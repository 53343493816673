import React, { useEffect, useState } from 'react';
import { PDFDownloadLink, Document, Page, Text, View, Image, Font, PDFViewer, Link, StyleSheet } from '@react-pdf/renderer';

import { db, storage } from '../../services/firebaseConnection';
import { ref as ref_database, child, get } from "firebase/database";

import Roboto from '../../fonts/Roboto-Regular.ttf';
import RobotoBold from '../../fonts/Roboto-Bold.ttf';
import RobotoItalic from '../../fonts/Roboto-Italic.ttf';

import './styles.css';

function TestFicha(props) {

    const userSelect = props.name
    const tokken = props.token
    const dataAss = props.data
    const dataTIme = props.timestamp
    const dataConv = new Date(dataTIme);
    const dia = dataConv.getDate();
    const meses = [
        'janeiro', 'fevereiro', 'março', 'abril',
        'maio', 'junho', 'julho', 'agosto',
        'setembro', 'outubro', 'novembro', 'dezembro'
    ];
    const mesIndex = dataConv.getMonth();
    const mes = meses[mesIndex];
    const ano = dataConv.getFullYear();

    const dataCapa = `${dia} de ${mes} de ${ano}`;

    const [dataRS, setDataRS] = useState([])
    const [partes, setPartes] = useState([])
    const [entornoData, setEntorno] = useState([])
    const [totalData, setTotalData] = useState([])


    Font.register({
        family: 'Roboto', fonts: [
            { src: Roboto },
            { src: RobotoBold, fontWeight: 600 },
            { src: RobotoItalic, fontStyle: 'italic' },
        ]
    });


    const styles = StyleSheet.create({
        capa: { backgroundColor: '#003566' },
        page: { backgroundColor: '#edf2f4' },
        section: { color: 'white', textAlign: 'center', margin: 15 },

        container: {
            flexDirection: 'row',
        },
        card_text: {
            flexDirection: 'row',
            alignItems: 'center',
            width: '50%'
        },
        card_text_title: {
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%'
        },
        card_text_one: {
            backgroundColor: '#293241',
            padding: 3,
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 3,
            marginTop: 3
        },
        card_text_two: {
            flex: 1,
            backgroundColor: '#fff',
            padding: 3,
            paddingLeft: 10,
            borderRadius: 3,
            marginTop: 3,
            marginLeft: 5
        },
        card_text_fuor: {
            flex: 1,
            borderWidth: 1,
            borderColor: "#2a9d8f",
            backgroundColor: '#fff',
            padding: 3,
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 3,
            marginTop: 3,
            marginLeft: 5
        },
        card_text_three: {
            backgroundColor: '#2a9d8f',
            padding: 3,
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 3,
            marginTop: 3
        },
        firstItem: {
            marginRight: 3
        },
        card_text_one_bottom: {
            borderWidth: 1,
            borderColor: "#495057",
            backgroundColor: '#fff',
            padding: 3,
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 3,
            marginTop: 3
        },
        card_text_two_bottom: {
            flex: 1,
            backgroundColor: '#fff',
            padding: 3,
            paddingLeft: 10,
            borderRadius: 3,
            marginTop: 3,
            marginLeft: 3
        },
        card_text_pedo: {
            flex: 1,
            borderWidth: 1,
            borderColor: "#495057",
            backgroundColor: '#fff',
            padding: 3,
            borderRadius: 3,
            marginTop: 3,
            alignItems: 'center',
            marginRight: 5
        },
        card_nivel: {
            flex: 0.5,
        },
        card_qtd: {
            flex: 0.5,
            marginRight: 0
        },
        card_text_pedo_rs: {
            flex: 1,
            backgroundColor: '#fff',
            padding: 2,
            borderRadius: 3,
            alignItems: 'center',
            marginRight: 5
        },
        card_nivel_rs: {
            flex: 0.5,
        },
        card_qtd_rs: {
            flex: 0.5,
            marginRight: 0
        },
        column: {
            height: 230,
            borderRadius: 5
        },
        image: {
            objectFit: 'scale-down',
            borderRadius: 5,
            margin: 3,
        },
        text_bold_cd: {
            fontSize: 10,
            fontFamily: 'Roboto',
            fontWeight: 600
        }
    });

    const qrCodeData = 'https://www.aipaconnect.com.br/verificacao'; // Dados para o código QR
    const qrCodeImageUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${qrCodeData}&size=100x100`;

    useEffect(() => {

        const dbRef = ref_database(db);

        get(child(dbRef, 'backup/' + userSelect)).then((snapshot) => {
            if (snapshot.exists()) {
                const dados = snapshot.val()

                const entornoPass = dados.dados
                const keyPontos = Object.keys(entornoPass)

                var arrDataFull = []
                Object.entries(entornoPass).forEach(function (data) {
                    arrDataFull[data[0]] = data[1]
                });

                const dataArr = Object.entries(arrDataFull)

                dataArr.sort((a, b) => {
                    const numA = parseInt(a[0].split('-')[1]);
                    const numB = parseInt(b[0].split('-')[1]);
                    return numA - numB;
                });

                setDataRS(dados)
                setTotalData(dataArr)
                setEntorno(dataArr)
                console.log(dataArr)

                /*const itensPorPagina = 50;
                const results = dataArr
                const qnt = results.length
                const parts = Math.ceil(qnt / itensPorPagina)

                var arrParts = []
                for (let i = 1; i <= parts; i++) {
                    const partsN = { a: ((i * 50) - 50), b: (i * 50) }
                    arrParts.push(partsN);
                }

                setPartes(arrParts)

                const primeiros50 = results.slice(0, 50)

                setEntorno(primeiros50);

                console.log(dataArr)
                //setEntorno(arrDataFull);
                
                */

            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });

    }, [])

    return (
        <div className='body'>
            <PDFViewer children={
                <Document>
                    <Page style={styles.capa}>

                        <View>
                            <View style={{ alignItems: 'center', marginTop: 120 }}>
                                <Image style={{ width: 200 }} source={require('../../img/logo-sbg.png')} />
                            </View>

                            <Text style={{
                                fontFamily: 'Roboto', textAlign: 'center',
                                color: '#fff', fontSize: 20, fontWeight: 600, marginTop: 15
                            }}>{dataRS.projeto}</Text>

                            <View style={{ borderWidth: 1, borderColor: '#fff', width: 350, alignSelf: 'center', marginTop: 20 }} />

                            <Text style={{
                                fontFamily: 'Roboto', textAlign: 'center',
                                color: '#fff', fontSize: 13, marginTop: 15
                            }}>{dataRS.protocolo}</Text>
                        </View>

                        <View style={{ position: 'absolute', bottom: 80, left: 20, right: 20, borderRadius: 10, height: 120, backgroundColor: '#fff', justifyContent: 'center' }} >
                            <View style={{ alignItems: 'center' }}>
                                <Image style={{ width: 250 }} source={require('../../img/logo-hc.png')} />
                            </View>
                        </View >

                        <View style={{ justifyContent: 'center', position: 'absolute', bottom: 50, width: '100%' }}>
                            <Text style={{ fontFamily: 'Roboto', textAlign: 'center', color: '#fff', fontSize: 12, }}>
                                Teresina, {dataCapa}.
                            </Text>
                        </View>

                        <View style={{ position: 'absolute', bottom: 0, left: 0, flexDirection: 'row', width: "100%", height: 20, backgroundColor: '#02c39a' }} />

                    </Page>

                    <Page style={styles.page}>

                        <Text style={{ fontSize: 14, fontFamily: 'Roboto', fontWeight: 600, textAlign: 'center', marginTop: 30, marginBottom: 15 }}>Lista de Intervenções</Text>

                        <Text style={{ fontSize: 13, fontFamily: 'Roboto', marginBottom: 15, marginLeft: 60 }}>Para acessar a página correta, clique na intervenção desejada.</Text>

                        <View style={{ flexDirection: 'row' }}>

                            <View style={{ width: '25%' }}>
                                {entornoData
                                    .slice(0, 40)
                                    .map((item, index) => (
                                        <View key={index} style={{ flexDirection: 'row', alignItems: 'center' }}>
                                            <View style={{ borderLeftWidth: 2, borderColor: '#ccc', marginLeft: 60, borderBottomStyle: 'solid', height: 12, marginRight: 5 }} />
                                            <Text src={`#${item}`} style={{ fontSize: 12, fontFamily: 'Roboto', marginTop: 3 }}>{item[0]}</Text>
                                        </View>
                                    ))}
                            </View>

                            <View style={{ width: '25%' }}>
                                {entornoData
                                    .slice(40, 80)
                                    .map((item, index) => (
                                        <View key={index} style={{ flexDirection: 'row', alignItems: 'center' }}>
                                            <View style={{ borderLeftWidth: 2, borderColor: '#ccc', marginLeft: 60, borderBottomStyle: 'solid', height: 12, marginRight: 5 }} />
                                            <Text src={`#${item}`} style={{ fontSize: 12, fontFamily: 'Roboto', marginTop: 3 }}>{item[0]}</Text>
                                        </View>
                                    ))}
                            </View>

                            <View style={{ width: '25%' }}>
                                {entornoData
                                    .slice(80, 120)
                                    .map((item, index) => (
                                        <View key={index} style={{ flexDirection: 'row', alignItems: 'center' }}>
                                            <View style={{ borderLeftWidth: 2, borderColor: '#ccc', marginLeft: 60, borderBottomStyle: 'solid', height: 12, marginRight: 5 }} />
                                            <Text src={`#${item}`} style={{ fontSize: 12, fontFamily: 'Roboto', marginTop: 3 }}>{item[0]}</Text>
                                        </View>
                                    ))}
                            </View>

                            <View style={{ width: '25%' }}>
                                {entornoData
                                    .slice(120, 160)
                                    .map((item, index) => (
                                        <View key={index} style={{ flexDirection: 'row', alignItems: 'center' }}>
                                            <View style={{ borderLeftWidth: 2, borderColor: '#ccc', marginLeft: 60, borderBottomStyle: 'solid', height: 12, marginRight: 5 }} />
                                            <Text src={`#${item}`} style={{ fontSize: 12, fontFamily: 'Roboto', marginTop: 3 }}>{item[0]}</Text>
                                        </View>
                                    ))}
                            </View>
                        </View>

                    </Page>

                    {/*.slice(0, 2)*/}
                    {entornoData
                        .map((item, index) => (

                            <Page key={index} style={styles.page}>

                                <View style={{ display: 'flex', flexDirection: 'column', margin: 15 }}>

                                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <View style={{ backgroundColor: '#003566', padding: 5, borderRadius: 5, paddingLeft: 15, paddingRight: 15 }}>
                                            <Image style={{ width: 80 }} source={require('../../img/logo-aipa-sim.png')} />
                                        </View>
                                        <View style={{
                                            backgroundColor: '#003566', padding: 5, borderRadius: 5,
                                            marginLeft: 5, alignItems: 'center', flex: 1, height: '100%', justifyContent: 'center'
                                        }}>
                                            <Text id={item} style={{ color: '#fff', fontSize: 13, fontFamily: 'Roboto', fontWeight: 600 }}>FICHA DE INTERVENÇÃO ARQUEOLÓGICA</Text>
                                        </View>
                                    </View>

                                    {/*===PROJETO E INTERVENÇÃO===*/}
                                    <View>

                                        <View style={styles.card_text_title} >
                                            <View style={styles.card_text_three}>
                                                <Text style={{ color: '#fff', fontSize: 10, fontFamily: 'Roboto' }}>Projeto</Text>
                                            </View>

                                            <View style={styles.card_text_fuor}>
                                                <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>{dataRS.projeto}</Text>
                                            </View>
                                        </View>


                                        <View style={styles.container} >

                                            <View style={styles.card_text} >
                                                <View style={styles.card_text_one}>
                                                    <Text style={{ color: '#fff', fontSize: 10, fontFamily: 'Roboto' }}>Pesquisador</Text>
                                                </View>

                                                <View style={[styles.card_text_two, styles.firstItem]}>
                                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>Hebert Coutinho</Text>
                                                </View>
                                            </View>

                                            <View style={styles.card_text} >
                                                <View style={styles.card_text_one}>
                                                    <Text style={{ color: '#fff', fontSize: 10, fontFamily: 'Roboto' }}>Intervenção</Text>
                                                </View>

                                                <View style={styles.card_text_two}>
                                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>Poço-Teste</Text>
                                                </View>
                                            </View>

                                        </View>

                                        <View style={styles.container}>
                                            <View style={styles.card_text}>
                                                <View style={styles.card_text_three}>
                                                    <Text style={{ color: '#fff', fontSize: 10, fontFamily: 'Roboto' }}>N° do PT</Text>
                                                </View>

                                                <View style={[styles.card_text_fuor, styles.firstItem]}>
                                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>{item[0]}</Text>
                                                </View>
                                            </View>

                                            <View style={styles.card_text}>
                                                <View style={styles.card_text_one}>
                                                    <Text style={{ color: '#fff', fontSize: 10, fontFamily: 'Roboto' }}>Coordenada</Text>
                                                </View>

                                                <View style={styles.card_text_two}>
                                                    <Link src={`http://maps.google.com.br/maps?q=${item[1].entorno.latitude},${item[1].entorno.longitude}&hl=pt-BR&t=h&z=16`}>
                                                        <Text style={{ fontSize: 10, fontFamily: 'Roboto', color: "#023e8a" }}>{item[1].entorno.utm}</Text>
                                                    </Link>
                                                </View>
                                            </View>
                                        </View>

                                        <View style={styles.container}>
                                            <View style={styles.card_text}>
                                                <View style={styles.card_text_one}>
                                                    <Text style={{ color: '#fff', fontSize: 10, fontFamily: 'Roboto' }}>Município</Text>
                                                </View>

                                                <View style={[styles.card_text_two, styles.firstItem]}>
                                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>{dataRS.municipio}</Text>
                                                </View>
                                            </View>

                                            <View style={styles.card_text}>
                                                <View style={styles.card_text_one}>
                                                    <Text style={{ color: '#fff', fontSize: 10, fontFamily: 'Roboto' }}>Data</Text>
                                                </View>

                                                <View style={styles.card_text_two}>
                                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>{item[1].entorno.timestamp}</Text>
                                                </View>
                                            </View>
                                        </View>

                                    </View>

                                    {/*===CONTEXTUALIZAÇÃO AMBIENTAL===*/}
                                    <View>

                                        <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 5, backgroundColor: '#495057', padding: 2, borderRadius: 3, justifyContent: 'center' }}>
                                            <View style={{ borderLeftWidth: 2, borderColor: '#fff', borderBottomStyle: 'solid', height: 10, marginRight: 8 }} />
                                            <Text style={{ color: '#fff', fontSize: 11, fontFamily: 'Roboto' }}>Contextualização Ambiental</Text>
                                            <View style={{ borderLeftWidth: 2, borderColor: '#fff', borderBottomStyle: 'solid', height: 10, marginLeft: 8 }} />
                                        </View>

                                        <View style={styles.container} >

                                            <View style={styles.card_text} >
                                                <View style={styles.card_text_one_bottom}>
                                                    <Text style={styles.text_bold_cd}>Vegetação</Text>
                                                </View>

                                                <View style={[styles.card_text_two_bottom, styles.firstItem]}>
                                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>{item[1].entorno.vegetacao}</Text>
                                                </View>
                                            </View>

                                            <View style={styles.card_text} >
                                                <View style={styles.card_text_one_bottom}>
                                                    <Text style={styles.text_bold_cd}>Declividade</Text>
                                                </View>

                                                <View style={styles.card_text_two_bottom}>
                                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>{item[1].entorno.declividade}</Text>
                                                </View>
                                            </View>

                                        </View>

                                        <View style={styles.container} >

                                            <View style={styles.card_text} >
                                                <View style={styles.card_text_one_bottom}>
                                                    <Text style={styles.text_bold_cd}>Feição de Relevo</Text>
                                                </View>

                                                <View style={[styles.card_text_two_bottom, styles.firstItem]}>
                                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>{item[1].entorno.feicRelevo}</Text>
                                                </View>
                                            </View>

                                            <View style={styles.card_text} >
                                                <View style={styles.card_text_one_bottom}>
                                                    <Text style={styles.text_bold_cd}>Posição topográfica</Text>
                                                </View>

                                                <View style={styles.card_text_two_bottom}>
                                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>{item[1].entorno.posicaoTop}</Text>
                                                </View>
                                            </View>

                                        </View>

                                        <View style={styles.card_text_title} >
                                            <View style={styles.card_text_one_bottom}>
                                                <Text style={styles.text_bold_cd}>Observação</Text>
                                            </View>

                                            <View style={styles.card_text_two_bottom}>
                                                <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>{item[1].entorno.descricao}</Text>
                                            </View>
                                        </View>
                                    </View>

                                    {/*===PEDOLOGIA===*/}
                                    {item[1].pedologia &&
                                        Object.keys(item[1].pedologia)[0] &&
                                        <View>

                                            <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 5, backgroundColor: '#495057', padding: 2, borderRadius: 3, justifyContent: 'center' }}>
                                                <View style={{ borderLeftWidth: 2, borderColor: '#fff', borderBottomStyle: 'solid', height: 10, marginRight: 8 }} />
                                                <Text style={{ color: '#fff', fontSize: 11, fontFamily: 'Roboto' }}>Pedologia</Text>
                                                <View style={{ borderLeftWidth: 2, borderColor: '#fff', borderBottomStyle: 'solid', height: 10, marginLeft: 8 }} />
                                            </View>

                                            <View style={styles.container} >

                                                <View style={[styles.card_text_pedo, styles.card_nivel]}>
                                                    <Text style={styles.text_bold_cd}>Nível</Text>
                                                </View>

                                                <View style={styles.card_text_pedo}>
                                                    <Text style={styles.text_bold_cd}>Coloração</Text>
                                                </View>

                                                <View style={styles.card_text_pedo}>
                                                    <Text style={styles.text_bold_cd}>Granulometria</Text>
                                                </View>

                                                <View style={styles.card_text_pedo}>
                                                    <Text style={styles.text_bold_cd}>Material Arqueológico</Text>
                                                </View>

                                                <View style={[styles.card_text_pedo, styles.card_qtd]}>
                                                    <Text style={styles.text_bold_cd}>Quantidade</Text>
                                                </View>

                                            </View>

                                            {Object.keys(item[1].pedologia) &&

                                                Object.keys(item[1].pedologia)
                                                    .filter(key => key !== "pt")
                                                    .sort((a, b) => parseInt(a) - parseInt(b))
                                                    .map(key => {

                                                        return (

                                                            <View style={styles.container} key={index}>

                                                                <View style={[styles.card_text_pedo_rs, styles.card_nivel]}>
                                                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>{item[1].pedologia[key].nivel}</Text>
                                                                </View>

                                                                <View style={styles.card_text_pedo_rs}>
                                                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>{item[1].pedologia[key].coloracao}</Text>
                                                                </View>

                                                                <View style={styles.card_text_pedo_rs}>
                                                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>{item[1].pedologia[key].textura}</Text>
                                                                </View>

                                                                {item[1].evidencias ? (

                                                                    Object.keys(item[1].evidencias)
                                                                        .filter(ponto => {
                                                                            return ponto === item[1].pedologia[key].nivel
                                                                        })
                                                                        .map((result) => {
                                                                            return (
                                                                                item[1].evidencias[result]
                                                                            )
                                                                        })
                                                                        .map((r, index) => {
                                                                            return (
                                                                                <View style={styles.card_text_pedo_rs} key={index}>
                                                                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>{r[index].evidencia}</Text>
                                                                                </View>

                                                                            )
                                                                        })

                                                                ) :
                                                                    <View style={styles.card_text_pedo_rs}>
                                                                        <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>Não encontrado</Text>
                                                                    </View>

                                                                }

                                                                {item[1].evidencias ? (
                                                                    Object.keys(item[1].evidencias)
                                                                        .filter(ponto => {
                                                                            return ponto === item[1].pedologia[key].nivel
                                                                        })
                                                                        .map(x => x)
                                                                        .length > 0 ? (
                                                                        <View></View>
                                                                    ) : (
                                                                        <View style={styles.card_text_pedo_rs}>
                                                                            <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>Não encontrado</Text>
                                                                        </View>
                                                                    )
                                                                ) :
                                                                    <View></View>
                                                                }

                                                                {item[1].evidencias ? (

                                                                    Object.keys(item[1].evidencias)
                                                                        .filter(ponto => {
                                                                            return ponto === item[1].pedologia[key].nivel
                                                                        })
                                                                        .map((result) => {
                                                                            return (
                                                                                item[1].evidencias[result]
                                                                            )
                                                                        })
                                                                        .map((r, index) => {
                                                                            return (
                                                                                <View style={[styles.card_text_pedo_rs, styles.card_qtd]} key={index}>
                                                                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>{r[index].quantidade}</Text>
                                                                                </View>

                                                                            )
                                                                        })

                                                                ) :
                                                                    <View style={[styles.card_text_pedo_rs, styles.card_qtd]} >
                                                                        <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>-</Text>
                                                                    </View>
                                                                }

                                                                {item[1].evidencias ? (
                                                                    Object.keys(item[1].evidencias)
                                                                        .filter(ponto => {
                                                                            return ponto === item[1].pedologia[key].nivel
                                                                        })
                                                                        .map(x => x)
                                                                        .length > 0 ? (
                                                                        <View></View>
                                                                    ) : (
                                                                        <View style={[styles.card_text_pedo_rs, styles.card_qtd]} >
                                                                            <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>-</Text>
                                                                        </View>
                                                                    )
                                                                ) :
                                                                    <View></View>
                                                                }

                                                            </View>

                                                        )
                                                    })
                                            }


                                        </View>
                                    }

                                    {/*===ENCERRAMENTO===*/}
                                    {item[1].encerramento &&
                                        <View>

                                            <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 5, backgroundColor: '#495057', padding: 2, borderRadius: 3, justifyContent: 'center' }}>
                                                <View style={{ borderLeftWidth: 2, borderColor: '#fff', borderBottomStyle: 'solid', height: 10, marginRight: 8 }} />
                                                <Text style={{ color: '#fff', fontSize: 11, fontFamily: 'Roboto' }}>Encerramento</Text>
                                                <View style={{ borderLeftWidth: 2, borderColor: '#fff', borderBottomStyle: 'solid', height: 10, marginLeft: 8 }} />
                                            </View>

                                            <View style={styles.container} >

                                                <View style={styles.card_text} >
                                                    <View style={styles.card_text_one_bottom}>
                                                        <Text style={styles.text_bold_cd}>Motivo</Text>
                                                    </View>

                                                    <View style={[styles.card_text_two_bottom, styles.firstItem]}>
                                                        <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>{item[1].encerramento.encerramento}</Text>
                                                    </View>
                                                </View>

                                                <View style={styles.card_text} >
                                                    <View style={styles.card_text_one_bottom}>
                                                        <Text style={styles.text_bold_cd}>Observação</Text>
                                                    </View>

                                                    <View style={styles.card_text_two_bottom}>
                                                        <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>{item[1].encerramento.observacoes}</Text>
                                                    </View>
                                                </View>

                                            </View>

                                        </View>
                                    }

                                    {/*===INTRANSPONIBILIDADE===*/}
                                    {item[1].intransponibilidade &&
                                        <View>

                                            <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 5, backgroundColor: '#495057', padding: 2, borderRadius: 3, justifyContent: 'center' }}>
                                                <View style={{ borderLeftWidth: 2, borderColor: '#fff', borderBottomStyle: 'solid', height: 10, marginRight: 8 }} />
                                                <Text style={{ color: '#fff', fontSize: 11, fontFamily: 'Roboto' }}>Intransponibilidade</Text>
                                                <View style={{ borderLeftWidth: 2, borderColor: '#fff', borderBottomStyle: 'solid', height: 10, marginLeft: 8 }} />
                                            </View>

                                            <View style={styles.container} >

                                                <View style={styles.card_text} >
                                                    <View style={styles.card_text_one_bottom}>
                                                        <Text style={styles.text_bold_cd}>Motivo</Text>
                                                    </View>

                                                    <View style={[styles.card_text_two_bottom, styles.firstItem]}>
                                                        <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>{item[1].intransponibilidade.motivo}</Text>
                                                    </View>
                                                </View>

                                                <View style={styles.card_text} >
                                                    <View style={styles.card_text_one_bottom}>
                                                        <Text style={styles.text_bold_cd}>Observação</Text>
                                                    </View>

                                                    <View style={styles.card_text_two_bottom}>
                                                        <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>Intervenção vistoriada em superfície - ({item[1].intransponibilidade.observacoes})</Text>
                                                    </View>
                                                </View>

                                            </View>

                                        </View>
                                    }

                                    {/*===FOTOS===*/}

                                    {item[1].fotos &&
                                        <View>

                                            <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 5, backgroundColor: '#495057', padding: 2, borderRadius: 3, justifyContent: 'center' }}>
                                                <View style={{ borderLeftWidth: 2, borderColor: '#fff', borderBottomStyle: 'solid', height: 10, marginRight: 8 }} />
                                                <Text style={{ color: '#fff', fontSize: 11, fontFamily: 'Roboto' }}>Fotos</Text>
                                                <View style={{ borderLeftWidth: 2, borderColor: '#fff', borderBottomStyle: 'solid', height: 10, marginLeft: 8 }} />
                                            </View>

                                            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                                {item[1].fotos &&
                                                    Object.values(item[1].fotos)
                                                        .slice(0, 2)
                                                        .map((image, index) => (
                                                            <View key={index} >
                                                                <Link src={image.uriStorage}>
                                                                    <Image src={image.uriStorage} style={{ width: 150, marginRight: 5, borderRadius: 5, marginTop: 5 }} />
                                                                </Link>
                                                            </View>
                                                        ))
                                                }
                                            </View>

                                            {Object.values(item[1].fotos).length > 4 &&
                                                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                                    {item[1].fotos &&
                                                        Object.values(item[1].fotos)
                                                            .slice(2, 4)
                                                            .map((image, index) => (
                                                                <View key={index} >
                                                                    <Link src={image.uriStorage}>
                                                                        <Image src={image.uriStorage} style={{ width: 150, marginRight: 5, borderRadius: 5, marginTop: 5 }} />
                                                                    </Link>
                                                                </View>
                                                            ))
                                                    }
                                                </View>
                                            }
                                        </View>
                                    }

                                </View>

                                <View style={{ borderWidth: 0.5, borderColor: '#212529', position: 'absolute', bottom: 55, marginLeft: 15, marginRight: 15, width: '95%' }} />

                                <Text style={{ position: 'absolute', bottom: 15, right: 15, textAlign: 'center', fontSize: 10, fontFamily: 'Roboto' }}
                                    render={({ pageNumber, totalPages }) => (
                                        `Página ${pageNumber} de ${totalPages}`
                                    )} fixed />

                                <View style={{ position: 'absolute', bottom: 15, left: 15, flexDirection: 'row', width: 450 }}>
                                    <Image src={qrCodeImageUrl} style={{ height: 35, width: 35, marginRight: 5 }} />

                                    <View>
                                        <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>
                                            Emitido eletronicamente em {dataAss} pelo AIPA Connect
                                        </Text>
                                        <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>
                                            A autenticidade deste documento pode ser
                                            conferida acessando o QRCode, ou, via
                                            sistema através do link <Link src={qrCodeData}>{qrCodeData}</Link>. informando o
                                            token a seguir: <Text style={{ fontWeight: 600, letterSpacing: 1 }}>{tokken}</Text>
                                        </Text>
                                    </View>
                                </View>

                                <View style={{ position: 'absolute', bottom: 0, left: 0, flexDirection: 'row', width: "100%", height: 8, backgroundColor: '#274c77' }} />

                            </Page>
                        ))}

                </Document>

            } style={{ width: '100%', height: '100%', border: 'none' }}>

            </PDFViewer>
        </div>
    );
}

export default TestFicha;