import React, { useState, useEffect } from 'react';
import './styles.css';
import Select from 'react-select'
import { useForm, Controller } from "react-hook-form";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { textAlign } from '@mui/system';
import { db, storage, auth } from '../../../services/firebaseConnection';
import { ref as ref_database, set, child, get } from "firebase/database";
import { ref as ref_storage, getDownloadURL, listAll, uploadBytes } from "firebase/storage";
import { onAuthStateChanged } from "firebase/auth";
import { BiArrowBack, BiPaperclip } from "react-icons/bi";
import { HiSave } from "react-icons/hi";
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FadingDots, FadingBalls } from "react-cssfx-loading";


function NewRegister(props) {

  const [entorno, setEntorno] = useState(false);
  const [loading, setLoading] = useState(true);
  const [entornoData, setEntornoData] = useState('');
  const [images, setImages] = useState([]);
  const [fileCount, setFileCount] = useState(0);
  const [finish, setFinish] = useState('');
  const [upload, setUpload] = useState('');
  const [textSyncInfo, setTextSyncInfo] = useState('');
  const [ptOptions, setPtOptions] = useState('');
  const [ptSync, setPtSync] = useState('');
  const [loaded, setLoaded] = useState(false);


  const uid = props.data.uid
  const projeto = props.data.projeto

  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2,
    autoplay: true,
    vertical: true,
    dotsClass: "slick-dots"
  };

  const imgPd = () => toast.error('Insira pelo menos duas imagens!', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const ptExt = () => toast.error('A intervenção já existe no sistema!', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const sucessNivel = () => toast.success('Nivel acrescentado!', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const vegetOp = [
    { value: 'Roça', label: 'Roça' },
    { value: 'Capoeira', label: 'Capoeira' },
    { value: 'Caatinga arbórea', label: 'Caatinga arbórea' },
    { value: 'Caating arbustuva', label: 'Caating arbustuva' },
    { value: 'Caatinga arbórea-arbustiva', label: 'Caatinga arbórea-arbustiva' },
    { value: 'Rasteira-gramínea', label: 'Rasteira-gramínea' },
    { value: 'Cerrado', label: 'Cerrado' },
    { value: 'Manguezal', label: 'Manguezal' },
    { value: 'Restinga', label: 'Restinga' },
    { value: 'Mata dos cocais', label: 'Mata dos cocais' },
    { value: 'Floresta decidual', label: 'Floresta decidual' },
    { value: 'Pampas', label: 'Pampas' },
    { value: 'Mata de araucária', label: 'Mata de araucária' },
    { value: 'Pantanal', label: 'Pantanal' },
    { value: 'Mata atlântica', label: 'Mata atlântica' },
  ]

  const posicOp = [
    { value: 'Topo', label: 'Topo' },
    { value: 'Alta vertente', label: 'Alta vertente' },
    { value: 'Média vertente', label: 'Média vertente' },
    { value: 'Baixa vertente', label: 'Baixa vertente' },
  ]

  const feicOp = [
    { value: 'Terraço fluvial', label: 'Terraço fluvial' },
    { value: 'Talvegue', label: 'Talvegue' },
    { value: 'Margem lacustre', label: 'Margem lacustre' },
    { value: 'Planalto com topo aplainado', label: 'Planalto com topo aplainado' },
    { value: 'Talude', label: 'Talude' },
    { value: 'Ravina / voçoroca', label: 'Ravina / voçoroca' },
    { value: 'Cuesta', label: 'Cuesta' },
    { value: 'Planície de inundação', label: 'Planície de inundação' },
    { value: 'Dolina', label: 'Dolina' },
    { value: 'Meandro abandonado', label: 'Meandro abandonado' },
    { value: 'Área deltáica', label: 'Área deltáica' },
    { value: 'Ambiente dunar', label: 'Ambiente dunar' },
    { value: 'Linha de praia', label: 'Linha de praia' },
    { value: 'Lagoa sazonal', label: 'Lagoa sazonal' },

  ]

  const declOp = [
    { value: 'Leve (<15°)', label: 'Leve (<15°)' },
    { value: 'Moderado (15° - 30°)', label: 'Moderado (15° - 30°)' },
    { value: 'Forte (>30°)', label: 'Forte (>30°)' },
  ]

  const schema = yup.object({
    pt: yup.string().required("Informe o número do ponto!"),
    utm: yup.string().required("Informe o número da coordernada UTM!"),
    vegetacao: yup.string().required("Informe o tipo de vegetação!"),
    posicaoTop: yup.string().required("Informe a tipo de posição topográfica!"),
    feicRelevo: yup.string().required("Informe a tipo de feição de relevo!"),
    declividade: yup.string().required("Informe a tipo de declividade!"),
    descricao: yup.string().required("Descreva o entorno da intervenção!"),
    timestamp: yup.string().required("Informe o dia que as informações foram coletadas!"),
  })

  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = data => {
    const inputDate = data.timestamp
    const formattedDate = `${inputDate.slice(8, 10)}/${inputDate.slice(5, 7)}/${inputDate.slice(0, 4)}`
    data.timestamp = formattedDate
    if (images[0] == undefined) {
      imgPd()
    } else if (images.length < 2) {
      imgPd()
    } else {
      const resultado = ptSync.filter(item => item === data.pt);
      if (resultado[0] == undefined) {
        setFinish(true)
        setEntornoData(data)
        setEntorno(false)
      } else {
        ptExt()
      }
    }
  }

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const imagesList = files.map((file) => URL.createObjectURL(file));
    setImages(imagesList);
    setFileCount(e.target.files.length);
  }

  async function sendEnt() {
    setUpload(true)
    setFinish(false)
    const qntPhotos = images.length
    const promises = []
    for (let contador = 0; contador < qntPhotos; contador++) {
      const ponto = entornoData.pt
      const newName = uid + "/AIPA-CONNECT-" + ponto + "-" + contador
      const metadata = {
        contentType: 'image/jpeg',
      };
      const directfoto = images[contador]
      const img = await fetch(directfoto)
      const bytes = await img.blob()
      const storageRef = ref_storage(storage, newName)

      await uploadBytes(storageRef, bytes, metadata)
        .then((snapshot) => {
          return getDownloadURL(snapshot.ref)
        }).then(downloadUrl => {
          setTextSyncInfo("Sincronizando!")
          const objPhoto = { uri: downloadUrl, pt: ponto }
          promises.push(objPhoto)
        }).catch((error) => {
          //console.log(error)
        })
    }

    const data = { entorno: entornoData, encerramento: '', evidencias: '', fotos: promises, pedologia: '' }
    const parentRef = ref_database(db, 'backup/' + projeto + "/dados/");
    const newChildRef = child(parentRef, entornoData.pt);
    set(newChildRef, data).then(() => {
      setTimeout(() => {
        setUpload(false)
      }, 2000);
    })
  }

  useEffect(() => {
    const dbRef = ref_database(db);
    get(child(dbRef, '/projetos/' + projeto + '/pontos')).then((snapshot) => {
      if (snapshot.exists()) {
        const pontos = snapshot.val()
        function myFunction(num) {
          return { value: num, label: num };
        }
        const map1 = pontos.map(myFunction);
        setPtOptions(map1)
        setTimeout(() => {
          setLoading(false)
          setEntorno(true)
        }, 500);

      } else {
        console.log("Sem dados cadastrados");
      }
    }).catch((error) => {
      console.error(error);
    });

    get(child(dbRef, '/backup/' + projeto + '/summary/abertos')).then((snapshot) => {
      if (snapshot.exists()) {
        const pontos = snapshot.val()
        setPtSync(pontos)
      } else {
        console.log("Sem dados cadastrados");
      }
    }).catch((error) => {
      console.error(error);
    });
  }, [])

  return (
    <div className='bodyRegister'>
      <ToastContainer />
      <div >

        {loading &&
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <FadingBalls style={{ marginTop: '200px' }} width="100px" height="25px" color="#001d3d" />
          </div>
        }

        {entorno &&
          <div >
            <div className='card_text'>
              <a className='text_one'>Entorno</a>
            </div>

            <div className='cd' >
              <form onSubmit={handleSubmit(onSubmit)}>

                <div className='form_cd'>

                  <Controller
                    control={control}
                    name="pt"
                    render={({ field: { onChange } }) => (
                      <Select
                        options={ptOptions}
                        onChange={(val) => onChange(val.value)}
                        className='select'
                        placeholder="Número do ponto"
                      />
                    )}
                  />

                  {errors.pt && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.pt?.message}</span>}

                  <Controller
                    control={control}
                    name="utm"
                    render={({ field: { onChange, value } }) => (
                      <input
                        type="text"
                        className='input'
                        placeholder="Coordenada UTM"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />

                  {errors.utm && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.utm?.message}</span>}

                  <Controller
                    control={control}
                    name="vegetacao"
                    render={({ field: { onChange } }) => (
                      <Select
                        options={vegetOp}
                        onChange={(val) => onChange(val.value)}
                        className='select'
                        placeholder="Vegetação"
                      />
                    )}
                  />

                  {errors.vegetacao && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.vegetacao?.message}</span>}

                  <Controller
                    control={control}
                    name="posicaoTop"
                    render={({ field: { onChange } }) => (
                      <Select
                        options={posicOp}
                        onChange={(val) => onChange(val.value)}
                        className='select'
                        placeholder="Posição Topográfica"
                      />
                    )}
                  />

                  {errors.posicaoTop && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.posicaoTop?.message}</span>}

                  <Controller
                    control={control}
                    name="feicRelevo"
                    render={({ field: { onChange } }) => (
                      <Select
                        options={feicOp}
                        onChange={(val) => onChange(val.value)}
                        className='select'
                        placeholder="Feição de relevo"
                      />
                    )}
                  />

                  {errors.feicRelevo && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.feicRelevo?.message}</span>}

                  <Controller
                    control={control}
                    name="declividade"
                    render={({ field: { onChange } }) => (
                      <Select
                        options={declOp}
                        onChange={(val) => onChange(val.value)}
                        className='select'
                        placeholder="Declividade"
                      />
                    )}
                  />

                  {errors.declividade && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.declividade?.message}</span>}

                  <Controller
                    control={control}
                    name="descricao"
                    render={({ field: { onChange, value } }) => (
                      <input
                        type="text"
                        className='input'
                        placeholder="Contextualização Ambiental"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />

                  {errors.descricao && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.descricao?.message}</span>}

                  <Controller
                    control={control}
                    name="timestamp"
                    render={({ field: { onChange, value } }) => (
                      <input
                        type="date"
                        className='input'
                        placeholder="Data da coleta"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />

                  {errors.timestamp && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.timestamp?.message}</span>}

                  <div class="file-upload">
                    <input type="file" name="file-upload" id="file-upload" onChange={handleImageUpload} multiple />
                    <label for="file-upload">
                      <BiPaperclip style={{ fontSize: '25px', marginRight: '10px' }} />
                      <i class="fa fa-cloud-upload"></i> Anexar imagens
                    </label>
                    <span class="file-name"></span>
                    {fileCount > 0 && (
                      <p style={{ marginTop: '5px' }}>{`Selecionado(s) ${fileCount} imagem(s)`}</p>
                    )}
                  </div>

                </div>

                <div className="car_cadastro">
                  <button type="submit" className="btn_cd_n">Avançar</button>
                </div>

              </form>

            </div>

          </div>
        }

        {finish &&
          <div>
            <div className='header_btns'>
              <div className='card_text'>
                <button onClick={() => setEntorno(true) || setFinish(false)} className='btn_null'><BiArrowBack /></button>
                <a className='text_one'>Voltar</a>
              </div>

              <button onClick={sendEnt} className='btn_null_save'>
                <HiSave />
                <a className='text_save'>Salvar</a>
              </button>

            </div>

            <div className='card_ponto'><a className='card_ponto_text'>{entornoData.pt}</a></div>



            <div className='card_ent'>
              <div className='card_data'>
                <a className='card_ent_text'>{entornoData.utm}</a>
                <a className='card_ent_text_title'>Coordernada UTM</a>
              </div>
              <div className='card_data'>
                <a className='card_ent_text'>{entornoData.vegetacao}</a>
                <a className='card_ent_text_title'>Vegetação</a>
              </div>
              <div className='card_data'>
                <a className='card_ent_text'>{entornoData.feicRelevo}</a>
                <a className='card_ent_text_title'>Feição de relevo</a>
              </div>
              <div className='card_data'>
                <a className='card_ent_text'>{entornoData.posicaoTop}</a>
                <a className='card_ent_text_title'>Posição topográfica</a>
              </div>
              <div className='card_data'>
                <a className='card_ent_text'>{entornoData.declividade}</a>
                <a className='card_ent_text_title'>Declividade</a>
              </div>
              <div className='card_data'>
                <a className='card_ent_text'>{entornoData.descricao}</a>
                <a className='card_ent_text_title'>Observação</a>
              </div>
              <div className='card_data'>
                <a className='card_ent_text'>{entornoData.timestamp}</a>
                <a className='card_ent_text_title'>Dia</a>
              </div>
            </div>

            <Slider className='slider' {...settings}>
              {images.map((image) => (
                <div key={image}>
                  <img src={image} alt="Imagem" onLoad={() => setLoaded(true)} />
                </div>
              ))}
            </Slider>

          </div>
        }

        {upload &&
          <div className='body_upload'>
            <FadingDots width="100px" height="100px" color="#2a9d8f" />
            <a className='text_update'>{textSyncInfo}</a>
          </div>
        }
      </div >
    </div >
  )
}

export default NewRegister;