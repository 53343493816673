import React from 'react';
import ReactDOM from 'react-dom/client';
import RouteSite from './RouteSite';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = createTheme({
  typography:{
    fontFamily:[
      'Inter',
      'Arimo',
      'Montserrat',
      'sans-serif'
    ].join(','),
  }
})

root.render(
  //<React.StrictMode>
  <ThemeProvider theme={theme}>
    <RouteSite />
  </ThemeProvider>
);