import React, { useState, useEffect } from 'react';
import './styles.css';
import Select from 'react-select'
import { useForm, Controller } from "react-hook-form";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { textAlign } from '@mui/system';
import { db, storage, auth } from '../../../services/firebaseConnection';
import { ref as ref_database, set, child, get } from "firebase/database";
import { ref as ref_storage, getDownloadURL, listAll, uploadBytes } from "firebase/storage";
import { onAuthStateChanged } from "firebase/auth";
import { BiArrowBack, BiPaperclip } from "react-icons/bi";
import { HiSave } from "react-icons/hi";
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FadingDots, FadingBalls, Spin } from "react-cssfx-loading";
import BeenhereIcon from '@mui/icons-material/Beenhere';


function AddData(props) {

    const [entorno, setEntorno] = useState(false);
    const [loading, setLoading] = useState(true);
    const [entornoData, setEntornoData] = useState('');
    const [images, setImages] = useState([]);
    const [fileCount, setFileCount] = useState(0);
    const [finish, setFinish] = useState('');
    const [upload, setUpload] = useState('');
    const [textSyncInfo, setTextSyncInfo] = useState('');
    const [ptOptions, setPtOptions] = useState('');
    const [ptSync, setPtSync] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [sucessUp, setSucessUp] = useState(false);



    //const uid = ""
    const projeto = props?.projeto;

    const settings = {
        dots: true,
        lazyLoad: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
        autoplay: true,
        vertical: true,
        dotsClass: "slick-dots"
    };

    const imgPd = () => toast.error('Insira pelo menos duas imagens!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const ptExt = () => toast.error('A intervenção já existe no sistema!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const sucessNivel = () => toast.success('Nivel acrescentado!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const vegetOp = [
        { value: 'Roça', label: 'Roça' },
        { value: 'Capoeira', label: 'Capoeira' },
        { value: 'Caatinga arbórea', label: 'Caatinga arbórea' },
        { value: 'Caatinga arbustiva', label: 'Caatinga arbustiva' },
        { value: 'Caatinga arbórea-arbustiva', label: 'Caatinga arbórea-arbustiva' },
        { value: 'Rasteira-gramínea', label: 'Rasteira-gramínea' },
        { value: 'Cerrado', label: 'Cerrado' },
        { value: 'Manguezal', label: 'Manguezal' },
        { value: 'Restinga', label: 'Restinga' },
        { value: 'Mata dos cocais', label: 'Mata dos cocais' },
        { value: 'Floresta decidual', label: 'Floresta decidual' },
        { value: 'Pampas', label: 'Pampas' },
        { value: 'Mata de araucária', label: 'Mata de araucária' },
        { value: 'Pantanal', label: 'Pantanal' },
        { value: 'Mata atlântica', label: 'Mata atlântica' },
        { value: 'Floresta Ombrófila Densa', label: 'Floresta Ombrófila Densa' },
        { value: 'Floresta Ombrófila Aberta', label: 'Floresta Ombrófila Aberta' },
        { value: 'Floresta Ombrófila Mista', label: 'Floresta Ombrófila Mista' },
        { value: 'Floresta Estacional Semidecidual', label: 'Floresta Estacional Semidecidual' },
        { value: 'Floresta Estacional Decidual', label: 'Floresta Estacional Decidual' },
        { value: 'Campinarana', label: 'Campinarana' },
        { value: 'Campinarana Florestada', label: 'Campinarana Florestada' },
        { value: 'Campinarana Arborizada', label: 'Campinarana Arborizada' },
        { value: 'Campinarana Gramínea-Lenhosa', label: 'Campinarana Gramínea-Lenhosa' },
        { value: 'Cerradão (Cerrado Florestado)', label: 'Cerradão (Cerrado Florestado)' },
        { value: 'Cerrado Arborizado (Campo-Cerrado)', label: 'Cerrado Arborizado (Campo-Cerrado)' },
        { value: 'Cerrado Gramíneo-Lenhoso', label: 'Cerrado Gramíneo-Lenhoso' },
        { value: 'Caatinga Florestada', label: 'Caatinga Florestada' },
        { value: 'Caatinga Gramínea-Lenhosa', label: 'Caatinga Gramínea-Lenhosa' },
        { value: 'Campos Gerais Planálticos', label: 'Campos Gerais Planálticos' },
        { value: 'Campanha Gaúcha', label: 'Campanha Gaúcha' },
        { value: 'Campo Salino', label: 'Campo Salino' },
    ]

    const posicOp = [
        { value: 'Topo', label: 'Topo' },
        { value: 'Alta vertente', label: 'Alta vertente' },
        { value: 'Média vertente', label: 'Média vertente' },
        { value: 'Baixa vertente', label: 'Baixa vertente' },
    ]

    const feicOp = [
        { value: 'Terraço fluvial', label: 'Terraço fluvial' },
        { value: 'Talvegue', label: 'Talvegue' },
        { value: 'Margem lacustre', label: 'Margem lacustre' },
        { value: 'Colina', label: 'Colina' },
        { value: 'Planalto com topo aplainado', label: 'Planalto com topo aplainado' },
        { value: 'Planalto', label: 'Planalto' },
        { value: 'Talude', label: 'Talude' },
        { value: 'Ravina / voçoroca', label: 'Ravina / voçoroca' },
        { value: 'Crista', label: 'Crista' },
        { value: 'Cuesta', label: 'Cuesta' },
        { value: 'Planície de inundação', label: 'Planície de inundação' },
        { value: 'Planície', label: 'Planície' },
        { value: 'Dolina', label: 'Dolina' },
        { value: 'Meandro abandonado', label: 'Meandro abandonado' },
        { value: 'Área deltáica', label: 'Área deltáica' },
        { value: 'Ambiente dunar', label: 'Ambiente dunar' },
        { value: 'Linha de praia', label: 'Linha de praia' },
        { value: 'Lagoa sazonal', label: 'Lagoa sazonal' },
        { value: 'Baixada', label: 'Baixada' },
        { value: 'Bloco Montanhoso', label: 'Bloco Montanhoso' },
        { value: 'Bloco Planáltico', label: 'Bloco Planáltico' },
        { value: 'Campos de Areias', label: 'Campos de Areias' },
        { value: 'Chapada', label: 'Chapada' },
        { value: 'Depressão', label: 'Depressão' },
        { value: 'Depressão Interplanáltica', label: 'Depressão Interplanáltica' },
        { value: 'Depressão Marginal', label: 'Depressão Marginal' },
        { value: 'Depressão Periférica', label: 'Depressão Periférica' },
        { value: 'Depressão Sertaneja', label: 'Depressão Sertaneja' },
        { value: 'Depressão Intermontana', label: 'Depressão Intermontana' },
        { value: 'Depressão Ortoclinal', label: 'Depressão Ortoclinal' },
        { value: 'Encosta', label: 'Encosta' },
        { value: 'Leque Aluvial', label: 'Leque Aluvial' },
        { value: 'Maciço', label: 'Maciço' },
        { value: 'Patamar Dissecado', label: 'Patamar Dissecado' },
        { value: 'Patamar', label: 'Patamar' },
        { value: 'Patamar Cuneiforme', label: 'Patamar Cuneiforme' },
        { value: 'Patamar Sertanejo', label: 'Patamar Sertanejo' },
        { value: 'Patamar Escalonado', label: 'Patamar Escalonado' },
        { value: 'Pediplano', label: 'Pediplano' },
        { value: 'Planalto Dissecado', label: 'Planalto Dissecado' },
        { value: 'Planalto Rebaixado', label: 'Planalto Rebaixado' },
        { value: 'Planalto Residual', label: 'Planalto Residual' },
        { value: 'Planície Residual', label: 'Planície Residual' },
        { value: 'Planície Alúvio-Coluvionar', label: 'Planície Alúvio-Coluvionar' },
        { value: 'Planície Fluviolacustre', label: 'Planície Fluviolacustre' },
        { value: 'Planície Fluviomarinha', label: 'Planície Fluviomarinha' },
        { value: 'Planície Lagunar', label: 'Planície Lagunar' },
        { value: 'Planície Litorânea', label: 'Planície Litorânea' },
        { value: 'Planície Deltaica', label: 'Planície Deltaica' },
        { value: 'Planície Estuarina', label: 'Planície Estuarina' },
        { value: 'Planície Marinha', label: 'Planície Marinha' },
        { value: 'Reverso do Planalto', label: 'Reverso do Planalto' },
        { value: 'Reverso da Cuesta', label: 'Reverso da Cuesta' },
        { value: 'Serrania', label: 'Serrania' },
        { value: 'Serra', label: 'Serra' },
        { value: 'Superfície Dissecada', label: 'Superfície Dissecada' },
        { value: 'Superfície Residual', label: 'Superfície Residual' },
        { value: 'Superfície Tabular', label: 'Superfície Tabular' },
        { value: 'Tabuleiro Dissecado', label: 'Tabuleiro Dissecado' },
        { value: 'Tabuleiro Costeiro', label: 'Tabuleiro Costeiro' },
        { value: 'Tabuleiro Interiorano', label: 'Tabuleiro Interiorano' },
        { value: 'Tabuleiro Pré-Litorâneo', label: 'Tabuleiro Pré-Litorâneo' },
        { value: 'Tabuleiro Sublitorâneo', label: 'Tabuleiro Sublitorâneo' },
        { value: 'Vale', label: 'Vale' },
        { value: 'Várzea', label: 'Várzea' },
        { value: 'Terraço Aluvial', label: 'Terraço Aluvial' },
    ]

    const declOp = [
        { value: 'Leve (<15°)', label: 'Leve (<15°)' },
        { value: 'Moderado (15° - 30°)', label: 'Moderado (15° - 30°)' },
        { value: 'Forte (>30°)', label: 'Forte (>30°)' },
    ]

    const schema = yup.object({
        pt: yup.string().required("Informe o número do ponto!"),
        utm: yup.string().required("Informe o número da coordernada UTM!"),
        vegetacao: yup.string().required("Informe o tipo de vegetação!"),
        posicaoTop: yup.string().required("Informe a tipo de posição topográfica!"),
        feicRelevo: yup.string().required("Informe a tipo de feição de relevo!"),
        declividade: yup.string().required("Informe a tipo de declividade!"),
        descricao: yup.string().required("Descreva o entorno da intervenção!"),
        timestamp: yup.string().required("Informe o dia que as informações foram coletadas!"),
    })

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = data => {
        const inputDate = data.timestamp
        const formattedDate = `${inputDate.slice(8, 10)}/${inputDate.slice(5, 7)}/${inputDate.slice(0, 4)}`
        data.timestamp = formattedDate
        if (images[0] == undefined) {
            imgPd()
        } else if (images.length < 2) {
            imgPd()
        } else {
            //const resultado = ptSync.filter(item => item === data.pt);

            setFinish(true)
            setEntornoData(data)
            setEntorno(false)

            /*if (resultado[0] == undefined) {
                setFinish(true)
                setEntornoData(data)
                setEntorno(false)
            } else {
                ptExt()
            }*/
        }
    }

    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files);
        const imagesList = files.map((file) => URL.createObjectURL(file));
        setImages(imagesList);
        setFileCount(e.target.files.length);
    }

    async function sendEnt() {
        const arrPontos = [...ptSync, entornoData.pt]

        arrPontos.sort((a, b) => {
            const numA = parseInt(a.split('-')[1]);
            const numB = parseInt(b.split('-')[1]);
            return numA - numB;
        });

        entornoData.latitude = ""
        entornoData.longitude = ""

        setUpload(true)
        setFinish(false)
        const qntPhotos = images.length
        const promises = []

        for (let contador = 0; contador < qntPhotos; contador++) {
            const ponto = entornoData.pt
            //const newName = uid + "/AIPA-CONNECT-" + ponto + "-" + contador
            const newName = projeto + "/WEB/AIPA-CONNECT-" + ponto + "-" + contador
            const metadata = {
                contentType: 'image/jpeg',
            };
            const directfoto = images[contador]
            const img = await fetch(directfoto)
            const bytes = await img.blob()
            const storageRef = ref_storage(storage, newName)

            await uploadBytes(storageRef, bytes, metadata)
                .then((snapshot) => {
                    return getDownloadURL(snapshot.ref)
                }).then(downloadUrl => {
                    setTextSyncInfo("Sincronizando!")
                    const objPhoto = { uriStorage: downloadUrl, pt: ponto }
                    promises.push(objPhoto)
                }).catch((error) => {
                    //console.log(error)
                })
        }

        const parentRefSum = ref_database(db, 'backup/' + projeto + "/summary/");
        const newChildRefSum = child(parentRefSum, "abertos");
        set(newChildRefSum, arrPontos);

        const data = { entorno: entornoData, encerramento: '', evidencias: '', fotos: promises, pedologia: { pt: entornoData.pt }, intransponibilidade: '' }
        //const data = { entorno: entornoData, fotos: promises }
        const parentRef = ref_database(db, 'backup/' + projeto + "/dados/");
        const newChildRef = child(parentRef, entornoData.pt);

        set(newChildRef, data).then(() => {
            setTimeout(() => {
                setUpload(false)
                setSucessUp(true)
            }, 2000);
        })
    }

    useEffect(() => {
        const dbRef = ref_database(db);
        get(child(dbRef, '/projetos/' + projeto + '/pontos')).then((snapshot) => {
            if (snapshot.exists()) {
                const pontos = snapshot.val()
                function myFunction(num) {
                    return { value: num, label: num };
                }
                const map1 = pontos.map(myFunction);
                setPtOptions(map1)
                setTimeout(() => {
                    setLoading(false)
                    setEntorno(true)
                }, 500);

            } else {
                console.log("Sem dados cadastrados");
            }
        }).catch((error) => {
            console.error(error);
        });

        get(child(dbRef, '/backup/' + projeto + '/summary/abertos')).then((snapshot) => {
            if (snapshot.exists()) {
                const pontos = snapshot.val()
                setPtSync(pontos)
            } else {
                console.log("Sem dados cadastrados");
            }
        }).catch((error) => {
            console.error(error);
        });
    }, [])

    return (
        <div className='bodyRegister'>
            <ToastContainer />
            <div>
                {loading &&
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spin style={{ marginTop: '200px' }} width="70px" height="70px" color="#001d3d" />
                    </div>
                }

                {entorno &&
                    <div>
                        <div className='card_text'>
                            <a className='text_one'>Cadastrar nova intervenção | Entorno</a>
                        </div>
                        <div className='card_ped'>
                            <div style={{ margin: 20 }}>
                                <div className='cd' >
                                    <form onSubmit={handleSubmit(onSubmit)}>

                                        <div className='form_cd'>

                                            <Controller
                                                control={control}
                                                name="pt"
                                                render={({ field: { onChange } }) => (
                                                    <Select
                                                        options={ptOptions}
                                                        onChange={(val) => onChange(val.value)}
                                                        className='select'
                                                        placeholder="Número do ponto"
                                                    />
                                                )}
                                            />

                                            {errors.pt && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.pt?.message}</span>}

                                            <Controller
                                                control={control}
                                                name="utm"
                                                render={({ field: { onChange, value } }) => (
                                                    <input
                                                        type="text"
                                                        className='input'
                                                        placeholder="Coordenada UTM"
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />

                                            {errors.utm && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.utm?.message}</span>}

                                            <Controller
                                                control={control}
                                                name="vegetacao"
                                                render={({ field: { onChange } }) => (
                                                    <Select
                                                        options={vegetOp}
                                                        onChange={(val) => onChange(val.value)}
                                                        className='select'
                                                        placeholder="Vegetação"
                                                    />
                                                )}
                                            />

                                            {errors.vegetacao && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.vegetacao?.message}</span>}

                                            <Controller
                                                control={control}
                                                name="posicaoTop"
                                                render={({ field: { onChange } }) => (
                                                    <Select
                                                        options={posicOp}
                                                        onChange={(val) => onChange(val.value)}
                                                        className='select'
                                                        placeholder="Posição Topográfica"
                                                    />
                                                )}
                                            />

                                            {errors.posicaoTop && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.posicaoTop?.message}</span>}

                                            <Controller
                                                control={control}
                                                name="feicRelevo"
                                                render={({ field: { onChange } }) => (
                                                    <Select
                                                        options={feicOp}
                                                        onChange={(val) => onChange(val.value)}
                                                        className='select'
                                                        placeholder="Feição de relevo"
                                                    />
                                                )}
                                            />

                                            {errors.feicRelevo && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.feicRelevo?.message}</span>}

                                            <Controller
                                                control={control}
                                                name="declividade"
                                                render={({ field: { onChange } }) => (
                                                    <Select
                                                        options={declOp}
                                                        onChange={(val) => onChange(val.value)}
                                                        className='select'
                                                        placeholder="Declividade"
                                                    />
                                                )}
                                            />

                                            {errors.declividade && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.declividade?.message}</span>}

                                            <Controller
                                                control={control}
                                                name="descricao"
                                                render={({ field: { onChange, value } }) => (
                                                    <input
                                                        type="text"
                                                        className='input'
                                                        placeholder="Contextualização Ambiental"
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />

                                            {errors.descricao && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.descricao?.message}</span>}

                                            <Controller
                                                control={control}
                                                name="timestamp"
                                                render={({ field: { onChange, value } }) => (
                                                    <input
                                                        type="date"
                                                        className='input'
                                                        placeholder="Data da coleta"
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />

                                            {errors.timestamp && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.timestamp?.message}</span>}

                                            <div class="file-upload">
                                                <input type="file" name="file-upload" id="file-upload" onChange={handleImageUpload} multiple />
                                                <label for="file-upload">
                                                    <BiPaperclip style={{ fontSize: '25px', marginRight: '10px' }} />
                                                    <i class="fa fa-cloud-upload"></i> Anexar imagens
                                                </label>
                                                <span class="file-name"></span>
                                                {fileCount > 0 && (
                                                    <p style={{ marginTop: '5px' }}>{`Selecionado(s) ${fileCount} imagem(s)`}</p>
                                                )}
                                            </div>

                                        </div>

                                        <div className="car_cadastro">
                                            <button type="submit" className="btn_cd_n">Avançar</button>
                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                }

                {finish &&
                    <div className='card_ped'>
                        <div style={{ margin: 20 }}>
                            <div className='header_btns'>
                                <div className='card_text'>
                                    <button onClick={() => setEntorno(true) || setFinish(false)} className='btn_null'><BiArrowBack /></button>
                                    <a className='text_one'>Voltar</a>
                                </div>

                                <button onClick={sendEnt} className='btn_null_save'>
                                    <HiSave />
                                    <a className='text_save'>Salvar</a>
                                </button>

                            </div>

                            <div className='card_ponto'><a className='card_ponto_text'>{entornoData.pt}</a></div>


                            <div className='card_ent'>
                                <div className='card_data'>
                                    <a className='card_ent_text'>{entornoData.utm}</a>
                                    <a className='card_ent_text_title'>Coordernada UTM</a>
                                </div>
                                <div className='card_data'>
                                    <a className='card_ent_text'>{entornoData.vegetacao}</a>
                                    <a className='card_ent_text_title'>Vegetação</a>
                                </div>
                                <div className='card_data'>
                                    <a className='card_ent_text'>{entornoData.feicRelevo}</a>
                                    <a className='card_ent_text_title'>Feição de relevo</a>
                                </div>
                                <div className='card_data'>
                                    <a className='card_ent_text'>{entornoData.posicaoTop}</a>
                                    <a className='card_ent_text_title'>Posição topográfica</a>
                                </div>
                                <div className='card_data'>
                                    <a className='card_ent_text'>{entornoData.declividade}</a>
                                    <a className='card_ent_text_title'>Declividade</a>
                                </div>
                                <div className='card_data'>
                                    <a className='card_ent_text'>{entornoData.descricao}</a>
                                    <a className='card_ent_text_title'>Observação</a>
                                </div>
                                <div className='card_data'>
                                    <a className='card_ent_text'>{entornoData.timestamp}</a>
                                    <a className='card_ent_text_title'>Dia</a>
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                {images.map((image) => (
                                    <div key={image}>
                                        <img src={image} alt="Imagem" onLoad={() => setLoaded(true)} style={{ width: 180, borderRadius: 10 }} />
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                }

                {upload &&
                    <div className='body_upload'>
                        <Spin width="70px" height="70px" color="#001d3d" />
                        <div style={{ backgroundColor: "#001d3d", padding: 5, paddingLeft: 20, paddingRight: 20, borderRadius: 10, marginTop: 10 }}>
                            <a className='text_update'>Enviando dados!</a>
                        </div>
                    </div>
                }

                {sucessUp &&
                    <div className='body_upload'>
                        <BeenhereIcon style={{ fontSize: 70, color: "#001d3d" }} />
                        <div style={{ backgroundColor: "#001d3d", padding: 5, paddingLeft: 20, paddingRight: 20, borderRadius: 10, marginTop: 10 }}>
                            <a className='text_update'>Envio concluído!</a>
                        </div>
                    </div>
                }

            </div >
        </div >
    )
}

export default AddData;