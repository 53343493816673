import React, { useState, useEffect, useRef } from 'react';
import { db, auth, storage } from '../../../services/firebaseConnection';
import { ref as ref_database, set, child, get, update, push, remove } from "firebase/database";
import { ref as ref_storage, getDownloadURL, listAll, uploadBytes } from "firebase/storage";
import { onAuthStateChanged } from "firebase/auth";
import { useForm, Controller } from "react-hook-form";
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Select from 'react-select'
import './styles.css';
import { BiArrowBack, BiEditAlt, BiTargetLock, BiPaperclip } from "react-icons/bi";
import { IoIosCube } from "react-icons/io";
import { MdCheckBox } from "react-icons/md";
import { AiOutlineAppstoreAdd, AiTwotoneDelete, AiOutlinePlus } from "react-icons/ai";
import { RiAlarmWarningFill } from "react-icons/ri";
import { HiSelector } from "react-icons/hi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer, toast } from 'react-toastify';
import { FadingBalls } from "react-cssfx-loading";


function EditRegister(props) {

  const imgPd = () => toast.error('Insira pelo menos duas imagens!', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const inputFileRef = useRef(null);
  const [ponto, setPonto] = useState(true);
  const [user, setUser] = useState('');
  const [dataProj, setDataProj] = useState('');
  const [pontos, setPontos] = useState('');
  const [niveis, setNiveis] = useState('');
  const [dataSelect, setDataSelect] = useState('');
  const [openEnt, setOpenEnt] = useState(true);
  const [openPed, setOpenPed] = useState(false);
  const [openEnc, setOpenEnc] = useState(false);
  const [updateEntorno, setUpdateEntorno] = useState(false);
  const [updateNivel, setUpdateNivel] = useState(false);
  const [selectNivel, setSelectNivel] = useState(false);
  const [remNivel, setRemNivel] = useState(false);
  const [nivelSelecionado, setNivelSelecionado] = useState(false);
  const [addNivel, setAddNivel] = useState(false);
  const [updadeEnc, setUpdadeEnc] = useState(false);
  const [addEnc, setAddEnc] = useState(false);
  const [images, setImages] = useState([]);
  const [imagesUpload, setImagesUpload] = useState([]);
  const [barraLateral, setBarraLateral] = useState(true);
  const [fileCount, setFileCount] = useState(0);
  const [addFotos, setAddFotos] = useState(false);
  const [uploadBalls, setUploadBalls] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const vegetOp = [
    { value: 'Roça', label: 'Roça' },
    { value: 'Capoeira', label: 'Capoeira' },
    { value: 'Caatinga arbórea', label: 'Caatinga arbórea' },
    { value: 'Caating arbustuva', label: 'Caating arbustuva' },
    { value: 'Caatinga arbórea-arbustiva', label: 'Caatinga arbórea-arbustiva' },
    { value: 'Rasteira-gramínea', label: 'Rasteira-gramínea' },
    { value: 'Cerrado', label: 'Cerrado' },
    { value: 'Manguezal', label: 'Manguezal' },
    { value: 'Restinga', label: 'Restinga' },
    { value: 'Mata dos cocais', label: 'Mata dos cocais' },
    { value: 'Floresta decidual', label: 'Floresta decidual' },
    { value: 'Pampas', label: 'Pampas' },
    { value: 'Mata de araucária', label: 'Mata de araucária' },
    { value: 'Pantanal', label: 'Pantanal' },
    { value: 'Mata atlântica', label: 'Mata atlântica' },
  ]

  const posicOp = [
    { value: 'Topo', label: 'Topo' },
    { value: 'Alta vertente', label: 'Alta vertente' },
    { value: 'Média vertente', label: 'Média vertente' },
    { value: 'Baixa vertente', label: 'Baixa vertente' },
  ]

  const feicOp = [
    { value: 'Terraço fluvial', label: 'Terraço fluvial' },
    { value: 'Talvegue', label: 'Talvegue' },
    { value: 'Margem lacustre', label: 'Margem lacustre' },
    { value: 'Planalto com topo aplainado', label: 'Planalto com topo aplainado' },
    { value: 'Talude', label: 'Talude' },
    { value: 'Ravina / voçoroca', label: 'Ravina / voçoroca' },
    { value: 'Cuesta', label: 'Cuesta' },
    { value: 'Planície de inundação', label: 'Planície de inundação' },
    { value: 'Dolina', label: 'Dolina' },
    { value: 'Meandro abandonado', label: 'Meandro abandonado' },
    { value: 'Área deltáica', label: 'Área deltáica' },
    { value: 'Ambiente dunar', label: 'Ambiente dunar' },
    { value: 'Linha de praia', label: 'Linha de praia' },
    { value: 'Lagoa sazonal', label: 'Lagoa sazonal' },

  ]

  const declOp = [
    { value: 'Leve (<15°)', label: 'Leve (<15°)' },
    { value: 'Moderado (15° - 30°)', label: 'Moderado (15° - 30°)' },
    { value: 'Forte (>30°)', label: 'Forte (>30°)' },
  ]

  const gran = [
    { value: 'Argilosa', label: 'Argilosa' },
    { value: 'Argilo-siltosa', label: 'Argilo-siltosa' },
    { value: 'Siltosa', label: 'Siltosa' },
    { value: 'Silto-arenosa', label: 'Silto-arenosa' },
    { value: 'Arenosa', label: 'Arenosa' },
    { value: 'Cascalhenta', label: 'Cascalhenta' },
    { value: 'Seixo', label: 'Seixo' },
    { value: 'Matacão', label: 'Matacão' },
  ]

  const colo = [
    { value: 'Branca', label: 'Branca' },
    { value: 'Cinza claro', label: 'Cinza claro' },
    { value: 'Cinza escuro', label: 'Cinza escuro' },
    { value: 'Vermelho', label: 'Vermelho' },
    { value: 'Avermelhado', label: 'Avermelhado' },
    { value: 'Amarelo', label: 'Amarelo' },
    { value: 'Amarelado', label: 'Amarelado' },
    { value: 'Laranja', label: 'Laranja' },
    { value: 'Alaranjado', label: 'Alaranjado' },
    { value: 'Marrom claro', label: 'Marrom claro' },
    { value: 'Marrom escuro', label: 'Marrom escuro' },
    { value: 'Preta', label: 'Preta' },

  ]

  const niv = [
    { value: '0-10', label: '0-10' },
    { value: '10-20', label: '10-20' },
    { value: '20-30', label: '20-30' },
    { value: '30-40', label: '30-40' },
    { value: '40-50', label: '40-50' },
    { value: '50-60', label: '50-60' },
    { value: '60-70', label: '60-70' },
    { value: '70-80', label: '70-80' },
    { value: '80-90', label: '80-90' },
    { value: '90-100', label: '90-100' },
    { value: '100-110', label: '100-110' },
    { value: '110-120', label: '110-120' },
    { value: '120-130', label: '120-130' },
  ]

  const ence = [
    { value: 'Compactação intransponível', label: 'Compactação intransponível' },
    { value: 'Aglomerados rochosos intransponível', label: 'Aglomerados rochosos intransponível' },
    { value: 'Solo de origem', label: 'Solo de origem' },
    { value: 'Rocha em decomposição', label: 'Rocha em decomposição' },
    { value: 'Rocha matriz', label: 'Rocha matriz' },
    { value: 'Presença de água', label: 'Presença de água' },
    { value: 'Profundidade máxima prevista', label: 'Profundidade máxima prevista' },
  ]

  const obsence = [
    { value: 'Presença de material arqueológico', label: 'Presença de material arqueológico' },
    { value: 'Ausência de material arqueológico', label: 'Ausência de material arqueológico' },
  ]

  const projeto = props.projeto.projeto
  const uid = props.projeto.uid

  const schema = yup.object({
    pt: yup.string().required("Insira o nome do ponto!"),
  })

  const schema2 = yup.object({
    utm: yup.string().required("Informe o número da coordernada UTM!"),
    vegetacao: yup.string().required("Informe o tipo de vegetação!"),
    posicaoTop: yup.string().required("Informe a tipo de posição topográfica!"),
    feicRelevo: yup.string().required("Informe a tipo de feição de relevo!"),
    declividade: yup.string().required("Informe a tipo de declividade!"),
    descricao: yup.string().required("Descreva o entorno da intervenção!"),
  })

  const schema3 = yup.object({
    nivel: yup.string().required("Selecione o nível!"),
    textura: yup.string().required("Selecione o granulometria!"),
    coloracao: yup.string().required("Selecione a coloração!"),
  })

  const schema4 = yup.object({
    textura: yup.string().required("Selecione o granulometria!"),
    coloracao: yup.string().required("Selecione a coloração!"),
  })

  const schema5 = yup.object({
    encerramento: yup.string().required("Selecione o motivo do encerramento!"),
    observacoes: yup.string().required("Selecione a observação do encerramento!"),
  })

  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const { control: control2, handleSubmit: handleSubmit2, formState: { errors: errors2 } } = useForm({
    resolver: yupResolver(schema2)
  });

  const { control: control3, handleSubmit: handleSubmit3, formState: { errors: errors3 } } = useForm({
    resolver: yupResolver(schema3)
  });

  const { control: control4, handleSubmit: handleSubmit4, formState: { errors: errors4 } } = useForm({
    resolver: yupResolver(schema4)
  });

  const { control: control5, handleSubmit: handleSubmit5, formState: { errors: errors5 } } = useForm({
    resolver: yupResolver(schema5)
  });

  useEffect(() => {
    const dbRef = ref_database(db);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        setUser(uid)

        get(child(dbRef, 'backup/' + projeto + '/summary/abertos')).then((snapshot) => {
          if (snapshot.exists()) {
            const dados = snapshot.val()
            const arrProjs = dados.map(x => { return { value: x, label: x } })
            setPontos(arrProjs)

          } else {
            console.log("No data available");
          }
        }).catch((error) => {
          console.error(error);
        });

      }
    });
  }, [])

  useEffect(() => {

    const dbRef = ref_database(db);
    get(child(dbRef, 'backup/' + projeto)).then((snapshot) => {
      if (snapshot.exists()) {
        const dados = snapshot.val()
        setDataProj(dados)
      }
    })

  }, [])


  const consultPt = (data) => {
    setDataSelect(data.pt)
    const fotos = dataProj.dados[data.pt].fotos
    if (fotos == '') {
      setImages([])
    } else {
      const arrFotos = fotos.map(r => (r.uri))
      setImages(arrFotos)
    }
    setPonto(false)
  }

  const editEnt = () => {
    setUpdateEntorno(true)
    setOpenEnt(false)
    setBarraLateral(false)
  }

  async function updEnt(data) {
    data.timestamp = dataProj.dados[dataSelect].entorno.timestamp
    //data.latitude = dataProj.dados[dataSelect].entorno.latitude
    //data.longitude = dataProj.dados[dataSelect].entorno.longitude
    data.pt = dataProj.dados[dataSelect].entorno.pt

    const updades = {}

    updades['backup/' + projeto + "/dados/" + data.pt + "/entorno"] = data
    await update(ref_database(db), updades);

    const novoArray = Object.entries(dataProj.dados).map((x) => {
      if (x[0] === data.pt) {
        if (x[1].entorno) {
          return [data.pt, {
            ...x[1], // Copia as propriedades existentes
            entorno: data // Atualiza a propriedade "entorno" com o novo valor
          }];
        }
      }
      return x;
    });

    var arrDataFull = {}

    novoArray.forEach(function (data) {
      arrDataFull[data[0]] = data[1]
    });

    const newData = { dados: arrDataFull, projeto: projeto }
    setDataProj(newData)

    setUpdateEntorno(false)
    setOpenEnt(true)
    setBarraLateral(true)
  }

  const editPed = (id) => {
    setOpenPed(false)
    setSelectNivel(true)
    setUpdateNivel(true)
    setNivelSelecionado(dataProj.dados[dataSelect].pedologia[id])
  }

  async function updNivel(data) {
    data.pt = nivelSelecionado.pt
    data.nivel = nivelSelecionado.nivel
    const updades = {}
    updades['backup/' + projeto + "/dados/" + data.pt + "/pedologia/" + nivelSelecionado.nivel] = data
    await update(ref_database(db), updades);

    const novoArray = Object.entries(dataProj.dados).map((x) => {
      if (x[0] === data.pt) {
        if (x[1].pedologia) {

          const nivel = Object.entries(x[1].pedologia).map((y) => {
            if (y[0] === data.nivel) {
              return [data.nivel, data]
            }
            return y
          })

          var arrDataNiv = {}

          nivel.forEach(function (data) {
            arrDataNiv[data[0]] = data[1]
          });

          return [data.pt, {
            ...x[1], // Copia as propriedades existentes
            pedologia: arrDataNiv // Atualiza a propriedade "entorno" com o novo valor
          }];

        }
      }
      return x;
    });

    var arrDataFull = {}

    novoArray.forEach(function (data) {
      arrDataFull[data[0]] = data[1]
    });

    const newData = { dados: arrDataFull, projeto: projeto }
    setDataProj(newData)


    setOpenPed(true)
    setSelectNivel(false)
    setUpdateNivel(false)
    setBarraLateral(true)
  }

  async function deleteNivel(data) {
    const childRef = ref_database(db, 'backup/' + projeto + "/dados/" + data.pt + "/pedologia/" + data.nivel);
    remove(childRef)
    const newData = { ...dataProj }
    delete newData.dados[data.pt].pedologia[data.nivel]
    setDataProj(newData)
  }

  const addNivelData = (data) => {
    data.pt = dataSelect
    const parentRef = ref_database(db, 'backup/' + projeto + "/dados/" + data.pt + "/pedologia/");
    const newChildRef = child(parentRef, data.nivel);
    set(newChildRef, data);

    const novoArray = Object.entries(dataProj.dados).map((x) => {
      if (x[0] === data.pt) {

        if (x[1].pedologia) {

          const nivel = Object.entries(x[1].pedologia)
          const newNiv = [data.nivel, data]
          const newArrNiv = [...nivel, newNiv]

          var arrDataNiv = {}

          newArrNiv.forEach(function (data) {
            arrDataNiv[data[0]] = data[1]
          });

          return [data.pt, {
            ...x[1], // Copia as propriedades existentes
            pedologia: arrDataNiv // Atualiza a propriedade "entorno" com o novo valor
          }];

        }
      }
      return x;
    });

    var arrDataFull = {}

    novoArray.forEach(function (data) {
      arrDataFull[data[0]] = data[1]
    });

    console.log(novoArray)

    const newData = { dados: arrDataFull, projeto: projeto }
    setDataProj(newData)

    //setAddNivel(false)
    //setOpenPed(true)
    //setBarraLateral(true)
  }

  const clickcomplete = (data) => {

    console.log(data)

    const nivSelect = dataProj.dados[data.pt].pedologia[data.nivel].nivel
    var numbNiv
    var nivResult
    if (nivSelect.length == 5) {
      var nivResult = nivSelect.slice(-2)
      var numbNiv = nivResult * 1
    } else if (nivSelect.length == 5) {
      var nivResult = 10
      var numbNiv = 10
    } else {
      var nivResult = nivSelect.slice(-3)
      var numbNiv = nivResult * 1
    }

    const niveis = dataProj.dados[data.pt].pedologia

    const uptArr = Object.keys(niveis)
      .sort((a, b) => parseInt(a) - parseInt(b))
      .map((key) => {
        return {
          nivel: dataProj.dados[data.pt].pedologia[key].nivel,
          pt: dataProj.dados[data.pt].pedologia[key].pt,
          textura: dataProj.dados[data.pt].pedologia[key].textura,
          coloracao: dataProj.dados[data.pt].pedologia[key].coloracao,
        };
      })

    const objeto = uptArr.find((item) => item.nivel === nivSelect);
    const posicao = uptArr.indexOf(objeto);

    if (posicao == 0) {
      if (numbNiv == 10) {
        //faz nada
      } else {
        const qntFor = (numbNiv * 0.1) - 1
        var arrTwo = uptArr
        for (var i = 0; i < qntFor; i++) {
          const limNiv = (nivResult * 1) - 10
          const nivInitial = limNiv - (i * 10)
          const nivFinal = (limNiv - (i * 10)) - 10
          const pacote = nivFinal + "-" + nivInitial
          const objDetail = {
            nivel: pacote,
            coloracao: dataProj.dados[data.pt].pedologia[data.nivel].coloracao,
            textura: dataProj.dados[data.pt].pedologia[data.nivel].textura,
            pt: dataProj.dados[data.pt].pedologia[data.nivel].pt
          }
          arrTwo.push(objDetail)
        }
        arrTwo.sort(function (a, b) { return a.nivel.match(/\d+/) - b.nivel.match(/\d+/) })
        const obj = arrTwo.reduce((acc, cur) => {
          acc[cur.nivel] = cur;
          return acc;
        }, {});

        const parentRef = ref_database(db, 'backup/' + projeto + "/dados/" + data.pt);
        const newChildRef = child(parentRef, "pedologia");
        set(newChildRef, obj);

        const novoArray = Object.entries(dataProj.dados).map((x) => {
          if (x[0] === data.pt) {

            if (x[1].pedologia) {

              return [data.pt, {
                ...x[1], // Copia as propriedades existentes
                pedologia: obj // Atualiza a propriedade "entorno" com o novo valor
              }];

            }
          }
          return x;
        });

        var arrDataFull = {}

        novoArray.forEach(function (data) {
          arrDataFull[data[0]] = data[1]
        });

        const newData = { dados: arrDataFull, projeto: projeto }
        setDataProj(newData)
      }
    } else {
      const antNiv = (posicao) - 1
      const nivAnt = uptArr[antNiv].nivel
      const nivAntResult = nivAnt.slice(-2)
      const subrt = ((nivResult - nivAntResult) * 0.1) - 1

      var arr = uptArr

      for (var i = 0; i < subrt; i++) {
        const limNiv = (nivResult * 1) - 10
        const nivInitial = limNiv - (i * 10)
        const nivFinal = (limNiv - (i * 10)) - 10
        const pacote = nivFinal + "-" + nivInitial
        const objDetail = {
          nivel: pacote,
          coloracao: dataProj.dados[data.pt].pedologia[data.nivel].coloracao,
          textura: dataProj.dados[data.pt].pedologia[data.nivel].textura,
          pt: dataProj.dados[data.pt].pedologia[data.nivel].pt
        }
        arr.push(objDetail)
      }

      arr.sort(function (a, b) { return a.nivel.match(/\d+/) - b.nivel.match(/\d+/) })

      const obj = arr.reduce((acc, cur) => {
        acc[cur.nivel] = cur;
        return acc;
      }, {});

      const parentRef = ref_database(db, 'backup/' + projeto + "/dados/" + data.pt);
      const newChildRef = child(parentRef, "pedologia");
      set(newChildRef, obj);

      const novoArray = Object.entries(dataProj.dados).map((x) => {
        if (x[0] === data.pt) {

          if (x[1].pedologia) {

            return [data.pt, {
              ...x[1], // Copia as propriedades existentes
              pedologia: obj // Atualiza a propriedade "entorno" com o novo valor
            }];

          }
        }
        return x;
      });

      var arrDataFull = {}

      novoArray.forEach(function (data) {
        arrDataFull[data[0]] = data[1]
      });

      const newData = { dados: arrDataFull, projeto: projeto }
      setDataProj(newData)

    }
  }

  const updEnce = (data) => {
    data.pt = dataSelect
    const parentRef = ref_database(db, 'backup/' + projeto + "/dados/" + data.pt);
    const newChildRef = child(parentRef, "encerramento");
    set(newChildRef, data);

    const novoArray = Object.entries(dataProj.dados).map((x) => {
      if (x[0] === data.pt) {
        if (x[1].encerramento) {
          return [data.pt, {
            ...x[1], // Copia as propriedades existentes
            encerramento: data // Atualiza a propriedade "entorno" com o novo valor
          }];
        }
      }
      return x;
    });

    var arrDataFull = {}

    novoArray.forEach(function (data) {
      arrDataFull[data[0]] = data[1]
    });

    const newData = { dados: arrDataFull, projeto: projeto }
    setDataProj(newData)

    setOpenEnc(true)
    setUpdadeEnc(false)
    setBarraLateral(true)
  }

  const addEncerramento = (data) => {
    data.pt = dataSelect
    const parentRef = ref_database(db, 'backup/' + projeto + "/dados/" + data.pt);
    const newChildRef = child(parentRef, "encerramento");
    set(newChildRef, data);
    setDataProj(prevState => {
      return {
        ...prevState,
        [dataProj.dados[data.pt]]: {
          ...prevState[dataProj.dados[data.pt]],
          "encerramento": data
        }
      };
    })
    setOpenEnc(true)
    setAddEnc(false)
  }

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const imagesList = files.map((file) => URL.createObjectURL(file));
    setImagesUpload(imagesList);
    setFileCount(e.target.files.length);
  }

  async function sendFotos() {
    setUploadBalls(true)
    if (imagesUpload[0] == undefined) {
      imgPd()
    } else if (imagesUpload.length < 2) {
      imgPd()
    } else {

      const qntPhotos = imagesUpload.length
      const promises = []
      const ponto = dataProj.dados[dataSelect].entorno.pt
      for (let contador = 0; contador < qntPhotos; contador++) {
        const newName = uid + "/AIPA-CONNECT-" + ponto + "-" + contador
        const metadata = {
          contentType: 'image/jpeg',
        };
        const directfoto = imagesUpload[contador]
        const img = await fetch(directfoto)
        const bytes = await img.blob()
        const storageRef = ref_storage(storage, newName)

        await uploadBytes(storageRef, bytes, metadata)
          .then((snapshot) => {
            return getDownloadURL(snapshot.ref)
          }).then(downloadUrl => {
            const objPhoto = { uri: downloadUrl, pt: ponto }
            promises.push(objPhoto)
          }).catch((error) => {
            //console.log(error)
          })
      }

      const parentRef = ref_database(db, 'backup/' + projeto + "/dados/" + ponto);
      const newChildRef = child(parentRef, 'fotos');
      set(newChildRef, promises).then(() => {
        setTimeout(() => {
          setImages(imagesUpload)
          setAddFotos(false)
          setUploadBalls(false)
          inputFileRef.current.value = null;
          setFileCount(0)
        }, 2000);
      })
    }
  }

  return (
    <div>
      <ToastContainer />
      {ponto &&
        <div>
          <div className='card_text'>
            <a className='text_one'>Selecione o ponto</a>
          </div>
          <form className='form_cd' onSubmit={handleSubmit(consultPt)}>
            <Controller
              control={control}
              name="pt"
              render={({ field: { onChange } }) => (
                <Select
                  options={pontos}
                  onChange={(val) => onChange(val.value)}
                  className='select'
                  placeholder="Ponto"
                />
              )}
            />
            <div className="car_cadastro">
              <button type="submit" className="btn_cd_n">Procurar</button>
            </div>
          </form>
        </div>
      }

      {errors.pt && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.pt?.message}</span>}

      {dataSelect &&
        <div className='body_edit'>
          <div className='collum_1'>
            <div className='card_title'>
              <a style={{ color: "#fff", fontWeight: '500', fontSize: '20px' }}>{dataProj.dados[dataSelect].entorno.pt}</a>
            </div>

            {barraLateral &&
              <div>

                <button
                  className={openEnt ? 'btn_header_clicked' : 'btn_header'}
                  onClick={() => setOpenEnt(true) || setOpenPed(false) || setOpenEnc(false)}
                >
                  <BiTargetLock className='icon_btn' />
                  ENTORNO
                </button>

                <button
                  className={openPed ? 'btn_header_clicked' : 'btn_header'}
                  onClick={() => setOpenPed(true) || setOpenEnt(false) || setOpenEnc(false)}
                >
                  <IoIosCube className='icon_btn' />
                  PEDOLOGIA
                </button>
                <button
                  className={openEnc ? 'btn_header_clicked' : 'btn_header'}
                  onClick={() => setOpenEnc(true) || setOpenEnt(false) || setOpenPed(false)}
                >
                  <MdCheckBox className='icon_btn' />
                  ENCERRAMENTO
                </button>

                <button
                  onClick={() => setPonto(true) || setDataSelect('')}
                  className='brn_back_edit'
                >

                  <BiArrowBack className='icon_btn' />
                  Voltar
                </button>

              </div>
            }

          </div>

          <div className='collum_2'>

            {openEnt &&
              <div>
                <div className='card_header_select'>
                  <a style={{ fontWeight: '800', fontSize: '18px' }}>ENTORNO</a>
                  <button className='btn_edit' onClick={editEnt}>
                    <BiEditAlt style={{ color: "#fff", fontSize: '20px' }} />
                    <a style={{ color: "#fff", fontSize: '15px', marginLeft: "5px" }}>Editar</a>
                  </button>
                </div>

                <div className='card_ent_ed'>
                  <div className='card_data_ent'>
                    <a className='card_ent_text'>{dataProj.dados[dataSelect].entorno.vegetacao}</a>
                    <a className='card_ent_text_title'>Vegetação</a>
                  </div>

                  <div className='card_data_ent'>
                    <a className='card_ent_text'>{dataProj.dados[dataSelect].entorno.utm}</a>
                    <a className='card_ent_text_title'>Coordenada UTM</a>
                  </div>

                  <div className='card_data_ent'>
                    <a className='card_ent_text'>{dataProj.dados[dataSelect].entorno.posicaoTop}</a>
                    <a className='card_ent_text_title'>Posição topográfica</a>
                  </div>

                  <div className='card_data_ent'>
                    <a className='card_ent_text'>{dataProj.dados[dataSelect].entorno.feicRelevo}</a>
                    <a className='card_ent_text_title'>Feição de relevo</a>
                  </div>

                  <div className='card_data_ent'>
                    <a className='card_ent_text'>{dataProj.dados[dataSelect].entorno.declividade}</a>
                    <a className='card_ent_text_title'>Declividade</a>
                  </div>

                  <div className='card_data_ent'>
                    <a className='card_ent_text'>{dataProj.dados[dataSelect].entorno.descricao}</a>
                    <a className='card_ent_text_title'>Descrição</a>
                  </div>
                </div>

                {images[0] ?
                  <div>
                    <Slider className='slider' {...settings}>
                      {images.map((image) => (
                        <div key={image}>
                          <img src={image} alt="Imagem" />
                        </div>
                      ))}
                    </Slider>
                  </div>

                  :
                  <div className='card_line'>
                    <div className='card_null_fotos'><a><RiAlarmWarningFill />Não foram cadastradas fotos para essa intervenção!</a></div>
                    <button className='btn_edit' onClick={() => setAddFotos(!addFotos)}>
                      <AiOutlinePlus style={{ color: "#fff", fontSize: '20px' }} />
                      <a style={{ color: "#fff", fontSize: '15px', marginLeft: "5px" }}>Cadastrar</a>
                    </button>
                  </div>
                }

                {addFotos &&
                  <div class="file-upload">
                    <input type="file" name="file-upload" id="file-upload" onChange={handleImageUpload} multiple ref={inputFileRef} />

                    <label for="file-upload">
                      <BiPaperclip style={{ fontSize: '25px', marginRight: '10px' }} />
                      <i class="fa fa-cloud-upload"></i> Anexar imagens
                    </label>

                    <span class="file-name"></span>
                    {fileCount > 0 && (
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <p style={{ marginTop: '5px' }}>{`Selecionado(s) ${fileCount} imagem(s)`}</p>
                        <button className="btn_cd_n" onClick={sendFotos}>Cadastrar</button>
                      </div>

                    )}

                  </div>
                }

                {uploadBalls &&
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                    <FadingBalls color="#001d3d" />
                  </div>
                }

              </div>
            }

            {openPed &&
              dataProj.dados[dataSelect].pedologia &&
              <div>
                <div className='card_header_select'>
                  <a style={{ fontWeight: '800', fontSize: '18px' }}>PEDOLOGIA</a>
                  <button className='btn_edit' onClick={() => setAddNivel(true) || setUpdateNivel(false) || setOpenPed(false) || setRemNivel(false) || setBarraLateral(false)}>
                    <AiOutlinePlus style={{ color: "#fff", fontSize: '20px' }} />
                    <a style={{ color: "#fff", fontSize: '15px', marginLeft: "5px" }}>Nível</a>
                  </button>
                </div>

                <div className='card_ped'>

                  <div className='card_ped_list'>

                    <div className='card_qnt_pc'>
                      <a className='card_ent_text'>Quantidade de pacotes cadastrados: {Object.keys(dataProj.dados[dataSelect].pedologia).length}</a>
                    </div>


                    {Object.keys(dataProj.dados[dataSelect].pedologia)
                      .sort((a, b) => parseInt(a) - parseInt(b))
                      .map((key) => (
                        <div key={key} className='list_pacotes'>
                          <div>
                            <a>{dataProj.dados[dataSelect].pedologia[key].nivel}</a>
                            <a> - {dataProj.dados[dataSelect].pedologia[key].textura}</a>
                            <a> - {dataProj.dados[dataSelect].pedologia[key].coloracao}</a>
                          </div>
                          <div>
                            <button className='btn_comp_niv' onClick={() => clickcomplete(dataProj.dados[dataSelect].pedologia[key])}>
                              <AiOutlineAppstoreAdd />
                              Completar
                            </button>
                            <button className='btn_edit_niv' onClick={() => editPed(dataProj.dados[dataSelect].pedologia[key].nivel) || setBarraLateral(false)}>
                              <BiEditAlt />
                              Editar
                            </button>
                            <button className='btn_remo_niv' onClick={() => deleteNivel(dataProj.dados[dataSelect].pedologia[key])}>
                              <AiTwotoneDelete />
                              Excluir
                            </button>
                          </div>
                        </div>
                      ))}

                  </div>

                </div>

              </div>
            }

            {openPed &&
              dataProj.dados[dataSelect].pedologia == '' &&
              <div>
                <div style={{ marginBottom: '10px' }}>
                  <a style={{ fontWeight: '800', fontSize: '18px' }}>Sem informações cadastradas!</a>
                </div>
                <button className='btn_add_page' onClick={() => setAddNivel(true) || setUpdateNivel(false) || setOpenPed(false) || setRemNivel(false) || setBarraLateral(false)}>
                  <AiOutlinePlus style={{ color: "#fff", fontSize: '25px' }} />
                  <a style={{ color: "#fff", fontSize: '18px', marginLeft: "5px" }}>Adicionar nível</a>
                </button>
              </div>
            }

            {openPed &&
              dataProj.dados[dataSelect].pedologia == undefined &&
              <div>
                <div style={{ marginBottom: '10px' }}>
                  <a style={{ fontWeight: '800', fontSize: '18px' }}>Sem informações cadastradas!</a>
                </div>
                <button className='btn_add_page' onClick={() => setAddNivel(true) || setUpdateNivel(false) || setOpenPed(false) || setRemNivel(false) || setBarraLateral(false)}>
                  <AiOutlinePlus style={{ color: "#fff", fontSize: '25px' }} />
                  <a style={{ color: "#fff", fontSize: '18px', marginLeft: "5px" }}>Adicionar nível</a>
                </button>
              </div>
            }

            {openEnc &&
              dataProj.dados[dataSelect].encerramento &&
              <div>
                <div className='card_header_select'>
                  <a style={{ fontWeight: '800', fontSize: '18px' }}>ENCERRAMENTO</a>
                  <button className='btn_edit' onClick={() => setUpdadeEnc(true) || setOpenEnc(false) || setBarraLateral(false)}>
                    <BiEditAlt style={{ color: "#fff", fontSize: '20px' }} />
                    <a style={{ color: "#fff", fontSize: '15px', marginLeft: "5px" }}>Editar</a>
                  </button>
                </div>

                <div className='card_enc'>
                  <div className='card_data_ent'>
                    <a className='card_ent_text'>{dataProj.dados[dataSelect].encerramento.encerramento}</a>
                    <a className='card_ent_text_title'>Motivo</a>
                  </div>

                  <div className='card_data_ent'>
                    <a className='card_ent_text'>{dataProj.dados[dataSelect].encerramento.observacoes}</a>
                    <a className='card_ent_text_title'>Observação</a>
                  </div>
                </div>
              </div>
            }

            {openEnc &&
              dataProj.dados[dataSelect].encerramento == '' &&
              <div>
                <div style={{ marginBottom: '10px' }}>
                  <a style={{ fontWeight: '800', fontSize: '18px' }}>Sem informações cadastradas!</a>
                </div>
                <button className='btn_add_page' onClick={() => setAddEnc(true) || setOpenEnc(false) || setBarraLateral(false)}>
                  <AiOutlinePlus style={{ color: "#fff", fontSize: '25px' }} />
                  <a style={{ color: "#fff", fontSize: '18px', marginLeft: "5px" }}>Cadastrar encerramento</a>
                </button>
              </div>
            }

            {openEnc &&
              dataProj.dados[dataSelect].encerramento == undefined &&
              <div>
                <div style={{ marginBottom: '10px' }}>
                  <a style={{ fontWeight: '800', fontSize: '18px' }}>Sem informações cadastradas!</a>
                </div>
                <button className='btn_add_page' onClick={() => setAddEnc(true) || setOpenEnc(false) || setBarraLateral(false)}>
                  <AiOutlinePlus style={{ color: "#fff", fontSize: '25px' }} />
                  <a style={{ color: "#fff", fontSize: '18px', marginLeft: "5px" }}>Cadastrar encerramento</a>
                </button>
              </div>
            }

            {updateEntorno &&
              <div className='cd'>

                <div className='card_text'>
                  <button onClick={() => setUpdateEntorno(false) || setOpenEnt(true) || setBarraLateral(true)} className='btn_null'><BiArrowBack /></button>
                  <a className='text_one'>Entorno</a>
                </div>

                <form className='form_cd' onSubmit={handleSubmit2(updEnt)}>

                  <Controller
                    control={control2}
                    name="utm"
                    defaultValue={dataProj.dados[dataSelect].entorno.utm}
                    render={({ field: { onChange } }) => (
                      <input
                        type="text"
                        className='input'
                        placeholder="Coordenada UTM"
                        defaultValue={dataProj.dados[dataSelect].entorno.utm}
                        //value={value}
                        onChange={onChange}
                      />
                    )}
                  />

                  {errors2.utm && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors2.utm?.message}</span>}

                  <Controller
                    control={control2}
                    name="vegetacao"
                    defaultValue={dataProj.dados[dataSelect].entorno.vegetacao}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={vegetOp}
                        onChange={(val) => onChange(val.value)}
                        value={vegetOp.find((option) => option.value === value)}
                        className='select'
                        placeholder="Vegetação"
                      />
                    )}
                  />

                  {errors2.vegetacao && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors2.vegetacao?.message}</span>}

                  <Controller
                    control={control2}
                    name="posicaoTop"
                    defaultValue={dataProj.dados[dataSelect].entorno.posicaoTop}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={posicOp}
                        onChange={(val) => onChange(val.value)}
                        value={posicOp.find((option) => option.value === value)}
                        className='select'
                        placeholder="Posição Topográfica"
                      />
                    )}
                  />

                  {errors2.posicaoTop && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors2.posicaoTop?.message}</span>}

                  <Controller
                    control={control2}
                    name="feicRelevo"
                    defaultValue={dataProj.dados[dataSelect].entorno.feicRelevo}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={feicOp}
                        onChange={(val) => onChange(val.value)}
                        value={feicOp.find((option) => option.value === value)}
                        className='select'
                        placeholder="Feição de relevo"
                      />
                    )}
                  />

                  {errors2.feicRelevo && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors2.feicRelevo?.message}</span>}

                  <Controller
                    control={control2}
                    name="declividade"
                    defaultValue={dataProj.dados[dataSelect].entorno.declividade}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={declOp}
                        onChange={(val) => onChange(val.value)}
                        value={declOp.find((option) => option.value === value)}
                        className='select'
                        placeholder="Declividade"
                      />
                    )}
                  />

                  {errors2.declividade && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors2.declividade?.message}</span>}

                  <Controller
                    control={control2}
                    name="descricao"
                    defaultValue={dataProj.dados[dataSelect].entorno.descricao}
                    render={({ field: { onChange, value } }) => (
                      <input
                        type="text"
                        className='input'
                        placeholder="Contextualização Ambiental"
                        defaultValue={dataProj.dados[dataSelect].entorno.descricao}
                        //value={value}
                        onChange={onChange}
                      />
                    )}
                  />

                  {errors2.descricao && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors2.descricao?.message}</span>}

                  <div className="car_cadastro">
                    <button type="submit" className="btn_cd_n">Atualizar</button>
                  </div>

                </form>

              </div>
            }

            {updateNivel &&
              selectNivel &&
              <div>
                <div className='card_text'>
                  <button onClick={() => setUpdateNivel(false) || setOpenPed(true) || setRemNivel(false) || setBarraLateral(true)} className='btn_null'><BiArrowBack /></button>
                  <a className='text_one'>Pedologia - {nivelSelecionado.nivel}</a>
                </div>

                <form className='form_cd' onSubmit={handleSubmit4(updNivel)}>
                  <a>Atual: {nivelSelecionado.textura}</a>
                  <Controller
                    control={control4}
                    name="textura"
                    render={({ field: { onChange } }) => (
                      <Select
                        options={gran}
                        onChange={(val) => onChange(val.value)}
                        className='select'
                        placeholder="Granulometria"
                      />
                    )}
                  />

                  {errors4.textura && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors4.textura?.message}</span>}

                  <a>Atual: {nivelSelecionado.coloracao}</a>
                  <Controller
                    control={control4}
                    name="coloracao"
                    render={({ field: { onChange } }) => (
                      <Select
                        options={colo}
                        onChange={(val) => onChange(val.value)}
                        className='select'
                        placeholder="Coloração"
                      />
                    )}
                  />

                  {errors4.coloracao && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors4.coloracao?.message}</span>}

                  <div className="car_cadastro">
                    <button type="submit" className="btn_cd_n">Atualizar</button>
                  </div>
                </form>

              </div>
            }

            {addNivel &&
              <div>

                <div className='card_text'>
                  <button onClick={() => setAddNivel(false) || setOpenPed(true) || setBarraLateral(true)} className='btn_null'><BiArrowBack /></button>
                  <a className='text_one'>Pedologia</a>
                </div>

                <form className='form_cd' onSubmit={handleSubmit3(addNivelData)}>
                  <Controller
                    control={control3}
                    name="nivel"
                    render={({ field: { onChange } }) => (
                      <Select
                        options={niv}
                        onChange={(val) => onChange(val.value)}
                        className='select'
                        placeholder="Nível (cm)"
                      />
                    )}
                  />

                  {errors3.nivel && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors3.nivel?.message}</span>}

                  <Controller
                    control={control3}
                    name="textura"
                    render={({ field: { onChange } }) => (
                      <Select
                        options={gran}
                        onChange={(val) => onChange(val.value)}
                        className='select'
                        placeholder="Granulometria"
                      />
                    )}
                  />

                  {errors3.textura && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors3.textura?.message}</span>}

                  <Controller
                    control={control3}
                    name="coloracao"
                    render={({ field: { onChange } }) => (
                      <Select
                        options={colo}
                        onChange={(val) => onChange(val.value)}
                        className='select'
                        placeholder="Coloração"
                      />
                    )}
                  />

                  {errors3.coloracao && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors3.coloracao?.message}</span>}

                  <div className="car_cadastro">
                    <button type="submit" className="btn_cd_n">Cadastrar</button>
                  </div>

                </form>

              </div>
            }

            {updadeEnc &&
              <div className='cd'>
                <div className='card_text'>
                  <button onClick={() => setOpenEnc(true) || setUpdadeEnc(false)} className='btn_null'><BiArrowBack /></button>
                  <a className='text_one'>Encerramento</a>
                </div>
                <form className='form_cd' onSubmit={handleSubmit5(updEnce)}>
                  <Controller
                    control={control5}
                    name="encerramento"
                    defaultValue={dataProj.dados[dataSelect].encerramento.encerramento}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={ence}
                        value={ence.find((option) => option.value === value)}
                        onChange={(val) => onChange(val.value)}
                        className='select'
                        placeholder="Motivo"
                      />
                    )}
                  />
                  {errors5.encerramento && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors5.encerramento?.message}</span>}
                  <Controller
                    control={control5}
                    name="observacoes"
                    defaultValue={dataProj.dados[dataSelect].encerramento.observacoes}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={obsence}
                        value={obsence.find((option) => option.value === value)}
                        onChange={(val) => onChange(val.value)}
                        className='select'
                        placeholder="Observação"
                      />
                    )}
                  />
                  {errors5.observacoes && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors5.observacoes?.message}</span>}
                  <div className="car_cadastro">
                    <button type="submit" className="btn_cd_n">Avançar</button>
                  </div>
                </form>
              </div>
            }

            {addEnc &&
              <div className='cd'>
                <div className='card_text'>
                  <button onClick={() => setOpenEnc(true) || setAddEnc(false) || setBarraLateral(true)} className='btn_null'><BiArrowBack /></button>
                  <a className='text_one'>Encerramento</a>
                </div>
                <form className='form_cd' onSubmit={handleSubmit5(addEncerramento)}>
                  <Controller
                    control={control5}
                    name="encerramento"
                    defaultValue={dataProj.dados[dataSelect].encerramento.encerramento}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={ence}
                        value={ence.find((option) => option.value === value)}
                        onChange={(val) => onChange(val.value)}
                        className='select'
                        placeholder="Motivo"
                      />
                    )}
                  />
                  {errors5.encerramento && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors5.encerramento?.message}</span>}
                  <Controller
                    control={control5}
                    name="observacoes"
                    defaultValue={dataProj.dados[dataSelect].encerramento.observacoes}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={obsence}
                        value={obsence.find((option) => option.value === value)}
                        onChange={(val) => onChange(val.value)}
                        className='select'
                        placeholder="Observação"
                      />
                    )}
                  />
                  {errors5.observacoes && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors5.observacoes?.message}</span>}
                  <div className="car_cadastro">
                    <button type="submit" className="btn_cd_n">Avançar</button>
                  </div>
                </form>
              </div>
            }

          </div>

        </div>
      }

    </div >
  )
}

export default EditRegister;