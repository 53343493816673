import React from 'react';

import DashboardIcon from '@mui/icons-material/Dashboard';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import RuleFolderIcon from '@mui/icons-material/RuleFolder';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import TableChartIcon from '@mui/icons-material/TableChart';


export const SidebarData = [
    {
        title: "Início",
        icon: <HomeRoundedIcon />,
        link: "sy/",
    },
    {
        title: "Dashboard",
        icon: <DashboardIcon />,
        link: "sy/dashboard",
    },
    {
        title: "Fichas",
        icon: <RuleFolderIcon />,
        link: "sy/fichas",
    },
    {
        title: "Tabela",
        icon: <TableChartIcon />,
        link: "sy/tabelas",
    },
    {
        title: "Projetos",
        icon: <ViewAgendaIcon />,
        link: "sy/projetos",
    },
    {
        title: "Usuários",
        icon: <PersonAddIcon />,
        link: "sy/usuarios",
    },
    {
        title: "Assinatura",
        icon: <TextSnippetIcon />,
        link: "sy/assinaturaEletronica",
    },
]
