import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db, storage, auth } from '../../../services/firebaseConnection';
import { ref as ref_database, set, child, get, update } from "firebase/database";
import { FadingBalls } from "react-cssfx-loading";


function SetInfo() {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        const projeto = location.state.projeto
        const dados = location.state.dados
        const summaryPre = location.state.summary
        const protocolo = location.state.protocolo
        const municipio = location.state.municipio
        const executor = location.state.executor

        console.log(dados)

        var abertos = summaryPre.abertos
        abertos.sort((a, b) => {
            const numA = parseInt(a.split('-')[1]);
            const numB = parseInt(b.split('-')[1]);
            return numA - numB;
        });

        var encerramentoCd = summaryPre.encerramentoCd
        encerramentoCd.sort((a, b) => {
            const numA = parseInt(a.split('-')[1]);
            const numB = parseInt(b.split('-')[1]);
            return numA - numB;
        });

        var evidenciaCd = summaryPre.evidenciaCd
        evidenciaCd.sort((a, b) => {
            const numA = parseInt(a.split('-')[1]);
            const numB = parseInt(b.split('-')[1]);
            return numA - numB;
        });

        var pedologiaCd = summaryPre.pedologiaCd
        pedologiaCd.sort((a, b) => {
            const numA = parseInt(a.split('-')[1]);
            const numB = parseInt(b.split('-')[1]);
            return numA - numB;
        });

        const summary = { abertos, pedologiaCd, evidenciaCd, encerramentoCd }


        set(ref_database(db, 'backup/' + projeto), {
            dados: dados,
            projeto: projeto,
            summary: summary,
            protocolo: protocolo,
            municipio: municipio,
            executor: executor,
            refresh: true
        }).then(() => {
            navigate('/home', { state: { projeto: projeto } });
        })

    }, [])

    return (

        <div style={{ overflow: 'hidden' }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh',
                    width: '100vw',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <a style={{ fontSize: 20, fontWeight: '800', marginBottom: '10px' }}>Gravando dados</a>
                <FadingBalls width="120px" height="30px" color="#30ae74" />
                <div />
            </div>
        </div>

    )
}

export default SetInfo;