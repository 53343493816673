import React, { useState, useEffect } from "react";
import { db, storage } from '../../services/firebaseConnection'
import { ref as ref_database, child, get, set } from "firebase/database";
import Select from 'react-select'

import { IoClose } from "react-icons/io5";

import { utils, writeFile } from "xlsx";
import './styles.css';

function Tabelas() {
    const [bkProjeto, setBkProjeto] = useState("");
    const [projeto, setProjeto] = useState("");
    const [totalData, setTotalData] = useState("");
    const [dataRS, setDataRS] = useState("");


    const xport = React.useCallback(() => {
        const table = document.getElementById("Table2XLSX");
        const wb = utils.table_to_book(table);

        const nameFile = "Tabela Intervenções " + dataRS.projeto + ".xlsx"

        writeFile(wb, nameFile);
    });

    const abrirProjeto = () => {

        const dbRef = ref_database(db);

        get(child(dbRef, 'backup/' + projeto)).then((snapshot) => {
            if (snapshot.exists()) {
                const dados = snapshot.val()

                const entornoPass = dados.dados
                const keyPontos = Object.keys(entornoPass)

                var arrDataFull = []
                Object.entries(entornoPass).forEach(function (data) {
                    arrDataFull[data[0]] = data[1]
                });

                const dataArr = Object.entries(arrDataFull)

                dataArr.sort((a, b) => {
                    const numA = parseInt(a[0].split('-')[1]);
                    const numB = parseInt(b[0].split('-')[1]);
                    return numA - numB;
                });

                setDataRS(dados)
                setTotalData(dataArr)
                console.log(dataArr)


            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });

    }

    useEffect(() => {

        const dbRef = ref_database(db);

        get(child(dbRef, 'projetos/list')).then((snapshot) => {
            if (snapshot.exists()) {
                const dados = snapshot.val()

                var arrProjs = []
                dados.map(x => { arrProjs.push(x.projeto) })
                function myFunction(num) {
                    return { value: num, label: num };
                }
                const map1 = arrProjs.map(myFunction);
                setBkProjeto(map1)

            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });

    }, [])

    return (
        <div style={{ overflowY: 'auto', height: '100vh' }}>
            <div style={{ margin: 20 }}>

                <a className="header_txt">Tabela de Situação da Intervenções</a>

                {totalData === "" &&
                    <div>
                        <Select
                            options={bkProjeto}
                            placeholder="Projeto"
                            className="select_projeto"
                            onChange={(item) => setProjeto(item.value)}
                        />

                        <div className="car_cadastro" style={{ marginTop: 10 }}>
                            <button onClick={abrirProjeto} className="btn_cd_n">Abrir projeto</button>
                        </div>
                    </div>
                }

                {totalData && (
                    <div style={{ marginTop: 10 }}>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className="car_cadastro" style={{ marginTop: 10 }}>
                                <button onClick={xport} className="btn_cd_n">Baixar tabela</button>
                            </div>

                            <button onClick={() => setTotalData('')} className="btn_quit_int">
                                <IoClose style={{ padding: 5, paddingLeft: 15, paddingRight: 15, fontSize: 15, color: '#fff' }} />
                            </button>
                        </div>

                        <table id="Table2XLSX" className="myTable">
                            <tbody>
                                <tr>
                                    <th style={{ textAlign: 'center', fontWeight: 600 }} colSpan="4">
                                        Situação do poços-teste
                                    </th>
                                </tr>
                                <tr>
                                    <th style={{ textAlign: 'center', fontWeight: 600 }}>Poço Teste</th>
                                    <th style={{ textAlign: 'center', fontWeight: 600 }}>Coordenada</th>
                                    <th style={{ textAlign: 'center', fontWeight: 600 }}>Situação</th>
                                    <th style={{ textAlign: 'center', fontWeight: 600 }}>Avaliação</th>
                                </tr>
                                {totalData.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{ textAlign: 'center' }}>{item[0]}</td>
                                        <td
                                            style={{
                                                textAlign: 'center',
                                                backgroundColor:
                                                    item[1].entorno.utm ? '' : '#f07167', // Define o fundo vermelho se não houver observações
                                            }}
                                        >
                                            {item[1].entorno.utm}
                                        </td>
                                        <td
                                            style={{
                                                textAlign: 'center',
                                                backgroundColor: item[1].encerramento ? '' : item[1].intransponibilidade ? '' : '#f07167', // Define o fundo vermelho se não houver encerramento
                                            }}
                                        >
                                            {item[1].encerramento ? 'Executado' : item[1].intransponibilidade ? 'Instransponível' : 'Não executado'}

                                        </td>

                                        {item[1].encerramento ?
                                            <td
                                                style={{
                                                    textAlign: 'center',
                                                    backgroundColor: item[1].encerramento.observacoes ? '' : '#f07167', // Define o fundo vermelho se não houver observações
                                                }}
                                            >
                                                {item[1].encerramento.observacoes}
                                            </td>
                                            :
                                            item[1].intransponibilidade ?
                                                <td
                                                    style={{
                                                        textAlign: 'center',
                                                        backgroundColor: item[1].intransponibilidade.observacoes ? '' : '#f07167', // Define o fundo vermelho se não houver observações
                                                    }}
                                                >
                                                    Intervenção vistoriada em superfície ({item[1].intransponibilidade.observacoes})
                                                </td>
                                                :
                                                <td
                                                    style={{
                                                        textAlign: 'center',
                                                        backgroundColor: item[1].encerramento ? '' : '#f07167', // Define o fundo vermelho se não houver observações
                                                    }}
                                                >
                                                    -
                                                </td>
                                        }

                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                )}
            </div>
        </div>
    );
}

export default Tabelas;