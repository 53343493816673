import React, { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from "react-hook-form";
import * as yup from 'yup';
import Select from 'react-select';
import { IoMdAdd } from "react-icons/io";
import { FaRegUser } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import './styles.css';

import { db, storage } from '../../services/firebaseConnection'
import { ref as ref_database, child, get, set } from "firebase/database";

import { PDFDownloadLink, Document, Page, Text, View, Image, Font, PDFViewer, Link, StyleSheet } from '@react-pdf/renderer';

import Roboto from '../../fonts/Roboto-Regular.ttf';
import RobotoBold from '../../fonts/Roboto-Bold.ttf';
import RobotoItalic from '../../fonts/Roboto-Italic.ttf';

import { Editor } from '@tinymce/tinymce-react';
import Html from 'react-pdf-html';

import { ToastContainer, toast } from 'react-toastify';

function Oficio() {

    const [cdInt, setCdInt] = useState(false)
    const [slInt, setSlInt] = useState(null)
    const [listInt, setListInt] = useState(null)
    const [assunto, setAssunto] = useState(null)
    const [empree, setEmp] = useState(null)
    const [projeto, setPro] = useState(null)
    const [textBody, setBody] = useState(null)
    const [TBBI, setTBBI] = useState('<p>Prezado senhor, [plural e concordância]</p><p>[TEXTO DO OFÍCIO]</p><p>Assim, nos colocamos a disposição para eventuais esclarecimentos.</p>')
    const [respon, setRespon] = useState(null)

    const [dataCab, setDataCab] = useState([])
    const [dataPDF, setDataPDF] = useState(null)

    const [diaData, setDiaData] = useState(null)
    const [diaHora, setDiaHora] = useState(null)
    const [dataEdit, setDataEdit] = useState(null)
    const [assPDF, setAssPDF] = useState(false)
    const [numeracao, setNumeracao] = useState('')
    const [ano, setAno] = useState('')

    const qrCodeData = 'https://www.aipaconnect.com.br/verificacao'; // Dados para o código QR
    const qrCodeImageUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${qrCodeData}&size=100x100`;

    Font.register({
        family: 'Roboto', fonts: [
            { src: Roboto },
            { src: RobotoBold, fontWeight: 600 },
            { src: RobotoItalic, fontStyle: 'italic' },
        ]
    });

    Font.registerHyphenationCallback(word => (
        [word]
    ));

    const styles = {
        p: {
            fontFamily: 'Roboto',
            fontSize: 11,
            textAlign: 'justify',
            lineHeight: 1.4,
            marginBottom: 12,
            marginTop: 0
        },
        strong: {
            fontFamily: 'Roboto',
            fontWeight: 600,
            fontSize: 11,
        },
        li: {
            fontFamily: 'Roboto',
            fontSize: 11,
            lineHeight: 1.4,
            marginRight: 25,
            textAlign: 'justify',
        },
        ul: {
            marginBottom: 12,
            marginTop: 0
        },
        ol: {
            marginBottom: 12,
            marginTop: 0
        },
        table: {
            borderWidth: 1,
            borderStyle: 'solid',
        },
        td: {
            fontFamily: 'Roboto',
            fontSize: 11,
            padding: 5,
            textAlign: 'center'
        }
    }

    const html = `<div><strong>This ás a bold text</strong></div>`;

    const schema = yup.object({
        interessado: yup.string().required("Informe o nome do interessado (secretaria, orgão, etc)"),
        nome: yup.string().required("Informe o nome do representante"),
        cargo: yup.string().required("Informe o cargo do representante"),
        endereco: yup.string().required("Informe o endereço do interessado!"),
        cep: yup.string().required("Informe o CEP do interessado!"),
        cidadeEstado: yup.string().required("Informe a cidade e o estado do interessado!"),
    })

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = data => {

        let convertInteressado = data.interessado.replace(/\//g, '-');

        set(ref_database(db, 'oficio/orgaos/' + convertInteressado), {
            interessado: data.interessado,
            nome: data.nome,
            cargo: data.cargo,
            endereco: data.endereco,
            cep: data.cep,
            cidadeEstado: data.cidadeEstado
        }).then(() => {
            setCdInt(false)
            sucessInt()
        })
    }

    const gerarOficio = () => {

        const dbRef = ref_database(db);

        /*get(child(dbRef, 'oficio/orgaos/' + slInt)).then((snapshot) => {
            if (snapshot.exists()) {
                const dados = snapshot.val()

                setDataCabPrim(dados);

            } else {
                console.log("No data available");
            }
        })*/

        const getPromises = interessados.map((x) => {
            return get(child(dbRef, 'oficio/orgaos/' + x.nome)).then((snapshot) => {
                if (snapshot.exists()) {
                    const dados = snapshot.val();
                    dados.pessoas = x.pessoas;
                    return dados;
                } else {
                    console.log("No data available");
                    return null;
                }
            });
        });

        Promise.all(getPromises)
            .then((arrOrgao) => {

                arrOrgao = arrOrgao.filter((item) => item !== null);
                setDataCab(arrOrgao);
                const dadosPDF = {};
                dadosPDF.assunto = assunto;
                dadosPDF.empreendimento = empree;
                dadosPDF.projeto = projeto;
                //const paragrafos = textBody.split('\n\n')
                dadosPDF.textBody = textBody;

                setDataPDF(dadosPDF);
                setTBBI(textBody)
                const responsaveisFiltrados = responsaveis.filter(
                    (responsavel) =>
                        responsavel.empresa.trim() !== '' &&
                        responsavel.nome.trim() !== '' &&
                        responsavel.funcao.trim() !== ''
                );
                setRespon(responsaveisFiltrados);

            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });

    }

    function geraStringAleatoria(tamanho, tokensExistente) {
        var stringAleatoria = '';
        var caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var tokenUnica = false;

        while (!tokenUnica) {
            stringAleatoria = '';
            for (var i = 0; i < tamanho; i++) {
                stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
            }

            // Verifica se a nova string gerada já existe no array de tokens existentes
            if (!tokensExistente.includes(stringAleatoria)) {
                tokenUnica = true;
            }
        }

        return stringAleatoria;
    }

    const assinarOficio = () => {

        const dbRef = ref_database(db);

        get(child(dbRef, 'assinatura/')).then((snapshot) => {
            if (snapshot.exists()) {
                const dados = snapshot.val()
                const tokensExistentes = Object.keys(dados).map(obj => obj).flat();
                const tokenPDF = geraStringAleatoria(8, tokensExistentes)

                const now1 = Date.now();

                set(ref_database(db, 'assinatura/' + tokenPDF), {
                    token: tokenPDF,
                    number: numeracao,
                    projeto: dataPDF.empreendimento,
                    assunto: dataPDF.assunto,
                    interessado: dataCab,
                    data: now1,
                    tipo: "of",
                    autor: "Hebert Rogério do Nascimento Coutinho"
                })

                const dataTIme = Date.now()
                const dataConv = new Date(dataTIme);
                const dia = dataConv.getDate();
                const meses = [
                    'janeiro', 'fevereiro', 'março', 'abril',
                    'maio', 'junho', 'julho', 'agosto',
                    'setembro', 'outubro', 'novembro', 'dezembro'
                ];
                const mesIndex = dataConv.getMonth();
                const mes = meses[mesIndex];
                const ano = dataConv.getFullYear();

                const dataCapa = `Teresina, ${dia} de ${mes} de ${ano}`;

                const now = new Date(dataTIme);
                const diaS = now.getDate().toString().padStart(2, '0');
                const mesS = (now.getMonth() + 1).toString().padStart(2, '0');
                const anoS = now.getFullYear().toString();
                const hora = now.getHours().toString().padStart(2, '0');
                const minuto = now.getMinutes().toString().padStart(2, '0');

                const dataFormatada = `${diaS}/${mesS}/${anoS} ${hora}:${minuto}`;

                setDiaHora(dataFormatada)
                setDiaData(dataCapa)

                const infoPass = dataPDF
                infoPass.token = tokenPDF

                setDataPDF(infoPass)
                setAssPDF(true)

            } else {
                console.log("No data available");
            }
        }).then(() => {
            const dataTIme = Date.now()
            const dataConv = new Date(dataTIme);
            const ano = dataConv.getFullYear();

            set(ref_database(db, 'oficio/numeracao/' + ano + '/' + numeracao), {
                interessado: dataCab,
            })
        }).catch((error) => {
            console.error(error);
        });

    }

    const sucessInt = () => toast.success('Interessado cadastrado com sucesso!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const [novoInteressado, setNovoInteressado] = useState('');
    const [interessados, setInteressados] = useState([]);

    const adicionarInteressado = () => {
        setInteressados([...interessados, { nome: novoInteressado, pessoas: [] }]);
        setNovoInteressado('');
    };

    const adicionarPessoaAoInteressado = (index) => {
        const novosInteressados = [...interessados];
        novosInteressados[index].pessoas.push({ nome: '', cargo: '' });
        setInteressados(novosInteressados);
    };

    const handleNomePessoaChange = (interessadoIndex, pessoaIndex, e) => {
        const novosInteressados = [...interessados];
        novosInteressados[interessadoIndex].pessoas[pessoaIndex].nome = e.target.value;
        setInteressados(novosInteressados);
    };

    const handleCargoPessoaChange = (interessadoIndex, pessoaIndex, e) => {
        const novosInteressados = [...interessados];
        novosInteressados[interessadoIndex].pessoas[pessoaIndex].cargo = e.target.value;
        setInteressados(novosInteressados);
    };

    const handleInteressadoChange = (selectedOption) => {
        setNovoInteressado(selectedOption.value); // Define o valor selecionado no estado
    };

    useEffect(() => {

        const dbRef = ref_database(db);

        get(child(dbRef, 'oficio/orgaos')).then((snapshot) => {
            if (snapshot.exists()) {
                const dados = snapshot.val()

                const arrInt = Object.keys(dados)

                var arrProjs = []
                arrInt.map(x => { arrProjs.push(x) })
                function myFunction(num) {
                    return { value: num, label: num };
                }
                const map1 = arrProjs.map(myFunction);
                setListInt(map1)

            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });

        const dataTIme = Date.now()
        const dataConv = new Date(dataTIme);
        const ano = dataConv.getFullYear();

        setAno(ano)

        get(child(dbRef, 'oficio/numeracao/' + ano)).then((snapshot) => {
            if (snapshot.exists()) {
                const dados = snapshot.val()
                const dadosValue = Object.keys(dados)

                const maiorNumero = dadosValue.reduce((maior, x) => {
                    if (x !== undefined && (maior === undefined || x > maior)) {
                        return x;
                    }
                    return maior;
                }, undefined);

                const numberAtual = (maiorNumero * 1) + 1
                let numeroFormatado = String(numberAtual).padStart(3, '0');
                setNumeracao(numeroFormatado)

            } else {
                setNumeracao("001")
            }
        }).catch((error) => {
            console.error(error);
        });

        console.log("fez")

    }, [cdInt])

    const [responsaveis, setResponsaveis] = useState([]);

    const adicionarInput = () => {
        setResponsaveis([...responsaveis, { empresa: '', nome: '', funcao: '' }]);
    };

    const handleInputChange = (index, event) => {
        const novosResponsaveis = [...responsaveis];
        novosResponsaveis[index][event.target.name] = event.target.value;
        setResponsaveis(novosResponsaveis);
    };

    const pegarValores = () => {

        // Faça o que desejar com os valores aqui, por exemplo, enviar para o servidor
    };


    return (
        <div style={{ overflowY: 'auto', height: '100vh' }}>
            <div style={{ margin: 25, width: 600, flexDirection: 'column', display: 'flex', }}>
                <ToastContainer />
                {dataPDF ?
                    <div>
                        {assPDF === true ?
                            <View>
                                <a className="header_txt">Ofício</a>
                                <PDFViewer children={
                                    <Document>
                                        <Page style={{ paddingBottom: 100, paddingTop: 135 }}>

                                            <View fixed style={{ position: 'absolute', top: 0, left: 0 }}>
                                                <Image style={{ width: 596 }} source={require('../../img/rodape_oficio_hc.png')} />
                                            </View>

                                            <View fixed style={{ position: 'absolute', bottom: 180, right: 0 }}>
                                                <Image style={{ width: 300, opacity: 0.08 }} source={require('../../img/simbolo_hc.png')} />
                                            </View>

                                            <View style={{ marginLeft: 80, marginRight: 80 }}>

                                                <Text style={{
                                                    fontFamily: 'Roboto', fontSize: 11, marginTop: 15
                                                }}>Ofício HC {numeracao}/{ano}</Text>

                                                <Text style={{
                                                    fontFamily: 'Roboto', fontSize: 11, marginTop: 15, textAlign: 'right'
                                                }}>{diaData}</Text>

                                                <Text style={{
                                                    fontFamily: 'Roboto', fontSize: 11, marginTop: 15
                                                }}>Ao</Text>

                                                {dataCab &&
                                                    dataCab.map((r, index) => (

                                                        <View key={index} >
                                                            <Text style={{
                                                                fontFamily: 'Roboto', fontSize: 11, fontWeight: 600, marginTop: 2, textAlign: 'justify'
                                                            }}>{r.interessado}</Text>
                                                            <Text style={{
                                                                fontFamily: 'Roboto', fontSize: 11, fontWeight: 600, marginTop: 2, textAlign: 'justify'
                                                            }}>{r.nome}</Text>
                                                            <Text style={{
                                                                fontFamily: 'Roboto', fontSize: 11, fontWeight: 600, marginTop: 2, textAlign: 'justify'
                                                            }}>{r.cargo}</Text>

                                                            {r.pessoas &&
                                                                r.pessoas.map((r, index) => (
                                                                    <View key={index} style={{ marginTop: 3, marginBottom: 3 }}>
                                                                        <Text style={{
                                                                            fontFamily: 'Roboto', fontSize: 11, fontWeight: 600, marginTop: 2, textAlign: 'justify'
                                                                        }}>{r.nome}</Text>

                                                                        <Text style={{
                                                                            fontFamily: 'Roboto', fontSize: 11, fontWeight: 600, marginTop: 2, textAlign: 'justify'
                                                                        }}>{r.cargo}</Text>
                                                                    </View>
                                                                ))
                                                            }

                                                            <Text style={{
                                                                fontFamily: 'Roboto', fontSize: 11, marginTop: 2, textAlign: 'justify'
                                                            }}>{r.endereco}</Text>
                                                            <Text style={{
                                                                fontFamily: 'Roboto', fontSize: 11, marginTop: 2
                                                            }}>CEP {r.cep}</Text>
                                                            <Text style={{
                                                                fontFamily: 'Roboto', fontSize: 11, marginTop: 2
                                                            }}>{r.cidadeEstado}</Text>



                                                        </View>
                                                    ))
                                                }

                                                <Text style={{
                                                    fontFamily: 'Roboto', fontSize: 11, marginTop: 15, fontWeight: 600, textAlign: 'justify'
                                                }}>Assunto: <Text style={{ fontWeight: 100 }}>{dataPDF.assunto}</Text></Text>
                                                <Text style={{
                                                    fontFamily: 'Roboto', fontSize: 11, marginTop: 2, fontWeight: 600, textAlign: 'justify'
                                                }}>Empreendimento: <Text style={{ fontWeight: 100 }}>{dataPDF.empreendimento}</Text></Text>
                                                <Text style={{
                                                    fontFamily: 'Roboto', fontSize: 11, marginTop: 2, fontWeight: 600, textAlign: 'justify'
                                                }}>Processo: <Text style={{ fontWeight: 100 }}>{dataPDF.projeto}</Text></Text>

                                                <View style={{ marginTop: 15 }}>
                                                    <Html stylesheet={styles}>{dataPDF.textBody}</Html>
                                                </View>


                                                <Text style={{
                                                    fontFamily: 'Roboto', fontSize: 11, marginTop: 15
                                                }}>Cordialmente,</Text>

                                                {respon &&
                                                    respon.map((r, index) => (
                                                        <View key={index} style={{ marginTop: 60 }}>
                                                            <View style={{ borderWidth: 0.7, borderColor: '#212529', width: '70%', alignSelf: 'center' }} />
                                                            <Text style={{
                                                                fontFamily: 'Roboto', fontSize: 11, fontWeight: 600, marginTop: 3, textAlign: 'center'
                                                            }}>{r.empresa}</Text>
                                                            <Text style={{
                                                                fontFamily: 'Roboto', fontSize: 11, marginTop: 1, textAlign: 'center'
                                                            }}>{r.nome}</Text>
                                                            <Text style={{
                                                                fontFamily: 'Roboto', fontSize: 11, marginTop: 1, textAlign: 'center'
                                                            }}>{r.funcao}</Text>
                                                        </View>
                                                    ))

                                                }

                                                <View style={{ display: 'flex', flexDirection: 'row', marginTop: 30, justifyContent: 'center', alignItems: 'center' }}>

                                                    <View style={{ marginRight: 10 }}>
                                                        <Link src={qrCodeData}>
                                                            <Image style={{ width: 60, opacity: 0.9 }} source={require('../../img/selo_assinatura.png')} />
                                                        </Link>
                                                    </View>

                                                    <View>
                                                        <Text style={{
                                                            fontFamily: 'Roboto', fontSize: 10, fontStyle: 'italic'
                                                        }}>(documento assinado eletronicamente)</Text>
                                                        <Text style={{
                                                            fontFamily: 'Roboto', fontSize: 11, marginTop: 2, fontWeight: '600'
                                                        }}>Hebert Rogério do Nascimento Coutinho</Text>
                                                        <Text style={{
                                                            fontFamily: 'Roboto', fontSize: 10, marginTop: 1
                                                        }}>Arqueólogo Coordenador</Text>
                                                    </View>

                                                </View>

                                            </View>

                                            <View fixed style={{ position: 'absolute', bottom: 20, right: 15, width: 166, backgroundColor: "#0c1c55", padding: 8, borderRadius: 8 }}>

                                                <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2 }}>
                                                    <Image style={{ width: 15, height: 15, marginRight: 5, brightness: 1.5, }} source={require('../../img/email.png')} />
                                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto', color: '#fff' }}>
                                                        hcoutinho@hcoutinho.com.br
                                                    </Text>
                                                </View>

                                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                    <Image style={{ width: 15, height: 15, marginRight: 5 }} source={require('../../img/phone.png')} />
                                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto', color: '#fff' }}>
                                                        86 99926-6344
                                                    </Text>
                                                </View>

                                            </View>

                                            <Text
                                                style={{
                                                    position: 'absolute',
                                                    bottom: 85,
                                                    right: 15,
                                                    textAlign: 'center',
                                                    fontSize: 10,
                                                    fontFamily: 'Roboto'
                                                }}
                                                render={({ pageNumber, totalPages }) =>
                                                    totalPages > 1 ? `Página ${pageNumber} de ${totalPages}` : null
                                                }
                                                fixed
                                            />

                                            <View fixed style={{ borderWidth: 0.5, borderColor: '#212529', position: 'absolute', bottom: 80, marginLeft: 15, marginRight: 15, width: '95%' }} />

                                            <View fixed style={{ position: 'absolute', bottom: 15, left: 15, flexDirection: 'row', width: 320 }}>
                                                <Image src={qrCodeImageUrl} style={{ height: 55, width: 55, marginRight: 5 }} />

                                                <View>
                                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>
                                                        Assinado eletronicamente em {diaHora} pelo AIPA Connect
                                                    </Text>
                                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto' }}>
                                                        A autenticidade deste documento pode ser
                                                        conferida acessando o QRCode, ou, via
                                                        sistema através do link <Link src={qrCodeData}>{qrCodeData}</Link>. informando o
                                                        token a seguir: <Text style={{ fontWeight: 600, letterSpacing: 1 }}>{dataPDF.token}</Text>
                                                    </Text>
                                                </View>
                                            </View>

                                            <View fixed style={{ position: 'absolute', bottom: 0, left: 0, flexDirection: 'row', width: "100%", height: 8, backgroundColor: '#0c1c55' }} />

                                        </Page>
                                    </Document>
                                } style={{ width: '60vw', height: '80vh', border: 'none', marginTop: 10 }}>
                                </PDFViewer>
                            </View>
                            :
                            <View>
                                <a className="header_txt">Ofício</a>
                                <button className="btn_pt_pv" onClick={() => setDataPDF(null) || setDataEdit({ interessado: dataPDF.interessado })}>Editar</button>
                                <button className="btn_pt_pv" onClick={() => assinarOficio()}>Assinar</button>
                                <PDFViewer showToolbar={false} children={
                                    <Document>
                                        <Page style={{ paddingBottom: 100, paddingTop: 135 }}>
                                            <View fixed style={{ position: 'absolute', top: 0, left: 0 }}>
                                                <Image style={{ width: 596 }} source={require('../../img/rodape_oficio_hc.png')} />
                                            </View>

                                            <View fixed style={{ backgroundColor: "#fff", position: 'absolute', right: 30, top: 30, padding: 10 }}>
                                                <Text style={{
                                                    fontFamily: 'Roboto', fontSize: 40, fontWeight: 600, color: 'red'
                                                }}>RASCUNHO</Text>
                                            </View>

                                            <View fixed style={{ position: 'absolute', bottom: 180, right: 0 }}>
                                                <Image style={{ width: 300, opacity: 0.08 }} source={require('../../img/simbolo_hc.png')} />
                                            </View>

                                            <View style={{ marginLeft: 80, marginRight: 80 }}>

                                                <Text style={{
                                                    fontFamily: 'Roboto', fontSize: 11
                                                }}>Ofício HC {numeracao}/{ano}</Text>

                                                <Text style={{
                                                    fontFamily: 'Roboto', fontSize: 11, marginTop: 15, textAlign: 'right'
                                                }}>Teresina, 00 de XXXX de 0000</Text>

                                                <Text style={{
                                                    fontFamily: 'Roboto', fontSize: 11, marginTop: 15
                                                }}>Ao</Text>

                                                {dataCab &&
                                                    dataCab.map((r, index) => (

                                                        <View key={index} >
                                                            <Text style={{
                                                                fontFamily: 'Roboto', fontSize: 11, fontWeight: 600, marginTop: 2, textAlign: 'justify'
                                                            }}>{r.interessado}</Text>
                                                            <Text style={{
                                                                fontFamily: 'Roboto', fontSize: 11, fontWeight: 600, marginTop: 2, textAlign: 'justify'
                                                            }}>{r.nome}</Text>
                                                            <Text style={{
                                                                fontFamily: 'Roboto', fontSize: 11, fontWeight: 600, marginTop: 2, textAlign: 'justify'
                                                            }}>{r.cargo}</Text>

                                                            {r.pessoas &&
                                                                r.pessoas.map((r, index) => (
                                                                    <View key={index} style={{ marginTop: 3, marginBottom: 3 }}>
                                                                        <Text style={{
                                                                            fontFamily: 'Roboto', fontSize: 11, fontWeight: 600, marginTop: 2, textAlign: 'justify'
                                                                        }}>{r.nome}</Text>

                                                                        <Text style={{
                                                                            fontFamily: 'Roboto', fontSize: 11, fontWeight: 600, marginTop: 2, textAlign: 'justify'
                                                                        }}>{r.cargo}</Text>
                                                                    </View>
                                                                ))
                                                            }

                                                            <Text style={{
                                                                fontFamily: 'Roboto', fontSize: 11, marginTop: 2, textAlign: 'justify'
                                                            }}>{r.endereco}</Text>
                                                            <Text style={{
                                                                fontFamily: 'Roboto', fontSize: 11, marginTop: 2
                                                            }}>CEP {r.cep}</Text>
                                                            <Text style={{
                                                                fontFamily: 'Roboto', fontSize: 11, marginTop: 2
                                                            }}>{r.cidadeEstado}</Text>



                                                        </View>
                                                    ))
                                                }

                                                <Text style={{
                                                    fontFamily: 'Roboto', fontSize: 11, marginTop: 15, fontWeight: 600, textAlign: 'justify'
                                                }}>Assunto: <Text style={{ fontWeight: 100 }}>{dataPDF.assunto}</Text></Text>
                                                <Text style={{
                                                    fontFamily: 'Roboto', fontSize: 11, marginTop: 2, fontWeight: 600, textAlign: 'justify'
                                                }}>Empreendimento: <Text style={{ fontWeight: 100 }}>{dataPDF.empreendimento}</Text></Text>
                                                <Text style={{
                                                    fontFamily: 'Roboto', fontSize: 11, marginTop: 2, fontWeight: 600, textAlign: 'justify'
                                                }}>Processo: <Text style={{ fontWeight: 100 }}>{dataPDF.projeto}</Text></Text>

                                                <View style={{ marginTop: 15 }}>
                                                    <Html stylesheet={styles}>{dataPDF.textBody}</Html>
                                                </View>

                                                {/* 

                                            <View style={{ marginTop: 12 }}>
                                                {dataPDF.textBody.map((p, index) => (
                                                    <Text style={{
                                                        fontFamily: 'Roboto', fontSize: 11, marginTop: 6, textAlign: 'justify', lineHeight: 1.4
                                                    }} key={index}>{p}</Text>
                                                ))}
                                            </View>

                                            */}

                                                <Text style={{
                                                    fontFamily: 'Roboto', fontSize: 11, marginTop: 15
                                                }}>Cordialmente,</Text>

                                                {respon &&
                                                    respon.map((r, index) => (
                                                        <View key={index} style={{ marginTop: 60 }}>
                                                            <View style={{ borderWidth: 0.7, borderColor: '#212529', width: '70%', alignSelf: 'center' }} />
                                                            <Text style={{
                                                                fontFamily: 'Roboto', fontSize: 11, fontWeight: 600, marginTop: 3, textAlign: 'center'
                                                            }}>{r.empresa}</Text>
                                                            <Text style={{
                                                                fontFamily: 'Roboto', fontSize: 11, marginTop: 1, textAlign: 'center'
                                                            }}>{r.nome}</Text>
                                                            <Text style={{
                                                                fontFamily: 'Roboto', fontSize: 11, marginTop: 1, textAlign: 'center'
                                                            }}>{r.funcao}</Text>
                                                        </View>
                                                    ))

                                                }

                                                <View style={{ display: 'flex', flexDirection: 'row', marginTop: 30, justifyContent: 'center', alignItems: 'center' }}>

                                                    <View style={{ marginRight: 10 }}>
                                                        <Image style={{ width: 60, opacity: 0.9 }} source={require('../../img/selo_assinatura.png')} />
                                                    </View>

                                                    <View>
                                                        <Text style={{
                                                            fontFamily: 'Roboto', fontSize: 10, fontStyle: 'italic'
                                                        }}>(documento assinado eletronicamente)</Text>
                                                        <Text style={{
                                                            fontFamily: 'Roboto', fontSize: 11, marginTop: 2, fontWeight: '600'
                                                        }}>Hebert Rogério do Nascimento Coutinho</Text>
                                                        <Text style={{
                                                            fontFamily: 'Roboto', fontSize: 10, marginTop: 1
                                                        }}>Arqueólogo Coordenador</Text>
                                                    </View>

                                                </View>

                                            </View>

                                            <Text
                                                style={{
                                                    position: 'absolute',
                                                    bottom: 85,
                                                    right: 15,
                                                    textAlign: 'center',
                                                    fontSize: 10,
                                                    fontFamily: 'Roboto'
                                                }}
                                                render={({ pageNumber, totalPages }) =>
                                                    totalPages > 1 ? `Página ${pageNumber} de ${totalPages}` : null
                                                }
                                                fixed
                                            />

                                            <View fixed style={{ position: 'absolute', bottom: 20, right: 15, width: 166, backgroundColor: "#0c1c55", padding: 8, borderRadius: 8 }}>

                                                <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 2 }}>
                                                    <Image style={{ width: 15, height: 15, marginRight: 5, brightness: 1.5, }} source={require('../../img/email.png')} />
                                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto', color: '#fff' }}>
                                                        hcoutinho@hcoutinho.com.br
                                                    </Text>
                                                </View>

                                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                    <Image style={{ width: 15, height: 15, marginRight: 5 }} source={require('../../img/phone.png')} />
                                                    <Text style={{ fontSize: 10, fontFamily: 'Roboto', color: '#fff' }}>
                                                        86 99926-6344
                                                    </Text>
                                                </View>

                                            </View>

                                            <View fixed style={{ borderWidth: 0.5, borderColor: '#212529', position: 'absolute', bottom: 80, marginLeft: 15, marginRight: 15, width: '95%' }} />

                                            <View fixed style={{ position: 'absolute', bottom: 0, left: 0, flexDirection: 'row', width: "100%", height: 8, backgroundColor: '#0c1c55' }} />

                                        </Page>
                                    </Document>
                                } style={{ width: '60vw', height: '80vh', border: 'none', marginTop: 10 }}>
                                </PDFViewer>
                            </View>
                        }

                    </div >
                    :
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                        <div style={{ marginBottom: 10 }}>
                            <a className="header_txt" style={{ marginRight: 10 }}>Ofício - HC {numeracao}/{ano}</a>
                            {cdInt !== true &&
                                <button className="btn_cd_inter" onClick={() => setCdInt(true)}>Cadastrar interessado</button>
                            }
                            <button className="btn_cd_inter" onClick={adicionarInput}>Adicionar responsável</button>
                        </div>

                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {/*
                            <input
                                type="text"
                                placeholder="Nome do Novo Interessado"
                                value={novoInteressado}
                                onChange={(e) => setNovoInteressado(e.target.value)}
                            />
                            */}
                                <Select
                                    options={listInt}
                                    //value={novoInteressado} // Define o valor selecionado
                                    onChange={handleInteressadoChange} // Chama a função de callback correta
                                    className="select"
                                    placeholder="Nome do Interessado"
                                />

                                <button className='btn_add_int' onClick={adicionarInteressado}>
                                    <IoMdAdd style={{ padding: 5, paddingLeft: 15, paddingRight: 15, fontSize: 15, color: '#fff' }} />
                                </button>

                            </div>

                            {cdInt === true &&
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div style={{
                                        display: 'flex', flexDirection: 'column', borderRadius: 10, marginBottom: 10,
                                        padding: 10, borderStyle: 'solid', borderWidth: 2, color: "#003049"
                                    }}>

                                        <Controller
                                            control={control}
                                            name="interessado"
                                            render={({ field: { onChange, value } }) => (
                                                <input
                                                    type="text"
                                                    className='input'
                                                    placeholder="Interessado"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />

                                        {errors.interessado && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.interessado?.message}</span>}

                                        <Controller
                                            control={control}
                                            name="nome"
                                            render={({ field: { onChange, value } }) => (
                                                <input
                                                    type="text"
                                                    className='input'
                                                    placeholder="Nome"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />

                                        {errors.nome && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.nome?.message}</span>}

                                        <Controller
                                            control={control}
                                            name="cargo"
                                            render={({ field: { onChange, value } }) => (
                                                <input
                                                    type="text"
                                                    className='input'
                                                    placeholder="Cargo"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />

                                        {errors.cargo && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.cargo?.message}</span>}

                                        <Controller
                                            control={control}
                                            name="endereco"
                                            render={({ field: { onChange, value } }) => (
                                                <input
                                                    type="text"
                                                    className='input'
                                                    placeholder="Endereço"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />

                                        {errors.endereco && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.endereco?.message}</span>}

                                        <Controller
                                            control={control}
                                            name="cep"
                                            render={({ field: { onChange, value } }) => (
                                                <input
                                                    type="text"
                                                    className='input'
                                                    placeholder="CEP"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />

                                        {errors.cep && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.cep?.message}</span>}

                                        <Controller
                                            control={control}
                                            name="cidadeEstado"
                                            render={({ field: { onChange, value } }) => (
                                                <input
                                                    type="text"
                                                    className='input'
                                                    placeholder="Cidade - Estado"
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />

                                        {errors.cidadeEstado && <span style={{ color: '#d62828', marginBottom: '10px' }}>{errors.cidadeEstado?.message}</span>}


                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <div className="car_cadastro">
                                                <button type="submit" className="btn_cd_n">Cadastrar</button>
                                            </div>
                                            <button onClick={() => setCdInt(false)} className="btn_quit_int">
                                                <IoClose style={{ padding: 5, paddingLeft: 15, paddingRight: 15, fontSize: 15, color: '#fff' }} />
                                            </button>
                                        </div>


                                    </div>
                                </form>
                            }

                            {interessados.map((interessado, index) => (
                                <div key={index}>

                                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                                        <div style={{ padding: 8, backgroundColor: '#ccc', borderRadius: 6, flex: 1, marginBottom: 10 }}>
                                            <a>{interessado.nome}</a>
                                        </div>

                                        <button className='btn_add_pessoa' style={{ height: 35 }} onClick={() => adicionarPessoaAoInteressado(index)}>
                                            <FaRegUser style={{ padding: 5, paddingLeft: 15, paddingRight: 15, fontSize: 15, color: '#fff' }} />
                                        </button>

                                    </div>

                                    {interessado.pessoas && interessado.pessoas.map((pessoa, pessoaIndex) => (
                                        <div key={pessoaIndex} style={{ display: 'flex', flexDirection: 'column' }}>
                                            <input
                                                type="text"
                                                className='input'
                                                placeholder={'Nome do interessado'}
                                                value={pessoa.nome}
                                                onChange={(e) => handleNomePessoaChange(index, pessoaIndex, e)}
                                            />
                                            <input
                                                type="text"
                                                className='input'
                                                placeholder={'Cargo do interessado'}
                                                value={pessoa.cargo}
                                                onChange={(e) => handleCargoPessoaChange(index, pessoaIndex, e)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ))}
                            {/*<pre>{JSON.stringify(interessados, null, 2)}</pre>*/}
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 5 }}>
                            <a style={{ fontWeight: 600, marginRight: 5 }}>Estrutura do Ofício</a>
                            <div style={{ borderBottomStyle: 'solid', borderBottomWidth: 2, borderColor: '#495057', flex: 1 }} />
                        </div>

                        <input
                            type="text"
                            className='input'
                            placeholder="Assunto"
                            onChange={(x) => setAssunto(x.target.value)}
                            value={assunto}
                        />

                        <input
                            type="text"
                            className='input'
                            placeholder="Empreendimento"
                            onChange={(x) => setEmp(x.target.value)}
                            value={empree}
                        />

                        <input
                            type="text"
                            className='input'
                            placeholder="Processo"
                            onChange={(x) => setPro(x.target.value)}
                            value={projeto}
                        />

                        <div style={{ height: 380 }}>
                            <Editor
                                apiKey='7xvhftuwxmiwzfc46kd4yphawki3cwqzbjy2a3mg9v05wqt8'
                                init={{
                                    selector: 'textarea',
                                    resize: false,
                                    height: 380,
                                    plugins: 'ai mentions anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste autocorrect a11ychecker typography inlinecss',
                                    toolbar: 'undo redo |bold italic underline strikethrough | numlist bullist | indent outdent | table ',
                                    menu: {
                                        oficio: { title: 'Texto do ofício', items: 'code' }
                                    },
                                    menubar: 'oficio',
                                    ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                                    entity_encoding: "raw",
                                }}
                                initialValue={TBBI}
                                onEditorChange={(content, editor) => { setBody(content) }}
                            />
                        </div>

                        {responsaveis.map((responsavel, index) => (
                            <div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 5, marginTop: 10 }}>
                                    <a style={{ fontWeight: 600, marginRight: 5 }}>Novo interessado</a>
                                    <div style={{ borderBottomStyle: 'solid', borderBottomWidth: 2, borderColor: '#495057', flex: 1 }} />
                                    <button onClick={() => setResponsaveis([]) || setRespon(null)} className="btn_quit_int">
                                        <IoClose style={{ padding: 5, paddingLeft: 15, paddingRight: 15, fontSize: 15, color: '#fff' }} />
                                    </button>
                                </div>

                                <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <input
                                        type="text"
                                        className='input'
                                        placeholder="Empresa"
                                        name="empresa"
                                        value={responsavel.empresa}
                                        onChange={(event) => handleInputChange(index, event)}
                                    />
                                    <input
                                        type="text"
                                        className='input'
                                        placeholder="Nome"
                                        name="nome"
                                        value={responsavel.nome}
                                        onChange={(event) => handleInputChange(index, event)}
                                    />
                                    <input
                                        type="text"
                                        className='input'
                                        placeholder="Função"
                                        name="funcao"
                                        value={responsavel.funcao}
                                        onChange={(event) => handleInputChange(index, event)}
                                    />
                                </div>
                            </div>
                        ))}

                        <div className="car_cadastro" style={{ marginTop: 10 }}>
                            <button onClick={gerarOficio} className="btn_cd_n">Gerar ofício</button>
                        </div>

                    </div>
                }
            </div >
        </div >
    )
}

export default Oficio;