import React, { useState, useEffect, useContext } from "react";
import './styles.css'
import PageFront from "./PageFront";
import Sidebar from "../Components/Sidebar"
import { AuthContext } from "../Context/AuthContext";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import Campos from "./Campos"
import Dashboard from "./Dashboard"
import PontosLev from "./PontosLev"
import Usuarios from "./Usuarios"
import Fichas from "./Fichas"
import Home from "./Home"
import Edicao from "./Edicao"
import Oficio from "./Oficio";
import Tabelas from "./Tabelas";
import InfoCampo from "./InfoCampo";

import { auth } from '../services/firebaseConnection'
import useAuth from "../Hooks/useAuth";
import { onAuthStateChanged } from 'firebase/auth'

function App() {
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        //faz nada

      } else {
        window.location.pathname = "/"
      }
    });
  }, [])

  return (

    <Sidebar className="App">
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/edit' element={<Edicao />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/panorama' element={<PontosLev />} />
        <Route path='/usuarios' element={<Usuarios />} />
        <Route path='/projetos' element={<Campos />} />
        <Route path='/fichas' element={<Fichas />} />
        <Route path='/assinaturaEletronica' element={<Oficio />} />
        <Route path='/tabelas' element={<Tabelas />} />
        <Route path='/infoCampo' element={<InfoCampo />} />
      </Routes>

      {/*}
        <div className="app">
          <div className="barra_lateral">
            <button type="button" onClick={Print} className="button_lat" >IMPRIMIR</button>
          </div>
          <div className="page_print_border">
            <div id='printablediv' className="page_print">
              <PDFContent />
            </div>
          </div>
        </div>
      {*/}

    </Sidebar>

  );
}

export default App;
