import React, { useEffect } from 'react';
import { auth, db } from '../../services/firebaseConnection'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { ref as ref_database, set, child, get } from "firebase/database";
import { useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import './styles.css';

function Login() {

    const { document } = window;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("")
    const [erro, setErro] = useState("")


    const handleClick = async (e) => {

        const dbRef = ref_database(db);



        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const uid = userCredential.user.uid
                get(child(dbRef, 'userperm/' + uid)).then((snapshot) => {
                    if (snapshot.exists()) {
                        const dados = snapshot.val()
                        if (dados.nivel == 2) {
                            //window.location.pathname = "/info"
                        } else if (dados.nivel == 1) {
                            window.location.pathname = "/sy/"
                        }
                    }

                })

            })
            .catch((error) => {
                const errorCode = error.code;
                //const errorMessage = error.message;
                if (errorCode == 'auth/invalid-email') {
                    setErro("Insira um email válido!")
                } else if (errorCode == 'auth/missing-password') {
                    setErro("Insira sua senha!")
                } else if (errorCode == 'auth/user-not-found') {
                    setErro("Email e senha incorretos!")
                } else if (errorCode == 'auth/wrong-password') {
                    setErro("Email e senha incorretos!")
                }
                console.log(errorCode)
            });
    }

    const getCert = () => { window.location.pathname = "/certificado" }

    useEffect(() => {
        document.title = 'Login - AIPA Connect';
    }, []);

    const ColorButton = styled(Button)(({ theme }) => ({
        color: "#fff",
        borderRadius: 10,
        borderColor: "#56ab91",
        backgroundColor: "#56ab91",
        '&:hover': {
            backgroundColor: "#469d89",
            borderColor: "#56ab91",
        },
        width: 150
    }));

    return (

        <div className='body-login'>

            <div style={{ position: 'absolute', bottom: 15, right: 15 }} className='btn_hc' onClick={() => window.open('https://hcoutinho.com.br/', '_blank')}>
                <a style={{ color: '#fff', fontSize: 14 }}>HCoutinho Pesquisa e Desenvolvimento</a>
            </div>

            <section className='area-login'>

                <div className='card-logo'>
                    <img src={require('../../img/logo-sbg.png')} />

                    <div style={{ marginTop: 15, textAlign: 'center' }}>
                        <a style={{ color: '#fff', fontSize: 14 }}>Ambiente Integrado de Pesquisa Arqueológica - AIPA Connect</a>
                    </div>

                    <div style={{ marginTop: 20 }}>
                        <WhatsAppIcon sx={{ fontSize: 35, color: '#fff', cursor: 'pointer' }} onClick={() => window.open('https://wa.me/5586999266344', '_blank')} />
                        <LinkedInIcon sx={{ fontSize: 35, color: '#fff', cursor: 'pointer' }} onClick={() => window.open('https://www.linkedin.com/company/hcoutinho', '_blank')} />
                    </div>

                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div className='login'>

                        <div style={{ marginTop: 10, marginBottom: 5, alignSelf: 'start' }}>
                            <a style={{ fontSize: 22, fontWeight: 'bold' }}>Login</a>
                        </div>

                        <input
                            placeholder="Email"
                            id="email"
                            onChange={(e) => setEmail(e.target.value)}
                        ></input>

                        <input
                            placeholder="Senha"
                            type="password"
                            id="password"
                            onChange={(e) => setPassword(e.target.value)}
                        ></input>

                        {erro &&
                            <div className='msgErro'>
                                <a>{erro}</a>
                            </div>
                        }

                        <ColorButton variant="outlined" onClick={handleClick} style={{ marginTop: 15, width: 150, textTransform: 'none', height: 40 }}>
                            <Typography style={{ fontFamily: 'Inter', fontSize: 16 }}>Login</Typography>
                        </ColorButton>

                    </div>

                    <div onClick={() => window.location.pathname = "verificacao/"} style={{ margin: 15, cursor: 'pointer' }}>
                        <a style={{ fontWeight: 'bold', color: '#fff', fontSize: 14 }}>Verificação de Autenticidade</a>
                    </div>

                    <div style={{ margin: -5 }}>
                        <a style={{ color: '#fff', fontSize: 14 }}>Versão 3.8.00</a>
                    </div>
                </div>

            </section>

        </div>
    )
}

export default Login;