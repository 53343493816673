import React, { useState, useEffect } from "react";
import Select from 'react-select'
import './styles.css'
import PDFContent from "./PDFContent";
import PDFContentPV from "./PDFContentPV";

import TestFicha from "../TestFicha";

import PrintIcon from '@mui/icons-material/Print';
import CachedIcon from '@mui/icons-material/Cached';

import { ToastContainer, toast } from 'react-toastify';

import { db, storage } from '../../services/firebaseConnection'
import { ref as ref_database, child, get, set } from "firebase/database";

function Fichas() {

    const [info, setInfo] = useState("");
    const [projeto, setProjeto] = useState("");
    const [nameProj, setNameProj] = useState(null);
    const [projetoAntigo, setProjetoAntigo] = useState("");
    const [projetoPV, setProjetoPV] = useState("");
    const [bkProjeto, setBkProjeto] = useState("");
    const [pt, setPT] = useState(false);
    const [pv, setPV] = useState(false);
    const [tokken, setTokken] = useState("");

    const Print = () => {
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload(false);
    }

    const resetData = () => {
        window.location.reload(false);
    }

    function geraStringAleatoria(tamanho, tokensExistente) {
        var stringAleatoria = '';
        var caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var tokenUnica = false;

        while (!tokenUnica) {
            stringAleatoria = '';
            for (var i = 0; i < tamanho; i++) {
                stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
            }

            // Verifica se a nova string gerada já existe no array de tokens existentes
            if (!tokensExistente.includes(stringAleatoria)) {
                tokenUnica = true;
            }
        }

        return stringAleatoria;
    }

    function assinarPDF() {

        if (nameProj === null) {
            noSelect()
        } else {

            const dbRef = ref_database(db);

            get(child(dbRef, 'assinatura/')).then((snapshot) => {
                if (snapshot.exists()) {
                    const dados = snapshot.val();
                    const projSearch = nameProj;

                    const tokensExistentes = Object.keys(dados).map(obj => obj).flat();

                    const rsData = Object.values(dados).find(
                        objeto => objeto.projeto === projSearch
                    );

                    if (rsData) {

                        const now = new Date(rsData.data);
                        const dia = now.getDate().toString().padStart(2, '0');
                        const mes = (now.getMonth() + 1).toString().padStart(2, '0');
                        const ano = now.getFullYear().toString();
                        const hora = now.getHours().toString().padStart(2, '0');
                        const minuto = now.getMinutes().toString().padStart(2, '0');

                        const dataFormatada = `${dia}/${mes}/${ano} ${hora}:${minuto}`;


                        setInfo({ projeto: rsData.projeto, token: rsData.token, data: dataFormatada, autor: rsData.autor, timestamp: rsData.data })


                    } else {

                        const tokenPDF = geraStringAleatoria(8, tokensExistentes)

                        const now1 = Date.now();

                        set(ref_database(db, 'assinatura/' + tokenPDF), {
                            token: tokenPDF,
                            projeto: nameProj,
                            data: now1,
                            tipo: "pt",
                            autor: "Hebert Rogério do Nascimento Coutinho"
                        })

                        const now = new Date(now1);
                        const dia = now.getDate().toString().padStart(2, '0');
                        const mes = (now.getMonth() + 1).toString().padStart(2, '0');
                        const ano = now.getFullYear().toString();
                        const hora = now.getHours().toString().padStart(2, '0');
                        const minuto = now.getMinutes().toString().padStart(2, '0');

                        const dataFormatada = `${dia}/${mes}/${ano} ${hora}:${minuto}`;

                        setInfo({ projeto: nameProj, token: tokenPDF, data: dataFormatada, timestamp: now1 })

                    }

                } else {
                    console.log("No data available");
                }
            }).catch((error) => {
                console.error(error);
            });

        }

    }

    const noSelect = () => toast.error('Selecione o projeto!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    useEffect(() => {

        const dbRef = ref_database(db);

        get(child(dbRef, 'projetos/list')).then((snapshot) => {
            if (snapshot.exists()) {
                const dados = snapshot.val()

                var arrProjs = []
                dados.map(x => { arrProjs.push(x.projeto) })
                function myFunction(num) {
                    return { value: num, label: num };
                }
                const map1 = arrProjs.map(myFunction);
                setBkProjeto(map1)

            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });

    }, [])

    return (
        <div>

            <ToastContainer />

            <div className="car_ficha">


                {info ? (
                    <div></div>
                ) :
                    <div className="card_select">

                        <a className="header_txt">Fichas de campo</a>

                        <button className="btn_pt_pv" onClick={() => { setPT(true) || setPV(false) }}>POÇO TESTE</button>

                        <button className="btn_pt_pv" onClick={() => { setPT(false) || setPV(true) }}>PONTO DE VISTORIA</button>

                        {pt &&
                            <div>
                                <Select
                                    options={bkProjeto}
                                    placeholder="Projeto"
                                    className="select_projeto"
                                    onChange={(item) => setNameProj(item.value)}
                                />

                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                    <a>Assinar documento eletronicamente</a>

                                    <div className="card_input_user">
                                        <button onClick={assinarPDF} className="btn_cd" >Assinar</button>
                                    </div>

                                </div>
                            </div>
                        }

                        {pv &&
                            <Select
                                options={bkProjeto}
                                placeholder="Projeto"
                                className="select_projeto"
                                onChange={(item) => setProjetoPV(item.value)}
                            />
                        }


                    </div>
                }

                {info &&
                    <div className="card_select" style={{ display: 'flex', flexDirection: 'column' }}>

                        <a className="header_txt">Fichas de campo</a>

                        <a>{nameProj}</a>
                        <a>Token de assinatura: <span style={{ fontWeight: '600' }}>{info.token}</span></a>
                        <a>Assinado eletronicamente por Heber Rogério do Nascimento Coutinho</a>
                        <a>Em {info.data}</a>

                        <div className="card_input_user">
                            <button onClick={() => setProjeto({ projeto: info.projeto, token: info.token, diaHr: info.data, timestamp: info.timestamp }) || setInfo(false) || setPT(false)} className="btn_cd" >Gerar PDF</button>
                        </div>

                    </div>
                }

                {projetoAntigo &&
                    <div>

                        <div className="container_button_print">

                            <button type="button" onClick={resetData} className="button_lat">
                                <CachedIcon style={{ color: '#FFF', fontSize: '20px', justifySelf: 'center' }} />
                                <span className="text_btn">Mudar projeto</span>
                            </button>

                            <div>Confira se as imagens carregaram antes de imprimir a ficha!</div>

                            <button type="button" onClick={Print} className="button_lat">
                                <PrintIcon style={{ color: '#FFF', fontSize: '20px', justifySelf: 'center' }} />
                                <span className="text_btn">Imprimir</span>
                            </button>

                        </div>
                        <div className="page_print_border" style={{ height: '80vh', width: '100%', overflowY: 'scroll' }}>
                            <div id='printablediv' className="page_print">
                                <PDFContent name={projeto} />
                            </div>
                        </div>
                    </div>
                }

                {projeto &&
                    <div>
                        <div className="container_button_print">

                            <button type="button" onClick={resetData} className="button_lat">
                                <CachedIcon style={{ color: '#FFF', fontSize: '20px', justifySelf: 'center' }} />
                                <span className="text_btn">Mudar projeto</span>
                            </button>

                        </div>
                        <div className="page_print_border" style={{ height: '80vh', width: '100%' }}>
                            <div id='printablediv' >
                                <TestFicha name={projeto.projeto} token={projeto.token} data={projeto.diaHr} timestamp={projeto.timestamp} />
                            </div>
                        </div>
                    </div>
                }

                {projetoPV &&
                    <div>

                        <div className="container_button_print">

                            <button type="button" onClick={resetData} className="button_lat">
                                <CachedIcon style={{ color: '#FFF', fontSize: '20px', justifySelf: 'center' }} />
                                <span className="text_btn">Mudar projeto</span>
                            </button>

                            <div>Confira se as imagens carregaram antes de imprimir a ficha!</div>

                            <button type="button" onClick={Print} className="button_lat">
                                <PrintIcon style={{ color: '#FFF', fontSize: '20px', justifySelf: 'center' }} />
                                <span className="text_btn">Imprimir</span>
                            </button>

                        </div>

                        <div className="page_print_border" style={{ height: '80vh', width: '100%', overflowY: 'scroll' }}>
                            <div id='printablediv' className="page_print">
                                <PDFContentPV name={projetoPV} />
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div >
    );
};
export default Fichas;