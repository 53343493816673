import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, storage, auth } from '../../../services/firebaseConnection';
import { ref as ref_database, set, child, get } from "firebase/database";
import { onAuthStateChanged } from "firebase/auth";
import { FadingBalls } from "react-cssfx-loading";


function GetUser() {

    const navigate = useNavigate();

    useEffect(() => {
        const dbRef = ref_database(db);
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
                get(child(dbRef, 'usuarios/' + uid)).then((snapshot) => {
                    if (snapshot.exists()) {
                        const dados = snapshot.val()
                        console.log(dados)
                        dados.uid = uid
                        navigate('/update', { state: dados });
                        //window.location.pathname = "/home"
                    }
                })
            }
        });
    }, [])

    return (

        <div style={{ overflow: 'hidden' }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh',
                    width: '100vw',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <a style={{ fontSize: 20, fontWeight: '800', marginBottom: '10px' }}>Carregando usuário</a>
                <FadingBalls width="120px" height="30px" color="#30ae74" />
                <div />
            </div>
        </div>

    )
}

export default GetUser;