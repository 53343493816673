import React, { useState, useEffect } from 'react';
import NewRegister from '../NewRegister';
import EditRegister from '../EditRegister';
import DashHome from '../DashHome';
import './styles.css';
import { useLocation } from 'react-router-dom';
import { IoMdExit } from "react-icons/io";
import { AiFillHome } from "react-icons/ai";
import { BsFillPlusSquareFill } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { db, storage, auth } from '../../../services/firebaseConnection';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';


function Home() {

  const { document } = window;
  const navigate = useNavigate();
  const location = useLocation();

  const [dashHome, setDashHome] = useState(true);
  const [newInt, setNewInt] = useState(false);
  const [editInt, setEditInt] = useState(false);
  const [data, setData] = useState(false);

  async function quitUser() {
    signOut(auth).then(() => {
      navigate('/');
    }).catch((error) => {
      // An error happened.
    });
  }

  useEffect(() => {
    document.title = 'Plataforma do Usuário - AIPA Connect';

    //const projeto = location.state.projeto
    //const uid = location.state.uid
    setData(location.state)
    if (location.state == null) {
      navigate('/')
    }
  }, [])

  return (
    <div className='body_aipa'>

      <div className='header'>
        <div>
          <img src={require('../../../img/logo-aipa-sim.png')} className="img_aipa_header"></img>
        </div>

        <div>
          <button className={dashHome ? "btn_cd_home_clicked" : 'btn_cd_home'} onClick={() => setDashHome(true) || setNewInt(false) || setEditInt(false)}>
            <div className='into_btn_header'>
              <AiFillHome />
              <a>Home</a>
            </div>

          </button>
          <button className={newInt ? "btn_cd_home_clicked" : 'btn_cd_home'} onClick={() => setNewInt(true) || setEditInt(false) || setDashHome(false)}>
            <div className='into_btn_header'>
              <BsFillPlusSquareFill />
              <a>Cadastrar</a>
            </div>
          </button>
          <button className={editInt ? "btn_cd_home_clicked" : 'btn_cd_home'} onClick={() => setEditInt(true) || setNewInt(false) || setDashHome(false)}>
            <div className='into_btn_header'>
              <FaEdit />
              <a>Editar</a>
            </div>
          </button>
        </div>

        <div>
          <button className="btn_quit" onClick={quitUser}><IoMdExit />Sair</button>
        </div>
      </div>

      <div className='card_center'>

        {dashHome &&
          <div className="card_home_new" >
            <div className="card_register">
              <DashHome />
            </div>
          </div>
        }

        {newInt &&
          <div className="card_home_new" >
            <div className="card_register">
              <NewRegister data={data} />
            </div>
          </div>
        }

        {editInt &&
          <div className="card_home_edit" >
            <div className="card_edit">
              <EditRegister projeto={data} />
            </div>
          </div>
        }

      </div>


    </div >
  )
}

export default Home;