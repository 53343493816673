import React, { useState, useEffect } from "react";
import { db, storage } from '../../services/firebaseConnection'
import { ref as ref_database, child, get } from "firebase/database";
import { ref as ref_storage, getDownloadURL, listAll } from "firebase/storage";
import './styles.css'

function PDFContent(props) {

  const [data, setData] = useState([])
  const [partes, setPartes] = useState([])
  const [entornoData, setEntorno] = useState([])
  const [totalData, setTotalData] = useState([])
  const [pedologia, setPedologia] = useState([])
  const [material, setMaterial] = useState([])
  const [encerramento, setEncerramento] = useState([])
  const [instrans, setInstrans] = useState([])
  //const [campo, setCampo] = useState([])
  //const [usuario, setUsuario] = useState([])
  const [urlImage, setUrlImage] = useState([])
  const [paginaAtual, setPaginaAtual] = useState(1)
  const [hidePhoto, setHidePhoto] = useState(false)

  const userSelect = props.name

  /*const listItem = () => {

    const dbRef = ref_database(db);

    get(child(dbRef, 'usuarios/' + userSelect)).then((snapshot) => {
      if (snapshot.exists()) {
        const dados = snapshot.val()

        setEntorno(dados.entorno);
        setPedologia(dados.pedologia)
        setEncerramento(dados.encerramento)
        setInstrans(dados.instransponibilidade)
        if (dados.evidencias) {
          setMaterial(dados.evidencias)
        } else {
          setMaterial([])
        }
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });

    get(child(dbRef, 'userperm/' + userSelect)).then((snapshot) => {
      if (snapshot.exists()) {
        const dados = snapshot.val()
        setCampo(dados)
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });


    const starsRef = ref_storage(storage, userSelect + '/');

    listAll(starsRef)
      .then(async (res) => {
        res.items.forEach((itemRef) => {

          const starsRefe = ref_storage(storage, userSelect + '/' + itemRef.name);

          getDownloadURL(starsRefe)
            .then((url) => {
              setUrlImage(arr => [...arr, url])
            })

        });
      }).catch((error) => {
        // Uh-oh, an error occurred!
      });

  }*/

  useEffect(() => {

    const dbRef = ref_database(db);

    get(child(dbRef, 'backup/' + userSelect)).then((snapshot) => {
      if (snapshot.exists()) {
        const dados = snapshot.val()

        const entornoPass = dados.dados
        const keyPontos = Object.keys(entornoPass)

        var arrDataFull = []
        Object.entries(entornoPass).forEach(function (data) {
          arrDataFull[data[0]] = data[1]
        });

        const dataArr = Object.entries(arrDataFull)

        dataArr.sort((a, b) => {
          const numA = parseInt(a[0].split('-')[1]);
          const numB = parseInt(b[0].split('-')[1]);
          return numA - numB;
        });

        /*let modifiedArr = entornoPass.map(function (element) {
          return element.info;
        });

        modifiedArr.sort(function(a,b){ return  a.pt.match(/\d+/) - b.pt.match(/\d+/) })
        //console.log(entornoPass)

        function separar(base, max) {
          var res = [];
          
          for (var i = 0; i < base.length; i = i+(max-1)) {
            res.push(base.slice(i,(i+max)));
          }
          res[res.length-1].push(base[0]);
          return res;
        }

        const blocoEntorno = separar(dados.entorno, 1)

        const entorno = dados.entorno

        let pontoesp = entorno.filter(d => {
          return d.pt === "PT 29-2D"
        })*/

        setData(dados)
        setTotalData(dataArr)

        //setPedologia(dados.pedologia)
        //setEncerramento(dados.encerramento)
        //setInstrans(dados.instransponibilidade)
        //if (dados.evidencias) {
        //setMaterial(dados.evidencias)
        //} else {
        //setMaterial([])
        //}

        const itensPorPagina = 50;
        const results = dataArr
        const qnt = results.length
        const parts = Math.ceil(qnt / itensPorPagina)

        var arrParts = []
        for (let i = 1; i <= parts; i++) {
          const partsN = { a: ((i * 50) - 50), b: (i * 50) }
          arrParts.push(partsN);
        }

        setPartes(arrParts)

        const primeiros50 = results.slice(0, 50)

        setEntorno(primeiros50);
        //setEntorno(arrDataFull);

      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });

    /*get(child(dbRef, 'userperm/' + userSelect)).then((snapshot) => {
      if (snapshot.exists()) {
        const dados = snapshot.val()
        setCampo(dados)
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });

    get(child(dbRef, 'users/' + userSelect)).then((snapshot) => {
      if (snapshot.exists()) {
        const dados = snapshot.val()
        setUsuario(dados)
        //console.log(dados)
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });*/
    //const starsRef = ref_storage(storage, 'oCDYQhiBX1UabclsbEah65ZZBtk2' + '/');
    /*listAll(starsRef)
      .then(async (res) => {
        res.items.forEach((itemRef) => {

          const starsRefe = ref_storage(storage, 'oCDYQhiBX1UabclsbEah65ZZBtk2' + '/' + itemRef.name);

          getDownloadURL(starsRefe)
            .then((url) => {

              const urlConsult = starsRefe.name
              var nameUrl = urlConsult.replace("AIPA-CONNECT-", "")
              var nameUrlS = nameUrl.replace("-Sedimento", "")
              var nameUrlE = nameUrlS.replace("-Execução", "")
              var nameUrlP = nameUrlE.replace("-Profundidade", "")
              var nameUrlF = nameUrlP.replace("-Fechamento", "")
              //var nameUrlPNG = nameUrlF.replace(".jpeg", "")

              const namePontoUrl = nameUrlF.slice(0, -10)
              const objUrl = [namePontoUrl, { namePontoUrl: url }]

              //console.log(nameUrlPNG)


              //setUrlImage(arr => [...arr, url])
              setUrlImage(arr => [...arr, objUrl])

            })

        });
      }).catch((error) => {
        // Uh-oh, an error occurred!
      });*/

  }, [])

  const clickPart = (data) => {
    const sliceOne = partes[data].a
    const sliceTwo = partes[data].b
    const resultClickPart = totalData.slice(sliceOne, sliceTwo)
    setEntorno(resultClickPart);
  }


  return (

    <div style={{ width: '100%' }}>

      {/*<button onClick={listItem} className="button_send">Atualizar ficha</button>*/}

      <div>
        <div className="container_capa">
          <div className="card_img_capa">
            <div>
              <img src={require('../../img/logo-sbg.png')} className="img_aipa_capa"></img>
              <div className="card_text_capa">
                <a className="text_title_capa">{data.projeto}</a>
              </div>
              <div className="card_text_border_capa">
                <div className="card_text_capa">
                  <a className="text_capa">Processo N° {data.protocolo}</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card_img_hc_capa">
          <div className="card_img_capa">
            <img src={require('../../img/logo-hc.png')} className="img_hc_capa"></img>
          </div>
        </div>

        <div className='page-divide'></div>

      </div>

      <div className="card_parts_photo">

        {partes.map((item, index) => (
          <div key={index} className="btns_parts">
            <button type="button" className="btn_on_parts" onClick={() => clickPart(index)}>
              <a>Parte {index + 1}</a>
            </button>

          </div>
        ))}

        <div>
          <button className="btn_on_parts" onClick={() => setHidePhoto(!hidePhoto)}>
            <a>Fotos</a>
          </button>

        </div>


      </div>

      <div>

        {entornoData.map((item, index) => (

          <div key={index}>

            <div className="card_img">

              <div className="card_container">
                <a className="text_title_ficha">FICHA DE INTERVENÇÃO ARQUEOLÓGICA</a>
              </div>

              <img src={require('../../img/logo-aipa-sim-b.png')} className="img_aipa"></img>

            </div>

            <div>

              <div className="container_projeto">
                <div className="card_info">
                  <a className="text_font_bold">Projeto</a>
                </div>

                <div className="card_info_result">
                  <a className="text_font">{data.projeto}</a>
                </div>
              </div>

              <div className="card_projeto">
                <div className="container_projeto">
                  <div className="card_info_pd">
                    <a className="text_font_bold">Pesquisador</a>
                  </div>

                  <div className="card_info_result_pd">
                    <a className="text_font">Hebert Coutinho</a>
                  </div>
                </div>

                <div className="container_projeto">
                  <div className="card_info_pt">
                    <a className="text_font_bold">N° do PT</a>
                  </div>

                  <div className="card_info_result_pt">
                    <a className="text_font">{item[0]}</a>
                  </div>
                </div>

                <div className="container_projeto">
                  <div className="card_info_pd">
                    <a className="text_font_bold">Município</a>
                  </div>

                  <div className="card_info_result_pd">
                    <a className="text_font">{data.municipio}</a>
                  </div>
                </div>

                <div className="container_projeto">
                  <div className="card_info_pd">
                    <a className="text_font_bold">Intervenção</a>
                  </div>

                  <div className="card_info_result_pd">
                    <a className="text_font">Poço-Teste</a>
                  </div>
                </div>

                <div className="container_projeto">
                  <div className="card_info_pd">
                    <a className="text_font_bold">Data</a>
                  </div>

                  <div className="card_info_result_pd">
                    <a className="text_font">{item[1].entorno.timestamp}</a>
                  </div>
                </div>

                <div className="container_projeto">
                  <div className="card_info_pd">
                    <a className="text_font_bold">Coordernada</a>
                  </div>

                  <div className="card_info_result_pd">
                    <a className="text_font">{item[1].entorno.utm}</a>
                  </div>
                </div>
              </div>

              <div className="card_container_meio">
                <a className="text_title_ficha">Contextualização Ambiental</a>
              </div>

              <div className="container_projeto">
                <div className="card_info_result_pd">
                  <a className="text_font">{item[1].entorno.descricao}</a>
                </div>
              </div>

              <div className="card_context_amb">

                <div className="container_projeto">
                  <div className="card_info_pd">
                    <a className="text_font_bold">Vegetação</a>
                  </div>

                  <div className="card_info_result_pd">
                    <a className="text_font">{item[1].entorno.vegetacao}</a>
                  </div>
                </div>

                <div className="container_projeto">
                  <div className="card_info_pd">
                    <a className="text_font_bold">Declividade</a>
                  </div>

                  <div className="card_info_result_pd">
                    <a className="text_font">{item[1].entorno.declividade}</a>
                  </div>
                </div>

                <div className="container_projeto">
                  <div className="card_info_pd">
                    <a className="text_font_bold">Feição de Relevo</a>
                  </div>

                  <div className="card_info_result_pd">
                    <a className="text_font">{item[1].entorno.feicRelevo}</a>
                  </div>
                </div>

                <div className="container_projeto">
                  <div className="card_info_pd">
                    <a className="text_font_bold">Posição topográfica</a>
                  </div>

                  <div className="card_info_result_pd">
                    <a className="text_font">{item[1].entorno.posicaoTop}</a>
                  </div>
                </div>

              </div>

              {item[1].pedologia &&
                <div>

                  <div>
                    <div className="card_container_meio">
                      <a className="text_title_ficha">Pedologia</a>
                    </div>

                    <div className="container_pedologia">
                      <div className="card_ped_tl">
                        <a className="text_font_bold">Nível</a>
                      </div>

                      <div className="card_ped_tl">
                        <a className="text_font_bold">Coloração</a>
                      </div>

                      <div className="card_ped_tl">
                        <a className="text_font_bold">Granulometria </a>
                      </div>

                      <div className="card_ped_tl">
                        <a className="text_font_bold">Material Arqueológico </a>
                      </div>

                      <div className="card_ped_tl">
                        <a className="text_font_bold">Quantidade </a>
                      </div>
                    </div>
                  </div>

                  {Object.keys(item[1].pedologia) &&

                    Object.keys(item[1].pedologia)
                      .sort((a, b) => parseInt(a) - parseInt(b))
                      .map(key => {

                        return (

                          <div key={key} className="container_pedologia">

                            <div className="card_ped_info">
                              <a className="text_font">{item[1].pedologia[key].nivel}</a>
                            </div>

                            <div className="card_ped_info">
                              <a className="text_font">{item[1].pedologia[key].coloracao}</a>
                            </div>

                            <div className="card_ped_info">
                              <a className="text_font">{item[1].pedologia[key].textura}</a>
                            </div>

                            <div className="card_mat_info">

                              {item[1].evidencias ? (

                                Object.keys(item[1].evidencias)
                                  .filter(ponto => {
                                    return ponto === item[1].pedologia[key].nivel
                                  })
                                  .map((result) => {
                                    return (
                                      item[1].evidencias[result]
                                    )
                                  })
                                  .map((r, index) => {
                                    return (
                                      <div key={index}>
                                        <a className="text_font">{r[index].evidencia}</a>
                                      </div>
                                    )
                                  })

                              ) :
                                <div>
                                  <a className="text_font">Não encontrado</a>
                                </div>
                              }

                              {item[1].evidencias ? (
                                Object.keys(item[1].evidencias)
                                  .filter(ponto => {
                                    return ponto === item[1].pedologia[key].nivel
                                  })
                                  .map(x => x)
                                  .length > 0 ? (
                                  <div></div>
                                ) : (
                                  <div>
                                    <a className="text_font">Não encontrado</a>
                                  </div>
                                )
                              ) :
                                <div></div>
                              }

                            </div>




                            <div className="card_mat_info">

                              {item[1].evidencias ? (

                                Object.keys(item[1].evidencias)
                                  .filter(ponto => {
                                    return ponto === item[1].pedologia[key].nivel
                                  })
                                  .map((result) => {
                                    return (
                                      item[1].evidencias[result]
                                    )
                                  })
                                  .map((r, index) => {
                                    return (
                                      <div key={index}>
                                        <a className="text_font">{r[index].quantidade}</a>
                                      </div>
                                    )
                                  })

                              ) :
                                <div>
                                  <a className="text_font">-</a>
                                </div>
                              }

                              {item[1].evidencias ? (
                                Object.keys(item[1].evidencias)
                                  .filter(ponto => {
                                    return ponto === item[1].pedologia[key].nivel
                                  })
                                  .map(x => x)
                                  .length > 0 ? (
                                  <div></div>
                                ) : (
                                  <div>
                                    <a className="text_font">-</a>
                                  </div>
                                )
                              ) :
                                <div></div>
                              }

                            </div>

                          </div>

                        )
                      })
                  }

                </div>
              }
              <div>
                {item[1].encerramento &&
                  <div>

                    <div className="card_container_meio">
                      <a className="text_title_ficha">Encerramento</a>
                    </div>

                    <div className="card_fechamento">

                      <div className="container_projeto">
                        <div className="card_info_pd">
                          <a className="text_font_bold">Motivo</a>
                        </div>

                        <div className="card_info_result_pd">
                          <a className="text_font">{item[1].encerramento.encerramento}</a>
                        </div>
                      </div>

                      <div className="container_projeto">
                        <div className="card_info_pd">
                          <a className="text_font_bold">Observação</a>
                        </div>

                        <div className="card_info_result_pd">
                          <a className="text_font">{item[1].encerramento.observacoes}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>

              <div>

                {item[1].intransponibilidade &&
                  <div>
                    <div className="card_container_meio">
                      <a className="text_title_ficha">Intransponibilidade</a>
                    </div>

                    <div className="card_fechamento">

                      <div className="container_projeto">
                        <div className="card_info_pd">
                          <a className="text_font_bold">Motivo</a>
                        </div>

                        <div className="card_info_result_pd">
                          <a className="text_font">{item[1].intransponibilidade.motivo}</a>
                        </div>
                      </div>

                      <div className="container_projeto">
                        <div className="card_info_pd">
                          <a className="text_font_bold">Observação</a>
                        </div>

                        <div className="card_info_result_pd">
                          <a className="text_font">{item[1].intransponibilidade.observacoes}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                }

              </div>

              {hidePhoto &&

                <div>

                  <div className="card_container_meio">
                    <a className="text_title_ficha">Fotos</a>
                  </div>

                  <div className="card_image">
                    {item[1].fotos &&
                      item[1].fotos
                        .map((fotos) => (
                          <div key={fotos} className="card_image_details">
                            <img src={fotos.uri} className="image" />
                          </div>
                        ))
                    }
                  </div>

                </div>

              }

              <div className='page-divide'></div>

            </div>

          </div>

        ))}

        <div className='footer'>
          <a className="text_footer">AIPA Connect © | Ficha de Intervenção Arqueológica | {data.projeto}</a>
        </div>

      </div>

    </div >

  );
}

export default PDFContent;
