import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { db, storage, auth } from '../../../services/firebaseConnection';
import { ref as ref_database, set, child, get } from "firebase/database";
import { onAuthStateChanged } from "firebase/auth";
import { FadingBalls } from "react-cssfx-loading";


function UpdateInfo() {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const dbRef = ref_database(db);
        const dataUser = location.state
        const uidUser = dataUser.uid
        const projSel = dataUser.projeto
        const exec = dataUser.executor
        const munici = dataUser.municipio
        const proto = dataUser.protocolo

        /*get(child(dbRef, 'backup/' + projSel + '/refresh')).then((snapshot) => {
            const data = snapshot.val()

            if (data == true) {
                navigate('/home', { state: { projeto: projSel, uid: uidUser } });
            }
        })*/

        get(child(dbRef, 'backup/')).then((snapshot) => {
            const dadosProj = snapshot.val()
            if (dadosProj[projSel] == undefined) {

                var entorno = dataUser.dados.entorno
                var pedologia = dataUser.dados.pedologia
                var encerramento = dataUser.dados.encerramento
                var evidencias = dataUser.dados.evidencias
                var fotos = dataUser.fotos
                var arrDataFull = []

                entorno.map((r) => {

                    Object.keys(pedologia).forEach(function (key) {

                        var fotoFil = fotos[0].filter((rp) => {
                            return rp.pt == r.pt
                        })

                        var encrFil = encerramento.filter((re) => {
                            return re.pt == r.pt
                        })

                        if (evidencias) {
                            var eviFilPT = evidencias.filter((rv) => {
                                return rv.pt == r.pt
                            })
                        }

                        if (eviFilPT) {
                            var eviFilNV = eviFilPT.reduce(function (r, a) {
                                r[a.info.nivel] = r[a.info.nivel] || [];
                                r[a.info.nivel].push({ evidencia: a.info.evidencia, quantidade: a.info.quantidade, pt: a.info.pt, nivel: a.info.nivel });
                                return r;
                            }, Object.create(null));
                        }

                        if (eviFilNV) {
                            if (Object.values(eviFilNV)[0] == undefined) {
                                var evids = ''
                            } else {
                                var evids = eviFilNV
                            }
                        } else {
                            var evids = ""
                        }

                        if (pedologia[r.pt] == undefined) {
                            var ped = '';
                        } else {
                            var ped = pedologia[r.pt];
                        }

                        if (encrFil[0] == undefined) {
                            var enc = ''
                        } else {
                            var enc = encrFil[0].info
                        }

                        if (fotoFil[0] == undefined) {
                            var fotoP = ''
                        } else {
                            var fotoP = fotoFil
                        }

                        var data = { entorno: r.info, pedologia: ped, encerramento: enc, fotos: fotoP, evidencias: evids }
                        var ponto = { [r.pt]: data }

                        arrDataFull[r.pt] = data

                    });

                })

                const abertos = Object.keys(arrDataFull)

                const pedologiaCd = Object.entries(arrDataFull).map((x) => {
                    if (x[1].pedologia) {
                        return x[0]
                    }
                }).filter(Boolean)

                const evidenciaCd = Object.entries(arrDataFull).map((x) => {
                    if (x[1].evidencias) {
                        return x[0]
                    }
                }).filter(Boolean)

                const encerramentoCd = Object.entries(arrDataFull).map((x) => {
                    if (x[1].encerramento) {
                        return x[0]
                    }
                }).filter(Boolean)

                const summary = { abertos, pedologiaCd, evidenciaCd, encerramentoCd }

                navigate('/setinfo', { state: { dados: arrDataFull, projeto: projSel, summary: summary, executor: exec, municipio: munici, protocolo: proto } });

            } else if (dadosProj[projSel]) {

                //DADOS NOVOS

                var entorno = dataUser.dados.entorno
                var pedologia = dataUser.dados.pedologia
                var encerramento = dataUser.dados.encerramento
                var evidencias = dataUser.dados.evidencias
                var fotos = dataUser.fotos
                var arrDataFull = []

                entorno.map((r) => {

                    Object.keys(pedologia).forEach(function (key) {

                        var fotoFil = fotos[0].filter((rp) => {
                            return rp.pt == r.pt
                        })

                        var encrFil = encerramento.filter((re) => {
                            return re.pt == r.pt
                        })

                        if (evidencias) {
                            var eviFilPT = evidencias.filter((rv) => {
                                return rv.pt == r.pt
                            })

                            var eviFilNV = eviFilPT.reduce(function (r, a) {
                                r[a.info.nivel] = r[a.info.nivel] || [];
                                r[a.info.nivel].push({ evidencia: a.info.evidencia, quantidade: a.info.quantidade, pt: a.info.pt, nivel: a.info.nivel });
                                return r;
                            }, Object.create(null));

                            if (Object.values(eviFilNV)[0] == undefined) {
                                var evids = ''
                            } else {
                                var evids = eviFilNV
                            }
                        }

                        if (pedologia[r.pt] == undefined) {
                            var ped = '';
                        } else {
                            var ped = pedologia[r.pt];
                        }

                        if (encrFil[0] == undefined) {
                            var enc = ''
                        } else {
                            var enc = encrFil[0].info
                        }

                        if (fotoFil[0] == undefined) {
                            var fotoP = ''
                        } else {
                            var fotoP = fotoFil
                        }

                        var data = { entorno: r.info, pedologia: ped, encerramento: enc, fotos: fotoP }

                        if (evidencias) {
                            data.evidencias = evids
                        }

                        var ponto = { [r.pt]: data }

                        arrDataFull[r.pt] = data

                        //DADOS JÁ ENVIADOS
                        const dataAnex = dadosProj[projSel].dados
                        Object.entries(dataAnex).forEach(function (data) {
                            arrDataFull[data[0]] = data[1]
                        });

                    });

                })

                const abertos = Object.keys(arrDataFull)

                const pedologiaCd = Object.entries(arrDataFull).map((x) => {
                    if (x[1].pedologia) {
                        return x[0]
                    }
                }).filter(Boolean)

                const evidenciaCd = Object.entries(arrDataFull).map((x) => {
                    if (x[1].evidencias) {
                        return x[0]
                    }
                }).filter(Boolean)

                const encerramentoCd = Object.entries(arrDataFull).map((x) => {
                    if (x[1].encerramento) {
                        return x[0]
                    }
                }).filter(Boolean)

                const summary = { abertos, pedologiaCd, evidenciaCd, encerramentoCd }

                navigate('/setinfo', { state: { dados: arrDataFull, projeto: projSel, summary: summary, executor: exec, municipio: munici, protocolo: proto } });

            } else {
                navigate('/home', { state: { projeto: projSel, uid: uidUser } });
            }
        })

    }, [])

    return (

        <div style={{ overflow: 'hidden' }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh',
                    width: '100vw',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <a style={{ fontSize: 20, fontWeight: '800', marginBottom: '10px' }}>Lendo informações</a>
                <FadingBalls width="120px" height="30px" color="#30ae74" />
                <div />
            </div>
        </div>

    )
}

export default UpdateInfo;