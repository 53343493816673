import React from 'react';
import './styles.css'
import { SidebarData } from '../SidebarData';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";
import { auth } from "../../services/firebaseConnection"


function Sidebar({ children }) {

  const navigate = useNavigate();

  const singOut = async (e) => {

    signOut(auth).then(() => {
      navigate('/');
    }).catch((error) => {
      // An error happened.
    })

  }

  return (

    <div className='container'>
      <div className='Sidebar'>
        
        <img src={require('../../img/logo-aipa-sim.png')} className="img_aipa_header"></img>
        <ul className='sidebar_list'>
          {SidebarData.map((val, key) => {
            return (
              <li
                key={key}
                className="row"
                id={window.location.pathname == val.link ? "active" : ""}
                onClick={() => {
                  window.location.pathname = val.link;
                }}
              >
                <div id="icon">{val.icon}</div> <div id="title">{val.title}</div>
              </li>
            )
          })}
        </ul>

        <button className='btn_quit_ad' onClick={singOut}>Sair</button>

      </div>
      <main className='body_children'>{children}</main>
    </div>

  );
}

export default Sidebar;