import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db, auth } from "../../services/firebaseConnection";
import { ref, set, get, child, remove, update } from "firebase/database";
import { BsFillRecordCircleFill, BsFillBoxFill, BsFillCheckSquareFill } from "react-icons/bs";
import { MdNature } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import './styles.css'

function PontosLev(props) {

    const navigate = useNavigate();
    const location = useLocation();

    const projeto = props?.projeto.projeto;

    const [camposDf, setCamposDf] = useState('')
    const [campos, setCampos] = useState('')
    const [camposData, setCamposData] = useState('')
    const [selCampos, setSelCampos] = useState('')
    const [geralProj, setGeralProj] = useState(true)
    const [expProj, setExpProj] = useState(false)
    const [naoPontos, setNaoPontos] = useState('')

    const getProj = (data) => {
        setGeralProj(false)
        setExpProj(true)

        const pontos = camposData[data].dados
        const filterPrg = camposDf.filter((x) => {
            return x.projeto === data
        })
        const pontosPrg = filterPrg[0].pontos.length
        const ptProgramados = filterPrg[0].pontos

        const arrPed = []
        const arrEvi = []
        const arrEnc = []
        const arrEnt = []

        const arrEntList = []
        const arrPedoList = []
        const arrEvidList = []
        const arrEnceList = []

        Object.values(pontos).map((x) => {
            const pedologia = x.pedologia.length
            const encerramento = x.encerramento.length
            const evidencias = x.evidencias.length
            const entorno = x.entorno.length
            if (pedologia == 0) {
                arrPed.push(1)
            }
            if (encerramento == 0) {
                arrEnc.push(1)
            }
            if (evidencias == undefined) {
                arrEvi.push(1)
            }
            if (entorno == undefined) {
                arrEnt.push(1)
            }

            const entoInfo = x.entorno.pt
            const pedoInfo = Object.values(x.pedologia)
            const evidInfo = Object.values(x.evidencias)
            const enceInfo = x.encerramento.pt

            arrEntList.push(entoInfo)

            if (pedoInfo[0] == undefined) {
            } else {
                const pedInfoPass = pedoInfo[0].pt
                arrPedoList.push(pedInfoPass)
            }

            if (evidInfo[0] == undefined) {
            } else {
                const evidInfoPass = evidInfo[0][0].pt
                arrEvidList.push(evidInfoPass)
            }

            if (enceInfo == undefined) {
            } else {
                arrEnceList.push(enceInfo)
            }
        })

        const exceEnto = ptProgramados.filter((element) => !arrEntList.includes(element))
        const excePedo = ptProgramados.filter((element) => !arrPedoList.includes(element))
        const exceEnce = ptProgramados.filter((element) => !arrEnceList.includes(element))

        const listPontos = { programados: ptProgramados, naoEnto: exceEnto, naoPedo: excePedo, naoEnce: exceEnce }
        const dataR = { pedologia: arrPed.length, encerramento: arrEnc.length, evidencias: arrEvi.length, entorno: arrEnt.length, programados: pontosPrg, lista: listPontos }
        setSelCampos(dataR)

    }

    const hideProgra = (data) => {
        setNaoPontos(data)
    }

    useEffect(() => {
        const dbRef = ref(db);
        if (projeto) {
            get(child(dbRef, 'projetos/' + projeto + "/pontos/")).then((snapshot) => {
                if (snapshot.exists()) {
                    const pontosPro = snapshot.val()
                    get(child(dbRef, 'backup/' + projeto + "/summary/")).then((snapshot) => {
                        if (snapshot.exists()) {
                            const summary = snapshot.val()

                            const exceEnto = pontosPro.filter((r) => !summary.abertos.includes(r))
                            const excePedo = pontosPro.filter((r) => !summary.pedologiaCd.includes(r))
                            const exceEnce = pontosPro.filter((r) => !summary.encerramentoCd.includes(r))

                            const newSummary = { ...summary, programados: pontosPro, naoEnto: exceEnto, naoPedo: excePedo, naoEnce: exceEnce }

                            setSelCampos(newSummary)
                        }
                    })
                }
            })
        }
    }, [projeto])

    return (
        <div className="body">
            {selCampos &&
                <div className='card_rows_int' style={{ width: "100%" }}>

                    <div className='card_interv' style={{ width: "100%" }}>

                        <button
                            onClick={() => hideProgra({ dados: selCampos.programados, title: "Pontos progamados" })}
                            className='card_proga'
                            style={{ width: "100%" }}
                        >

                            <div style={{ width: '65px', borderRadius: '80px', justifyContent: 'center', display: 'flex' }}>
                                <BsFillRecordCircleFill style={{ backgroundColor: '#a8d8ff', padding: '8px', borderRadius: '20px', fontSize: '25px', color: '#3a86ff', textAlign: 'center' }} />
                            </div>

                            <div className='card_collum_da'>
                                <a style={{ color: '#3a86ff', fontSize: '25px', fontWeight: '600' }}>{selCampos.programados.length}</a>
                                <a style={{ fontSize: '15px' }}>Programados</a>
                            </div>
                        </button>


                        <button
                            onClick={() => hideProgra({ dados: selCampos.naoEnto, title: "Não abertos" })}
                            className='card_ento'>
                            <div style={{ width: '65px', borderRadius: '80px', justifyContent: 'center', display: 'flex' }}>
                                <MdNature style={{ backgroundColor: '#f5d4bf', padding: '8px', borderRadius: '20px', fontSize: '25px', color: '#e3533b', textAlign: 'center' }} />
                            </div>
                            <div className='card_collum_da'>
                                <a style={{ color: '#e3533b', fontSize: '25px', fontWeight: '600' }}>{selCampos.abertos.length}</a>
                                <a style={{ fontSize: '15px' }}>Entorno</a>
                            </div>
                        </button>

                        <button
                            onClick={() => hideProgra({ dados: selCampos.naoPedo, title: "Sem pedologia" })}
                            className='card_pedo'>
                            <div style={{ width: '65px', borderRadius: '80px', justifyContent: 'center', display: 'flex' }}>
                                <BsFillBoxFill style={{ backgroundColor: '#d4cde7', padding: '8px', borderRadius: '20px', fontSize: '25px', color: '#5e539e', textAlign: 'center' }} />
                            </div>
                            <div className='card_collum_da'>
                                <a style={{ color: '#5e539e', fontSize: '25px', fontWeight: '600' }}>{selCampos.pedologiaCd.length}</a>
                                <a style={{ fontSize: '15px' }}>Pedologia</a>
                            </div>
                        </button>

                        <button
                            onClick={() => hideProgra({ dados: selCampos.naoEnce, title: "Não encerrados" })}
                            className='card_ence'>
                            <div style={{ width: '65px', borderRadius: '80px', justifyContent: 'center', display: 'flex' }}>
                                <BsFillCheckSquareFill style={{ backgroundColor: '#baddc5', padding: '8px', borderRadius: '20px', fontSize: '25px', color: '#3b7d50', textAlign: 'center' }} />
                            </div>
                            <div className='card_collum_da'>
                                <a style={{ color: '#3a5a40', fontSize: '25px', fontWeight: '600' }}>{selCampos.encerramentoCd.length}</a>
                                <a style={{ fontSize: '15px' }}>Encerrados</a>
                            </div>
                        </button>
                    </div>


                    {naoPontos &&
                        <div style={{ width: '400px', marginLeft: 10 }}>

                            <div className='card_title_select'>
                                <a style={{ color: '#fff', fontSize: 14 }}>{naoPontos.title}</a>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', height: "300px", overflowY: "auto" }}>
                                {naoPontos && (naoPontos.dados).map((r) => {
                                    return (
                                        <a className='card_ponto_list'>{r}</a>
                                    )
                                })}
                            </div>
                        </div>
                    }



                </div>
            }
        </div>
    );
}

export default PontosLev;
