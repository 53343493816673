import React from 'react';


function PoliticaPrivacidade() {

    return (
        <div style={{height: "400px", overflowY: "auto", margin:'50px'}}>
            <h1>Política de Privacidade</h1>
            <a>A Hcoutinho Pesquisa e Desenvolvimento desenvolveu o aplicativo AIPA Connect como um aplicativo gratuito. Este SERVIÇO é fornecido pela Hcoutinho Pesquisa e Desenvolvimento sem nenhum custo e destina-se ao uso como está.
                Esta página é usada para informar os visitantes sobre nossas políticas de coleta, uso e divulgação de informações pessoais, caso alguém decida usar nosso serviço.
                Se você optar por usar nosso Serviço, concorda com a coleta e o uso de informações relacionadas a esta política. As informações pessoais que coletamos são usadas para fornecer e melhorar o serviço. Não usaremos ou compartilharemos suas informações com ninguém, exceto conforme descrito nesta Política de Privacidade.
                Os termos usados nesta Política de Privacidade têm os mesmos significados que em nossos Termos e Condições, que estão acessíveis no AIPA Connect, a menos que definido de outra forma nesta Política de Privacidade.
            </a>
            <h1>Coleta e uso de informações</h1>
            <a>Para uma melhor experiência, ao usar nosso Serviço, podemos solicitar que você nos forneça algumas informações de identificação pessoal, incluindo, entre outras, Wender Bacelar. As informações que solicitamos serão retidas por nós e usadas conforme descrito nesta política de privacidade.
                O aplicativo usa serviços de terceiros que podem coletar informações usadas para identificá-lo.
                Link para a política de privacidade de provedores de serviços terceirizados usados pelo app
                Serviços do Google Play
                Google Analytics para Firebase
                exposição
                Dados de registro
                Queremos informar que sempre que você usa nosso Serviço, em caso de erro no aplicativo, coletamos dados e informações (através de produtos de terceiros) em seu telefone chamado Log Data. Esses dados de registro podem incluir informações como endereço de protocolo de Internet ("IP") do dispositivo, nome do dispositivo, versão do sistema operacional, configuração do aplicativo ao utilizar nosso serviço, hora e data de uso do serviço e outras estatísticas .
            </a>
            <h1>Biscoitos</h1>
            <a>Cookies são arquivos com uma pequena quantidade de dados que são comumente usados como identificadores únicos anônimos. Estes são enviados para o seu navegador a partir dos sites que você visita e são armazenados na memória interna do seu dispositivo.
                Este Serviço não usa esses “cookies” explicitamente. No entanto, o aplicativo pode usar código e bibliotecas de terceiros que usam “cookies” para coletar informações e melhorar seus serviços. Você tem a opção de aceitar ou recusar esses cookies e saber quando um cookie está sendo enviado ao seu dispositivo. Se você optar por recusar nossos cookies, talvez não consiga usar algumas partes deste Serviço.
            </a>
            <h1>Provedores de serviço</h1>
            <a>Podemos empregar empresas e indivíduos terceirizados devido aos seguintes motivos:
                Para facilitar nosso Serviço;
                Para fornecer o Serviço em nosso nome;
                Para realizar serviços relacionados ao Serviço; ou
                Para nos ajudar a analisar como nosso Serviço é usado.
                Queremos informar aos usuários deste Serviço que esses terceiros têm acesso às suas Informações Pessoais. O motivo é realizar as tarefas atribuídas a eles em nosso nome. No entanto, eles são obrigados a não divulgar ou usar as informações para qualquer outra finalidade.
            </a>
            <h1>Segurança</h1>
            <a>Valorizamos sua confiança em nos fornecer suas informações pessoais, portanto, estamos nos esforçando para usar meios comercialmente aceitáveis de protegê-las. Mas lembre-se que nenhum método de transmissão pela internet, ou método de armazenamento eletrônico é 100% seguro e confiável, e não podemos garantir sua segurança absoluta.</a>
            <h1>Links para outros sites</h1>
            <a>Este Serviço pode conter links para outros sites. Se você clicar em um link de terceiros, será direcionado para esse site. Observe que esses sites externos não são operados por nós. Portanto, recomendamos fortemente que você revise a Política de Privacidade desses sites. Não temos controle e não assumimos nenhuma responsabilidade pelo conteúdo, políticas de privacidade ou práticas de sites ou serviços de terceiros.</a>
            <h1>Privacidade das crianças</h1>
            <a>Esses Serviços não se destinam a menores de 13 anos. Não coletamos intencionalmente informações de identificação pessoal de crianças menores de 13 anos. No caso de descobrirmos que uma criança menor de 13 anos nos forneceu informações pessoais, nós as excluímos imediatamente de nossos servidores. Se você é pai ou responsável e está ciente de que seu filho nos forneceu informações pessoais, entre em contato conosco para que possamos tomar as medidas necessárias.</a>
            <h1>Mudanças nesta Política de Privacidade</h1>
            <a>Podemos atualizar nossa Política de Privacidade de tempos em tempos. Assim, você é aconselhado a revisar esta página periodicamente para quaisquer alterações. Iremos notificá-lo sobre quaisquer alterações publicando a nova Política de Privacidade nesta página.
                Esta política é efetiva a partir de 2023-03-24
            </a>
            <h1>Contate-nos</h1>
            <a>Se você tiver alguma dúvida ou sugestão sobre nossa Política de Privacidade, não hesite em nos contatar em sac.aipa@hcoutinho.com.br.</a>

        </div>
    )

}
export default PoliticaPrivacidade;