import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db, storage, auth } from "../../services/firebaseConnection"
import { ref as ref_database, set, child, get } from "firebase/database";
import './styles.css';
import EditData from './EditData';
import AddData from './AddData';
import { BiArrowBack, BiEditAlt, BiTargetLock, BiPaperclip, BiX } from "react-icons/bi";
import CloseIcon from '@mui/icons-material/Close';

function Edicao() {

    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState([])
    const [initial, setInitial] = useState(true)
    const [edit, setEdit] = useState(false)
    const [add, setAdd] = useState(false)

    useEffect(() => {
        //const dbRef = ref_database(db);
        const projeto = location.state.projeto
        setData(projeto)

        /*get(child(dbRef, 'backup/' + projeto)).then((snapshot) => {
            if (snapshot.exists()) {
                const dados = snapshot.val()
                setData(dados)
            }
        })*/
    }, [])

    return (
        <div>
            {initial &&
                <div className='btns_edits'>
                    <div className='card_text'>
                        <a className='text_one'>{data}</a>
                    </div>
                    <div className="car_cadastro" style={{ marginBottom: 10 }}>
                        <button onClick={() => { setInitial(false) || setEdit(true) }} className="btn_cd_n">Editar</button>
                    </div>
                    <div className="car_cadastro">
                        <button onClick={() => { setInitial(false) || setAdd(true) }} className="btn_cd_n">Adicionar</button>
                    </div>
                </div>
            }

            {edit &&
                <div style={{ position: 'absolute', right: 15 }}>
                    <button onClick={() => setEdit(false) || setInitial(true)} className="btn_quit_proj"><CloseIcon /></button>
                </div>
            }

            {edit &&
                <div style={{ margin: 15, maxWidth: 800 }}>
                    {data &&
                        <EditData projeto={data} />
                    }
                </div>
            }

            {add &&
                <div style={{ position: 'absolute', right: 15 }}>
                    <button onClick={() => setAdd(false) || setInitial(true)} className="btn_quit_proj"><CloseIcon /></button>
                </div>
            }

            {add &&
                <div style={{ margin: 15, maxWidth: 800 }}>
                    {data &&
                        <div style={{ height: "100vh", overflowY: 'auto' }}>
                            <AddData projeto={data} />
                        </div>
                    }
                </div>
            }


        </div>
    )
}

export default Edicao;