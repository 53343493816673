import React, { useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';

import { db, storage } from '../../services/firebaseConnection';
import { ref as ref_database, child, get } from "firebase/database";

import { HiDocumentCheck } from "react-icons/hi2";

import './styles.css';


function Verificador() {

    const [token, setToken] = useState(null)
    const [dados, setDados] = useState(null)

    const noSet = () => toast.error('Digite o token informado no documento!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const noToken = () => toast.error('Não existe em nosso sitema, assinatura com o token informado!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    function verificarToken() {

        if (token === null) {
            noSet()
            setDados(null)
        } else {

            const dbRef = ref_database(db);

            get(child(dbRef, 'assinatura/' + token)).then((snapshot) => {
                if (snapshot.exists()) {

                    const dados = snapshot.val()

                    const now = new Date(dados.data);
                    const diaS = now.getDate().toString().padStart(2, '0');
                    const mesS = (now.getMonth() + 1).toString().padStart(2, '0');
                    const anoS = now.getFullYear().toString();
                    const hora = now.getHours().toString().padStart(2, '0');
                    const minuto = now.getMinutes().toString().padStart(2, '0');

                    const dataFormatada = `${diaS}/${mesS}/${anoS} ${hora}:${minuto}`;

                    dados.data = dataFormatada
                    dados.ano = anoS

                    setDados(dados)

                } else {
                    noToken()
                    setDados(null)
                }
            }).catch((error) => {
                console.error(error);
            });

        }

    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>

            <div style={{ position: 'absolute', top: 15, left: 15 }} className='btn_home_veri' onClick={() => window.location.pathname = "/"}>
                <a style={{ color: '#fff' }}>Início</a>
            </div>

            <img src={require('../../img/logo-aipa-sim-b.png')} style={{ width: 250, marginBottom: 20 }} />

            <ToastContainer />
            <div style={{ display: 'flex', flexDirection: 'column', padding: 30, backgroundColor: "#b5e48c", borderRadius: 10, width: 600 }}>
                <a style={{ textAlign: 'center', fontWeight: 600, fontSize: 18, marginBottom: 15 }}>Verificação de Autenticidade de Assinatura Digital</a>
                <a style={{ marginBottom: 5 }}>Insira o token de verificação para validar o documento</a>
                <input
                    type="text"
                    className='input'
                    placeholder="Token"
                    onChange={(e) => setToken(e.target.value)}
                />
                <a style={{ marginBottom: 15, fontSize: 12 }}>*Campo obrigatório</a>
                <div className="card_input_user">
                    <button onClick={verificarToken} className="btn_cd" >Verificar</button>
                </div>
            </div>

            {dados &&
                dados.tipo === 'pt' &&
                <div style={{ display: 'flex', padding: 30, backgroundColor: "#014f86", borderRadius: 10, width: 600, marginTop: 15 }}>
                    <HiDocumentCheck style={{ display: 'flex', fontSize: 65, marginRight: 30, color: '#fff', justifyContent: 'center' }} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <a style={{ color: '#fff', fontWeight: 600, fontSize: 18, marginBottom: 15, fontFamily: 'Segoe UI' }}>Documento valido</a>
                        <a style={{ color: '#fff' }}><span style={{ fontWeight: 600, color: '#fff', fontFamily: 'Segoe UI' }}>Ficha de Intervenção Arqueológica - Poço Teste </span></a>
                        <a style={{ color: '#fff' }}><span style={{ fontWeight: 500, color: '#fff', fontFamily: 'Segoe UI' }}>Projeto: </span>{dados.projeto}</a>
                        <a style={{ color: '#fff' }}><span style={{ fontWeight: 500, color: '#fff', fontFamily: 'Segoe UI' }}>Token de assinatura: </span>{dados.token}</a>
                        <a style={{ color: '#fff' }}><span style={{ fontWeight: 500, color: '#fff', fontFamily: 'Segoe UI' }}>Assinado eletronicamente por: </span>{dados.autor}</a>
                        <a style={{ color: '#fff' }}><span style={{ fontWeight: 500, color: '#fff', fontFamily: 'Segoe UI' }}>Data da assinatura: </span>{dados.data}</a>
                    </div>
                </div>
            }
            {dados &&
                dados.tipo === 'of' &&
                <div style={{ display: 'flex', padding: 30, backgroundColor: "#014f86", borderRadius: 10, width: 600, marginTop: 15 }}>
                    <HiDocumentCheck style={{ display: 'flex', fontSize: 65, marginRight: 30, color: '#fff', justifyContent: 'center' }} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <a style={{ color: '#fff', fontWeight: 600, fontSize: 18, marginBottom: 15, fontFamily: 'Segoe UI' }}>Documento valido</a>
                        <a style={{ color: '#fff' }}><span style={{ fontWeight: 600, color: '#fff', fontFamily: 'Segoe UI' }}>Ofício HC {dados.number}/{dados.ano}</span></a>
                        {dados.interessado &&
                            dados.interessado.map((r, index) => (
                                <div key={index}>
                                    <a style={{ color: '#fff' }}><span style={{ fontWeight: 600, color: '#fff', fontFamily: 'Segoe UI' }}>{r.interessado} </span></a>
                                </div>
                            ))}
                        <a style={{ color: '#fff' }}><span style={{ fontWeight: 500, color: '#fff', fontFamily: 'Segoe UI' }}>Projeto: </span>{dados.projeto}</a>
                        <a style={{ color: '#fff' }}><span style={{ fontWeight: 500, color: '#fff', fontFamily: 'Segoe UI' }}>Token de assinatura: </span>{dados.token}</a>
                        <a style={{ color: '#fff' }}><span style={{ fontWeight: 500, color: '#fff', fontFamily: 'Segoe UI' }}>Assinado eletronicamente por: </span>{dados.autor}</a>
                        <a style={{ color: '#fff' }}><span style={{ fontWeight: 500, color: '#fff', fontFamily: 'Segoe UI' }}>Data da assinatura: </span>{dados.data}</a>
                    </div>
                </div>
            }
        </div>
    );
}

export default Verificador;