import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../../services/firebaseConnection'
import { ref as ref_database, set, child, get, update, push } from "firebase/database";
import PontosLev from '../PontosLev';
import { MdSettingsBackupRestore } from "react-icons/md";
import { BiEdit, BiArrowFromTop } from "react-icons/bi";
import { TbChartPieFilled } from "react-icons/tb";
import { IoAlertOutline } from "react-icons/io5";
import { TbRefreshAlert } from "react-icons/tb";
import { ToastContainer, toast } from 'react-toastify';
import Modal from '@mui/material/Modal';
import './styles.css';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';

import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

function Home() {

    const navigate = useNavigate();
    const location = useLocation();

    const [projetos, setProjetos] = useState([])
    const [abrirPano, setAbrirPano] = useState(false)
    const [projSelect, setProjSelect] = useState([])
    const [projSync, setProjSync] = useState([])
    const [atualizar, setAtualizar] = useState(false);
    const [dataSelect, setDataSelect] = useState();
    const [usersArr, setUsersArr] = useState([]);

    const [open, setOpen] = useState(false);
    const [openInfoUser, setOpenInfoUser] = useState();
    const [openListPV, setOpenListPV] = useState(false);

    const dbRef = ref_database(db);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const projetosSnapshot = await get(child(dbRef, 'projetos/list'));
                const timestampsSnapshot = await get(child(dbRef, 'timestampsync'));
                const projsSyncSanap = await get(child(dbRef, 'backup/list'));
                const usersCDSanap = await get(child(dbRef, 'users'));

                let projetosDados
                let timestampsDados
                let projsSync
                let usersCD

                if (projetosSnapshot.exists()) {
                    projetosDados = projetosSnapshot.val();
                }

                if (timestampsSnapshot.exists()) {
                    timestampsDados = timestampsSnapshot.val();
                    //setTimestamps(timestampsDados);
                }

                if (projsSyncSanap.exists()) {
                    projsSync = projsSyncSanap.val();
                    //setTimestamps(timestampsDados);
                }

                if (usersCDSanap.exists()) {
                    usersCD = usersCDSanap.val();
                }

                const arrUsers = Object.values(usersCD).map((x) => {
                    const data = { username: x.username, uid: x.uid }
                    return data
                })

                setUsersArr(arrUsers)

                setProjSync(projsSync)

                // Converter os timestamps em um array de objetos
                const timestampsArray = Object.entries(timestampsDados).map(([projeto, timestamp]) => ({
                    projeto,
                    timestamp,
                }));

                // Criar um objeto temporário com chaves únicas sendo os valores de "projeto"
                const mergedObj = [...timestampsArray, ...projetosDados].reduce((acc, item) => {
                    const { projeto, ...rest } = item;
                    if (!acc[projeto]) {
                        acc[projeto] = { projeto, ...rest };
                    } else if (rest.user) {
                        acc[projeto].user = rest.user;
                    }
                    return acc;
                }, {});

                // Converter o objeto de volta para um array usando Object.values()
                const mergedArray = Object.values(mergedObj);

                // Ordenar o array de timestamps em ordem decrescente com base nos timestamps
                const resFinal = mergedArray.sort((a, b) => b.timestamp - a.timestamp);

                setProjetos(resFinal);

            } catch (error) {

                console.error(error);
            }
        };

        fetchData();
    }, [atualizar]);

    const sucessBackup = () => toast.success('Backup realizado com sucesso!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    async function fazerBackup(data) {
        const confirmacao = window.prompt('Para confirmar o backup, digite "backup":');

        /*if (confirmacao === 'backup') {

            var dadosUsu = [];
            const users = data.user.map((r) => {
                return get(child(dbRef, 'usuarios/' + r.uid)).then((snapshot) => {
                    if (snapshot.exists()) {
                        const dados = snapshot.val();
                        dadosUsu.push({ data: dados, uid: r.uid });
                    }
                });
            });
            await Promise.all(users);

            var dataUsersFull = []
            dadosUsu.map((result) => {

                const dataUser = result.data
                const uidUser = result.uid
                const projSel = dataUser.projeto
                const exec = dataUser.executor
                const munici = dataUser.municipio
                const proto = dataUser.protocolo

                var arrDataFull = []
                var arrDataFullPV = []

                if (dataUser.dados) {

                    var entorno = dataUser.dados.entorno
                    var pedologia = dataUser.dados.pedologia
                    var encerramento = dataUser.dados.encerramento
                    var evidencias = dataUser.dados.evidencias
                    var instransponibilidade = dataUser.dados.instransponibilidade
                    var fotos = dataUser.fotos
                    var pontovistoria = dataUser.dados.pontovistoria

                    if (dataUser.dados.entorno) {

                        entorno.map((r) => {
                            var data = { entorno: r.info }
                            arrDataFull[r.pt] = data
                        })

                        entorno.map((r) => {

                            Object.keys(pedologia).forEach(function (key) {

                                var fotoFil = fotos[0].filter((rp) => {
                                    return rp.pt == r.pt
                                })

                                if (encerramento) {
                                    var encrFil = encerramento.filter((re) => {
                                        return re.pt == r.pt
                                    })
                                } else {
                                    var encrFil = ""
                                }

                                if (instransponibilidade) {
                                    var intraFil = instransponibilidade.filter((ri) => {
                                        return ri.pt == r.pt
                                    })
                                } else {
                                    var intraFil = ""
                                }

                                if (evidencias) {
                                    var eviFilPT = evidencias.filter((rv) => {
                                        return rv.pt == r.pt
                                    })

                                    const group = eviFilPT.reduce(function (r, a) {
                                        r[a.info.nivel] = r[a.info.nivel] || {};
                                        r[a.info.nivel][a.info.evidencia] = r[a.info.nivel][a.info.evidencia] || [];
                                        r[a.info.nivel][a.info.evidencia] = a.info.quantidade
                                        return r;
                                    }, {});

                                    if (Object.values(group)[0] == undefined) {
                                        var evids = ''
                                    } else {
                                        var evids = group
                                    }
                                } else {
                                    var evids = ''
                                }

                                if (pedologia[r.pt] == undefined) {
                                    var ped = '';
                                } else {
                                    var ped = pedologia[r.pt];
                                }

                                if (encrFil[0] == undefined) {
                                    var enc = ''
                                } else {
                                    var enc = encrFil[0].info
                                }

                                if (intraFil[0] == undefined) {
                                    var intra = ''
                                } else {
                                    var intra = intraFil[0].info
                                }

                                if (fotoFil[0] == undefined) {
                                    var fotoP = ''
                                } else {
                                    var fotoP = fotoFil
                                }

                                var data = { entorno: r.info, pedologia: ped, encerramento: enc, fotos: fotoP, evidencias: evids, instransponibilidade: intra }

                                var ponto = { [r.pt]: data }

                                arrDataFull[r.pt] = data

                            });

                        })

                    }

                    if (dataUser.dados.pontovistoria) {
                        pontovistoria.map((r) => {
                            var data = r.info

                            var fotoFil = fotos[0].filter((rp) => {
                                return rp.pt == r.pv
                            })

                            if (fotoFil[0] == undefined) {
                                var fotoP = ''
                            } else {
                                var fotoP = fotoFil
                            }

                            data.fotos = fotoP

                            arrDataFullPV[r.pv] = data
                        })
                    }

                }

                var resultData = { projeto: projSel, executor: exec, municipio: munici, protocolo: proto };

                if (Object.keys(arrDataFull)[0]) {
                    resultData.dados = arrDataFull
                }

                if (Object.keys(arrDataFullPV)[0]) {
                    resultData.dadosPV = arrDataFullPV
                }

                dataUsersFull.push(resultData)
            });

            const mergedUsers = [];
            dataUsersFull.forEach((r) => {
                if (r.dados) {
                    Object.keys(r.dados).forEach((key) => {
                        mergedUsers[key] = r.dados[key];
                    });
                }
            });

            const mergedUsersPV = [];
            dataUsersFull.forEach((r) => {
                if (r.dadosPV) {
                    Object.keys(r.dadosPV).forEach((key) => {
                        mergedUsersPV[key] = r.dadosPV[key];
                    });
                }
            });

            const vistoriados = Object.keys(mergedUsersPV)
            const abertos = Object.keys(mergedUsers)

            const pedologiaCd = Object.entries(mergedUsers).map((x) => {
                if (x[1].pedologia) {
                    return x[0]
                }
            }).filter(Boolean)

            const evidenciaCd = Object.entries(mergedUsers).map((x) => {
                if (x[1].evidencias) {
                    return x[0]
                }
            }).filter(Boolean)

            const encerramentoCd = Object.entries(mergedUsers).map((x) => {
                if (x[1].encerramento) {
                    return x[0]
                }
            }).filter(Boolean)

            const intranspCd = Object.entries(mergedUsers).map((x) => {
                if (x[1].intransponivel) {
                    return x[0]
                }
            }).filter(Boolean)

            console.log(mergedUsers)

            const summary = { abertos, pedologiaCd, evidenciaCd, encerramentoCd, vistoriados, intranspCd }

            set(ref_database(db, 'backup/' + dataUsersFull[0].projeto), {
                dados: mergedUsers,
                dadosPV: mergedUsersPV,
                projeto: dataUsersFull[0].projeto,
                summary: summary,
                protocolo: dataUsersFull[0].protocolo,
                municipio: dataUsersFull[0].municipio,
                //executor: dataUsersFull[0].executor,
                refresh: true
            }).then(async () => {

                const currentDate = new Date();
                const timestampSync = { projeto: dataUsersFull[0].projeto, timestamp: currentDate.getTime() }


                if (projSync.some(r => r.projeto === dataUsersFull[0].projeto)) {

                    const filterProj = projSync.filter(x => x.projeto !== dataUsersFull[0].projeto)
                    const newProjSyncAdd = [...filterProj, timestampSync]
                    const updades = {}
                    updades['backup/list'] = newProjSyncAdd
                    await update(ref_database(db), updades);
                    setProjSync(newProjSyncAdd)

                } else {

                    const newProjSync = [...projSync, timestampSync]
                    const updades = {}
                    updades['backup/list'] = newProjSync
                    await update(ref_database(db), updades);
                    setProjSync(newProjSync)

                }

                var infoGet = []

                get(child(dbRef, 'timestampsync/')).then((snapshot) => {
                    if (snapshot.exists()) {
                        const dados = snapshot.val();
                        dados[dataUsersFull[0].projeto] = new Date().getTime()
                        infoGet.push(dados)
                    }
                });

                const fetchData = async () => {
                    try {
                        const timestampBk = await get(child(dbRef, 'timestampsync/'));
                        let dataTimestampBk
                        if (timestampBk.exists()) {
                            dataTimestampBk = timestampBk.val();
                        }

                        dataTimestampBk[dataUsersFull[0].projeto] = new Date().getTime()

                        const updades = {}
                        updades['timestampsync'] = dataTimestampBk
                        await update(ref_database(db), updades);

                    } catch (error) {
                        console.error(error);
                    }
                };

                fetchData();

                sucessBackup();
            })

        } else {
            //faz nada
        }*/
    }

    function mergeArraysByUid(array1, array2) {
        // Crie um objeto temporário para armazenar os objetos por uid
        const tempObject = {};

        // Percorra o primeiro array e adicione os objetos ao objeto temporário
        for (const obj of array1) {
            tempObject[obj.uid] = obj;
        }

        // Percorra o segundo array e adicione ou atualize os objetos no objeto temporário
        for (const obj of array2) {
            if (tempObject[obj.uid]) {
                // Se o uid já existir no objeto temporário, combine as propriedades
                Object.assign(tempObject[obj.uid], obj);
            } else {
                // Caso contrário, adicione o objeto ao objeto temporário
                tempObject[obj.uid] = obj;
            }
        }

        // Converta o objeto temporário de volta em um array
        const mergedArray = Object.values(tempObject);

        return mergedArray;
    }

    async function openBackup(data) {

        var sincUsers = []

        const mapUsers = data.user.map(x => {
            return get(child(dbRef, 'usuarios/' + x.uid + "/dados/atualizacao")).then((snapshot) => {
                if (snapshot.exists()) {
                    const dados = snapshot.val()
                    const infoUser = { name: x.name, uid: x.uid, atualizacao: dados }
                    sincUsers.push(infoUser)
                } else {
                    const infoUser = { name: x.name, uid: x.uid, atualizacao: "" }
                    sincUsers.push(infoUser)
                }
            }).catch((error) => {
                console.error(error);
            });
        })
        await Promise.all(mapUsers);

        var ptUsersAB = []
        const mapPTAb = data.user.map(x => {
            return get(child(dbRef, 'usuarios/' + x.uid + "/dados/entorno")).then((snapshot) => {
                if (snapshot.exists()) {
                    const dados = snapshot.val()
                    var ptUsersInfo = []
                    dados.map(x => ptUsersInfo.push(x.pt))
                    const infoUser = { uid: x.uid, ptAB: ptUsersInfo }
                    ptUsersAB.push(infoUser)
                }
            }).catch((error) => {
                console.error(error);
            });
        })
        await Promise.all(mapPTAb);

        var ptUsersPE = []
        const mapPTPe = data.user.map(x => {
            return get(child(dbRef, 'usuarios/' + x.uid + "/dados/pedologia")).then((snapshot) => {
                if (snapshot.exists()) {
                    const dados = snapshot.val()
                    var ptUsersInfo = []
                    dados.map(x => ptUsersInfo.push(x.pt))
                    const infoUser = { uid: x.uid, ptPE: ptUsersInfo }
                    ptUsersPE.push(infoUser)
                }
            }).catch((error) => {
                console.error(error);
            });
        })
        await Promise.all(mapPTPe);

        var ptUsersEN = []
        const mapPTEn = data.user.map(x => {
            return get(child(dbRef, 'usuarios/' + x.uid + "/dados/encerramento")).then((snapshot) => {
                if (snapshot.exists()) {
                    const dados = snapshot.val()
                    var ptUsersInfo = []
                    dados.map(x => ptUsersInfo.push(x.pt))
                    const infoUser = { uid: x.uid, ptEN: ptUsersInfo }
                    ptUsersEN.push(infoUser)
                }
            }).catch((error) => {
                console.error(error);
            });
        })
        await Promise.all(mapPTEn);

        var pvUsers = []
        const mapPV = data.user.map(x => {
            return get(child(dbRef, 'usuarios/' + x.uid + "/dados/pontovistoria")).then((snapshot) => {
                if (snapshot.exists()) {
                    const dados = snapshot.val()
                    var pvUsersInfo = []
                    dados.map(x => pvUsersInfo.push(x.pv))
                    const infoUser = { uid: x.uid, pv: pvUsersInfo }
                    pvUsers.push(infoUser)
                }
            }).catch((error) => {
                console.error(error);
            });
        })
        await Promise.all(mapPV);

        const mergedOne = mergeArraysByUid(sincUsers, ptUsersAB);
        const mergedTwo = mergeArraysByUid(mergedOne, ptUsersPE);
        const mergedThree = mergeArraysByUid(mergedTwo, ptUsersEN);
        const mergedFour = mergeArraysByUid(mergedThree, pvUsers);

        data.user = mergedThree

        setDataSelect(data)
        setOpen(true)

    }

    const limite = 35;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 650,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const DemoPaper = styled(Paper)(({ theme }) => ({
        width: "100%",
        borderRadius: 10,
        padding: theme.spacing(1.5),
        ...theme.typography.body2,
        textAlign: 'center',
    }));

    const ColorButton = styled(Button)(({ theme }) => ({
        color: "#fff",
        borderRadius: 10,
        borderColor: "#ffd000",
        backgroundColor: "#ffdd00",
        '&:hover': {
            backgroundColor: "#ffd000",
            borderColor: "#ffd000",
        },
    }));

    const ColorButton2 = styled(Button)(({ theme }) => ({
        color: "#fff",
        borderRadius: 10,
        borderColor: "#0096c7",
        backgroundColor: "#00b4d8",
        '&:hover': {
            backgroundColor: "#0096c7",
            borderColor: "#0096c7",
        },
        fontWeight: 800
    }));

    const theme = useTheme();

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const names = [
        'Oliver Hansen',
        'Van Henry',
        'April Tucker',
        'Ralph Hubbard',
        'Omar Alexander',
        'Carlos Abbott',
        'Miriam Wagner',
        'Bradley Wilkerson',
        'Virginia Andrews',
        'Kelly Snyder',
    ];

    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    const [personName, setPersonName] = useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const openModal = (dados) => {

        const usersFill = projetos.filter(x => x.projeto === dados.proj)[0].user
        if (usersFill !== undefined) {
            const nameUser = usersFill.map(r => r.name)
            setPersonName(nameUser)
        }

        setOpen(true)
        setProjSelect(dados.proj)
    }

    async function cdUsuarios() {

        const resultado = personName.map(nome => {
            const usuario = usersArr.find(user => user.username === nome);
            return usuario ? usuario.uid : null;
        });

        resultado.map(x => {
            const fetchData = async () => {
                try {
                    const userProj = await get(child(dbRef, 'users/' + x + '/projeto'))
                    let dataUserProj
                    if (userProj.exists()) {
                        dataUserProj = userProj.val();
                    }

                    if (dataUserProj) {
                        if (dataUserProj.includes(projSelect)) {
                            //
                        } else {
                            dataUserProj.push(projSelect)
                            const updades = {}
                            updades['users/' + x + '/projeto'] = dataUserProj
                            await update(ref_database(db), updades);
                        }
                    } else {
                        const updades = {}
                        updades['users/' + x + '/projeto'] = [projSelect]
                        await update(ref_database(db), updades);
                    }

                } catch (error) {
                    console.error(error);
                }
            };
            fetchData()
        })

        const resultadoS = usersArr
            .filter(user => personName.includes(user.username))
            .map(({ username, ...rest }) => ({ name: username, ...rest }));

        const fetchData = async () => {
            try {
                const userProj = await get(child(dbRef, 'projetos'));
                const listProjUser = await get(child(dbRef, 'projetos/list'));

                let dataUserProj
                let dataListProjUser

                if (userProj.exists()) {
                    dataUserProj = userProj.val();
                }

                if (listProjUser.exists()) {
                    dataListProjUser = listProjUser.val();
                }

                const indice = dataListProjUser.findIndex(item => item.projeto === projSelect);

                if (indice !== -1) {
                    const novoItem = { ...dataListProjUser[indice], user: resultadoS };
                    dataListProjUser.splice(indice, 1, novoItem);
                }

                dataUserProj[projSelect].users = resultado

                const updades = {}
                const updadesList = {}
                updades['projetos'] = dataUserProj
                updadesList['projetos/list'] = dataListProjUser
                await update(ref_database(db), updades);
                await update(ref_database(db), updadesList);

            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
        setOpen(false)
        setProjSelect([])
        setPersonName([])
    }

    return (
        <div className='body_home'>
            <ToastContainer />
            {/* */}

            <div className='card_text'>
                <a className='text_one'>Projetos em desenvolvimento</a>
                <a style={{ color: '#6c757d', fontSize: 13, marginLeft: 20 }}>Informações relativas a | {new Date().toLocaleString()}</a>
                <button className='btn_refresh_data' onClick={() => setAtualizar(!atualizar)}><TbRefreshAlert /></button>
            </div>

            <div style={{ overflowY: 'scroll', height: '80vh' }}>
                <div style={{ marginRight: 35 }}>
                    {projetos &&
                        projetos.map((item, index) => {
                            return (
                                <DemoPaper variant="outlined" key={index} style={{ marginBottom: 8 }}>
                                    <div className='card_user_job'>

                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                                            <div className='card_projeto_li'>
                                                <Typography style={{ fontFamily: 'Inter', fontSize: 14 }}>{item.projeto}</Typography>
                                            </div>


                                            {item.user ?
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 20, width: 160 }}>

                                                    <a style={{ fontSize: 13, color: '#6c757d', marginRight: 10, cursor: 'pointer' }}
                                                        onClick={() => openModal({ proj: item.projeto })}>Usuários</a>

                                                    <AvatarGroup max={3} sx={{
                                                        '& .MuiAvatar-root': { width: 32, height: 32, fontSize: 15 },
                                                    }}>
                                                        {item.user.map((it, id) => {
                                                            const names = it.name.trim().split(" ");
                                                            const initials = names.map((name) => name.charAt(0).toUpperCase()).join("");
                                                            return (
                                                                <Avatar sx={{ bgcolor: "#4361ee", width: 32, height: 32, fontSize: 15, }}>{initials}</Avatar>
                                                            );
                                                        })
                                                        }
                                                    </AvatarGroup>
                                                </div>
                                                :
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 20, width: 160 }}>
                                                    <Button variant="outlined" size="small" onClick={() => openModal({ proj: item.projeto })}>
                                                        Adicionar usuários
                                                    </Button>
                                                </div>
                                            }

                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 20 }}>
                                                {item.timestamp &&
                                                    <a style={{ fontSize: 13, color: '#6c757d', marginRight: 10 }}>Backup</a>
                                                }

                                                {item.timestamp &&
                                                    <DemoPaper variant="outlined"
                                                        style={{ padding: 4, paddingLeft: 15, paddingRight: 15, backgroundColor: "#dee2e6", width: 150 }}>
                                                        <a style={{ fontSize: 13, color: "#001d3d" }}>{new Date(item.timestamp).toLocaleString()}</a>
                                                    </DemoPaper>
                                                }
                                            </div>

                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <ColorButton variant="outlined" onClick={() => navigate('/sy/edit', { state: { projeto: item.projeto } })}
                                                style={{ textTransform: 'none', marginRight: 15 }}>
                                                <Typography style={{ fontSize: 14, fontWeight: 500, color: '#333533' }}>Edição</Typography>
                                            </ColorButton>
                                            <ColorButton2 variant="outlined" onClick={() => navigate('/sy/infoCampo', { state: { projeto: item.projeto } })}
                                                style={{ textTransform: 'none' }}>
                                                <Typography style={{ fontSize: 14, fontWeight: 500 }}>Acompanhar campo</Typography>
                                            </ColorButton2>
                                        </div>

                                    </div>
                                </DemoPaper>
                            )
                        })
                    }
                </div>
            </div>

            <Modal
                open={open}
                onClose={() => { setOpen(false); setPersonName([]) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <a className='text_one'>Selecione os usuários para o campo {projSelect}</a>
                    <div style={{ marginTop: 10 }}>
                        <FormControl sx={{ m: 1, width: "100%" }} style={{ marginBottom: 40 }}>
                            <InputLabel id="demo-multiple-chip-label">Usuários</InputLabel>
                            <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={personName}
                                onChange={handleChange}
                                input={<OutlinedInput id="select-multiple-chip" label="Usuários" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {usersArr.map((x) => (
                                    <MenuItem
                                        key={x.username}
                                        value={x.username}
                                        style={getStyles(x.username, personName, theme)}
                                    >
                                        {x.username}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Button variant="outlined" size="small" onClick={() => cdUsuarios()} style={{ position: 'absolute', bottom: 30, right: 25 }}>
                            Adicionar usuários
                        </Button>

                    </div>
                </Box>
            </Modal>

            {/* */}
        </div>
    )
}

export default Home;