import React, { useEffect, useState } from 'react';
import { PDFDownloadLink, Document, Page, Text, View, Image, Font, PDFViewer, Link } from '@react-pdf/renderer';

import Roboto from '../../fonts/Roboto-Regular.ttf'
import RobotoBold from '../../fonts/Roboto-Bold.ttf'
import RobotoItalic from '../../fonts/Roboto-Italic.ttf'

function Certificado() {

    const [tokken, setTokken] = useState("");
    const [data, setData] = useState("");
    const [times, setTimes] = useState("");

    function geraStringAleatoria(tamanho) {
        var stringAleatoria = '';
        var caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        for (var i = 0; i < tamanho; i++) {
            stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
        }
        return stringAleatoria;
    }

    useEffect(() => {
        document.title = 'Certificado - AIPA Connect';

        setTokken(geraStringAleatoria(8))

        var data = new Date();
        var dia = String(data.getDate()).padStart(2, '0')
        var mes = String(data.getMonth() + 1).padStart(2, '0')
        var ano = data.getFullYear()

        setData(dia + '/' + mes + '/' + ano)

        const h1 = data.getHours()
        const m2 = data.getMinutes()

        setTimes(h1 + ':' + m2)


    }, []);

    const qrCodeData = 'https://www.aipaconnect.com.br'; // Dados para o código QR

    const qrCodeImageUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${qrCodeData}&size=100x100`;


    Font.register({
        family: 'Roboto', fonts: [
            { src: Roboto },
            { src: RobotoBold, fontWeight: 600 },
            { src: RobotoItalic, fontStyle: 'italic' },
        ]
    });


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 25 }} >

            <PDFDownloadLink document={

                <Document>
                    <Page orientation='landscape'>
                        <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 25 }}>

                            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <Image style={{ width: 300, display: 'flex' }} src={require('../../img/logo-hc.png')} />
                                <View style={{ width: 1, height: 100, backgroundColor: "#DCDCDC", marginLeft: 15, marginRight: 15 }}></View>
                                <Image style={{ width: 200, display: 'flex' }} src={require('../../img/logo-aipa-sim-b.png')} />
                            </View>

                            <Text style={{ marginTop: 20, fontSize: 30, letterSpacing: 4 }}>Certificado</Text>

                            <View style={{ padding: 30, width: 700 }}>
                                <Text style={{ fontSize: 15 }}>A HCoutinho Pesquisa e Desenvolvimento, através do Módulo de Educação Patrimonial do AIPA Connect, certifica que</Text>

                                <Text style={{ marginBottom: 15, marginTop: 15, textAlign: 'center', fontSize: 25, fontWeight: '800' }}>Wender Bacelar da Silva</Text>

                                <Text style={{ fontSize: 15 }}>
                                    concluiu o curso Educação Patrimonial, com carga-horária de 60 horas, início em
                                    XX/XX/XX, término em XX/XX/XXXX.
                                </Text>

                            </View>

                            <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 25 }}>

                                <Text style={{ marginTop: 20, fontSize: 12 }}>(assinado eletronicamente)</Text>

                                <Text style={{ fontSize: 14, fontWeight: 'bold' }}>Hebert Rogério do Nascimento Coutinho</Text>

                                <Text style={{ fontSize: 12 }}>Sócio-adminstrador HCoutinho Pesquisa e Desenvolvimento</Text>

                            </View>

                            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>

                                <Image src={qrCodeImageUrl} style={{ height: 65, width: 65, marginRight: 15 }} />

                                <View style={{ display: 'flex', flexDirection: 'column', width: 700 }}>
                                    <Text style={{ fontSize: 12, marginBottom: 5 }}>Certificado registrado no Educação Patrimonial - AIPA Connect</Text>
                                    <Text style={{ fontSize: 12, marginBottom: 5 }}>Este certificado foi gerado em {data}, às {times}.</Text>
                                    <Text style={{ fontSize: 12 }}>
                                        O presente certificado pode ter a sua validade comprovada acessando o QRCode à esquerda, ou, caso desejar, informando
                                        o código verificador <Text style={{ fontWeight: '800', letterSpacing: 1 }}>{tokken}</Text> no site https://www.aipaconnect.com.br.
                                    </Text>
                                </View>

                            </View>

                        </View>

                    </Page>
                </Document>

            } fileName="somename.pdf">
                {({ blob, url, loading, error }) =>
                    loading ? 'Loading document...' : 'Download now!'
                }
            </PDFDownloadLink>

            <PDFViewer children={
                <Document>
                    <Page orientation='landscape'>
                        <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 25 }}>

                            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <Image style={{ width: 300, display: 'flex' }} src={require('../../img/logo-hc.png')} />
                                <View style={{ width: 1, height: 100, backgroundColor: "#DCDCDC", marginLeft: 15, marginRight: 15 }}></View>
                                <Image style={{ width: 200, display: 'flex' }} src={require('../../img/logo-aipa-sim-b.png')} />
                            </View>

                            <Text style={{ marginTop: 20, fontSize: 30, letterSpacing: 4, fontFamily: 'Roboto', fontWeight: 600 }}>Certificado</Text>

                            <View style={{ padding: 30, width: 700 }}>
                                <Text style={{ fontSize: 15, lineHeight: 1.2, fontFamily: 'Roboto', textAlign: 'center' }}>A HCoutinho Pesquisa e Desenvolvimento, através do Módulo de Educação Patrimonial do AIPA Connect, certifica que</Text>

                                <Text style={{
                                    marginBottom: 15, marginTop: 15, textAlign: 'center', fontSize: 25,
                                    fontFamily: 'Roboto', fontWeight: 600

                                }}>Wender Bacelar da Silva</Text>

                                <Text style={{ fontSize: 15, lineHeight: 1.2, fontFamily: 'Roboto', textAlign: 'center' }}>
                                    concluiu o curso Educação Patrimonial, com carga-horária de 60 horas, início em
                                    XX/XX/XX, término em XX/XX/XXXX.
                                </Text>

                            </View>

                            <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 25 }}>

                                <Text style={{ marginTop: 20, fontSize: 12, fontFamily: 'Roboto', fontStyle: 'italic' }}>(assinado eletronicamente)</Text>

                                <Text style={{ fontSize: 14, fontFamily: 'Roboto', fontWeight: 600 }}>Hebert Rogério do Nascimento Coutinho</Text>

                                <Text style={{ fontSize: 12, fontFamily: 'Roboto' }}>Sócio-adminstrador HCoutinho Pesquisa e Desenvolvimento</Text>

                            </View>

                            <View style={{ width: 780, height: 1, backgroundColor: "#000000", marginLeft: 25, marginRight: 25, marginBottom: 10 }}></View>

                            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>

                                <Image src={qrCodeImageUrl} style={{ height: 65, width: 65, marginRight: 15 }} />

                                <View style={{ display: 'flex', flexDirection: 'column', width: 700 }}>
                                    <Text style={{ fontSize: 12, marginBottom: 5, fontFamily: 'Roboto' }}>Certificado registrado no Educação Patrimonial - AIPA Connect</Text>
                                    <Text style={{ fontSize: 12, marginBottom: 5, fontFamily: 'Roboto' }}>Este certificado foi gerado em {data}, às {times}.</Text>
                                    <Text style={{ fontSize: 12, fontFamily: 'Roboto' }}>
                                        O presente certificado pode ter a sua validade comprovada acessando o QRCode à esquerda, ou, caso desejar, informando
                                        o código verificador <Text style={{ fontWeight: 600, letterSpacing: 1 }}>{tokken}</Text> no site <Link src={qrCodeData}>{qrCodeData}</Link>.
                                    </Text>
                                </View>



                            </View>

                        </View>

                    </Page>
                </Document>

            } width={800} height={600}>

            </PDFViewer>

        </div>
    );
}

export default Certificado;