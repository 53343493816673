import { initializeApp as initial_user, getApps } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from "firebase/storage";


const firebaseConfig = {
    apiKey: "AIzaSyBsTmnRLhBXPBMvS8KIglarlIGWBXix4ng",
    authDomain: "hcoutinhoapp.firebaseapp.com",
    databaseURL: "https://hcoutinhoapp-default-rtdb.firebaseio.com",
    projectId: "hcoutinhoapp",
    storageBucket: "hcoutinhoapp.appspot.com",
    messagingSenderId: "510852532122",
    appId: "1:510852532122:web:ee45f52212db461de69c23"
  };


  const app =
  getApps().length > 0 ? getApps()[0] : initial_user(firebaseConfig)

  //const appAdmin = initial_admin(firebaseConfig);

  export const auth = getAuth(app);
  export const db = getDatabase(app);
  export const storage = getStorage(app);

  //export const auth_admin = getAuth(appAdmin)

