import React, { useState } from 'react';

function ContadorFluxo() {

    const [moto, setMoto] = useState(0)
    const [carro, setCarro] = useState(0)
    const [onibus, setOnibus] = useState(0)
    const [carreta, setCarreta] = useState(0)

    

    return (
        <div>
            <a style={{display:'flex', justifyContent:'center', marginTop: '50px', marginBottom:'15px' }}>CONTADOR DE FLUXO</a>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems:'end', width: '200px' }}>
                    <a style={{ padding: '10px 20px' }}>Moto</a>
                    <a style={{ padding: '10px 20px' }}>Carro / Picape</a>
                    <a style={{ padding: '10px 20px' }}>Ônibus / Caminhão</a>
                    <a style={{ padding: '10px 20px' }}>Carreta +3</a>
                </div>

                <div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <a style={{ padding: '10px 20px', background: '#ccc', marginLeft: '10px', borderRadius: '10px' }}>{moto}</a>
                        <button
                            style={{ border: 'none', padding: '10px 20px', backgroundColor: '#ef476f', marginLeft: '10px', color: '#fff', borderRadius: '10px', cursor: 'pointer' }}
                            onClick={() => setMoto(moto + 1)}>ADICIONAR</button>
                        <button
                            style={{ border: 'none', padding: '10px 20px', backgroundColor: '#403d39', marginLeft: '10px', color: '#fff', borderRadius: '10px', cursor: 'pointer' }}
                            onClick={() => setMoto(0)}>Apagar</button>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <a style={{ padding: '10px 20px', background: '#ccc', marginLeft: '10px', borderRadius: '10px' }}>{carro}</a>
                        <button
                            style={{ border: 'none', padding: '10px 20px', backgroundColor: '#8338ec', marginLeft: '10px', color: '#fff', borderRadius: '10px', cursor: 'pointer' }}
                            onClick={() => setCarro(carro + 1)}>ADICIONAR</button>
                        <button
                            style={{ border: 'none', padding: '10px 20px', backgroundColor: '#403d39', marginLeft: '10px', color: '#fff', borderRadius: '10px', cursor: 'pointer' }}
                            onClick={() => setCarro(0)}>Apagar</button>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <a style={{ padding: '10px 20px', background: '#ccc', marginLeft: '10px', borderRadius: '10px' }}>{onibus}</a>
                        <button
                            style={{ border: 'none', padding: '10px 20px', backgroundColor: '#ffbe0b', marginLeft: '10px', color: 'black', borderRadius: '10px', cursor: 'pointer' }}
                            onClick={() => setOnibus(onibus + 1)}>ADICIONAR</button>
                        <button
                            style={{ border: 'none', padding: '10px 20px', backgroundColor: '#403d39', marginLeft: '10px', color: '#fff', borderRadius: '10px', cursor: 'pointer' }}
                            onClick={() => setOnibus(0)}>Apagar</button>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <a style={{ padding: '10px 20px', background: '#ccc', marginLeft: '10px', borderRadius: '10px' }}>{carreta}</a>
                        <button
                            style={{ border: 'none', padding: '10px 20px', backgroundColor: '#2a9d8f', marginLeft: '10px', color: '#fff', borderRadius: '10px', cursor: 'pointer' }}
                            onClick={() => setCarreta(carreta + 1)}>ADICIONAR</button>
                        <button
                            style={{ border: 'none', padding: '10px 20px', backgroundColor: '#403d39', marginLeft: '10px', color: '#fff', borderRadius: '10px', cursor: 'pointer' }}
                            onClick={() => setCarreta(0)}>Apagar</button>
                    </div>

                </div>

            </div>
        </div>

    )
}

export default ContadorFluxo;