import React, { useState, useEffect } from "react";
import './styles.css'

function PageFront() {
    return (
    {/*}
        <nav>
            <div className="barra_lateral">
                <ul>
                    <li className="card_img_barra">
                        <img src={require('../../img/logo-aipa-sim.png')} className="img_aipa"></img>
                    </li>
                    <li className="card_text_barra">
                        <a className="text_barra">Dashboard</a>
                    </li>
                    <li className="card_text_barra">
                        <a className="text_barra">Pontos Levantados</a>
                    </li>
                    <li className="card_text_barra">
                        <a className="text_barra">Salvar Ficha</a>
                    </li>
                    <li className="card_text_barra">
                        <a className="text_barra">Usuários</a>
                    </li>
                    <li className="card_text_barra">
                        <a className="text_barra">Campos</a>
                    </li>
                </ul>
                <div className="card_sair_barra">
                    <a className="text_sair_barra">Sair</a>
                </div>

            </div>

        </nav>
    {*/}


    );
}

export default PageFront;