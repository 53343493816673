import React, { useState, useEffect } from "react";
import { db, auth } from "../../services/firebaseConnection";
import { useForm, Controller } from "react-hook-form";
import { ref, set, get, child, remove, update } from "firebase/database";
import Select from 'react-select'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ToastContainer, toast } from 'react-toastify';
import './styles.css'
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';


function Usuarios() {

    const [alert, setAlert] = useState(false)
    const [users, setUsers] = useState()
    const [usersData, setUsersData] = useState()
    const [projetos, setProjetos] = useState()
    const [projetosList, setProjetosList] = useState()
    const [addUser, setAddUser] = useState(true)
    const [editUser, setEditUser] = useState(false)
    const [dataUserEdit, setDataUserEdit] = useState('')
    const [openBtns, setOpenBtns] = useState(false)


    const imgPd = () => toast.error('Já existe um usuário cadastrado com o UID informado!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const schema = yup.object({
        nome: yup.string().required("Informe o nome do usuário!"),
        uid: yup.string().required("Informe o UID do usuário!"),
        funcao: yup.string().required("Informe a função do usuário!"),
        projeto: yup.string().required("Informe o projeto que o usuário irar desenvolver!"),
    })

    const schema2 = yup.object({
        nome: yup.string().required("Informe o nome do usuário!"),
        uid: yup.string().required("Informe o UID do usuário!"),
        funcao: yup.string().required("Informe a função do usuário!"),
        projeto: yup.string().required("Informe o projeto que o usuário irar desenvolver!"),
    })

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const { control: control2, handleSubmit: handleSubmit2, reset, formState: { errors: errors2 } } = useForm({
        resolver: yupResolver(schema2)
    });

    const onSubmit = data => {
        const comparUid = users.filter(r => r == data.uid)
        if (comparUid[0] == undefined) {
            set(ref(db, 'users/' + data.uid), {
                username: data.nome,
                funcao: data.funcao,
                uid: data.uid
            })

            set(ref(db, 'userperm/' + data.uid), {
                projeto: data.projeto,
                permissao: "ativo",
                nivel: '2',
                uid: data.uid
            })

            set(ref(db, 'usuarios/' + data.uid), {
                atualizacao: Date.now()
            })
        } else {
            imgPd()
        }
    };

    async function onEdit(data) {

        const filterProj = projetosList.filter(x => { return (x.projeto != data.projeto) })
        const filterProjSelec = projetosList.filter(x => { return (x.projeto == data.projeto) })

        if (filterProjSelec[0].user == undefined) {
            const users = [{ name: data.nome, uid: data.uid }]

            const projetoNew = { projeto: data.projeto, user: users }
            const newArrPro = [...filterProj, projetoNew]

            const projetoList = {}
            projetoList['projetos/list'] = newArrPro
            await update(ref(db), projetoList);
        } else {
            const oldUser = filterProjSelec[0].user;
            const users = { name: data.nome, uid: data.uid };

            const newUsers = [...oldUser];
            if (!newUsers.some((user) => user.uid === users.uid)) {
                newUsers.push(users);
            }

            const projetoNew = { projeto: data.projeto, user: newUsers }
            const newArrPro = [...filterProj, projetoNew]

            const projetoList = {}
            projetoList['projetos/list'] = newArrPro
            await update(ref(db), projetoList);
        }

        const nome = {}
        const uid = {}
        const funcao = {}
        const projeto = {}
        const projetoPerm = {}
        nome['users/' + data.uid + '/username'] = data.nome
        uid['users/' + data.uid + '/uid'] = data.uid
        funcao['users/' + data.uid + '/funcao'] = data.funcao
        projeto['userperm/' + data.uid + '/projeto'] = data.projeto
        projetoPerm['users/' + data.uid + '/projeto'] = data.projeto
        await update(ref(db), nome);
        await update(ref(db), uid);
        await update(ref(db), funcao);
        await update(ref(db), projeto);
        await update(ref(db), projetoPerm);

        setAddUser(true)
        setEditUser(false)
        reset({})

    }

    const removeUser = data => {
        const users = ref(db, 'users/' + data);
        const userperm = ref(db, 'userperm/' + data);
        const usuarios = ref(db, 'usuarios/' + data);
        remove(users)
        remove(userperm)
        remove(usuarios)
        const newData = { ...usersData }
        delete newData.data
        setUsersData(newData)
    }

    async function ativarUser(data) {
        const updades = {}
        updades['users/' + data + '/permissao'] = 'ativo'
        updades['userperm/' + data + '/permissao'] = 'ativo'
        await update(ref(db), updades);

        const dbRef = ref(db);

        get(child(dbRef, 'users/')).then((snapshot) => {
            if (snapshot.exists()) {
                const dados = snapshot.val()
                const userName = Object.keys(dados)
                setUsers(userName)
                setUsersData(dados)
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    async function desativarUser(data) {
        const updades = {}
        updades['users/' + data + '/permissao'] = 'desativado'
        updades['userperm/' + data + '/permissao'] = 'desativado'
        await update(ref(db), updades);

        const dbRef = ref(db);

        get(child(dbRef, 'users/')).then((snapshot) => {
            if (snapshot.exists()) {
                const dados = snapshot.val()
                const userName = Object.keys(dados)
                setUsers(userName)
                setUsersData(dados)
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    const editarUsuario = data => {
        setAddUser(false)
        setEditUser(true)
        setDataUserEdit(data)
    }

    useEffect(() => {

        const dbRef = ref(db);

        get(child(dbRef, 'users/')).then((snapshot) => {
            if (snapshot.exists()) {
                const dados = snapshot.val()
                const userName = Object.keys(dados)
                setUsers(userName)
                setUsersData(dados)
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });

    }, [])

    /*useEffect(() => {

        const dbRef = ref(db);

        get(child(dbRef, 'projetos/list')).then((snapshot) => {
            if (snapshot.exists()) {
                const dados = snapshot.val()

                setProjetosList(dados)

                var arrProjs = []
                dados.map(x => { arrProjs.push(x.projeto) })
                function myFunction(num) {
                    return { value: num, label: num };
                }
                const map1 = arrProjs.map(myFunction);
                setProjetos(map1)

            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });

    }, [])*/

    const DemoPaper = styled(Paper)(({ theme }) => ({
        width: "100%",
        borderRadius: 10,
        padding: theme.spacing(0.7),
        ...theme.typography.body2,
    }));


    return (
        <div className="card_total">

            <ToastContainer />

            <a className='text_one'>Usuários Cadastrados</a>

            <div style={{ overflowY: 'scroll', height: '90vh', marginTop: 8 }}>

                {users &&
                    users.map((uid, index) => (
                        <div key={index} style={{ marginRight: 15 }}>
                            {usersData &&
                                <DemoPaper variant="outlined"
                                    style={{ marginBottom: 8, width: '100%', marginRight: 25, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                                    <div className="user_infos">
                                        <a>{usersData[uid].username}</a>
                                        {usersData[uid].permissao == "desativado" && <div className="selo_user">desativado</div>}
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <button className="btn_edit_one" onClick={() => ativarUser(usersData[uid].uid)}>Ativar</button>
                                        <button className="btn_edit_two" onClick={() => desativarUser(usersData[uid].uid)}>Desativar</button>
                                        <button className="btn_edit_final" onClick={() => removeUser(usersData[uid].uid)}>Excluir</button>
                                    </div>
                                </DemoPaper>
                            }
                        </div>

                    ))
                }

            </div>

        </div>
    )
}

export default Usuarios;